<template>
  <v-card class="mt-3 pa-3 custom-border"
    :class="{ 'da-fare': daFare }" width="100%" variant="flat">
    <v-card-text class="pa-0">
      <div class="float-right" :title="formatData(attivita.dataEsecuzione)" :class="colorClass">{{ getData(attivita.dataEsecuzione) }}</div>
      <div class="titolo">
        <v-icon>{{ getModalitaIcon }}</v-icon>
        {{ getTitle }}
      </div>
      <div class="contenuto">
        {{ attivita.descrizione }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'pinia';
import useLeadsStore from '@/stores/leads';
import AttivitaService from '@/services/AttivitaService';

export default {
  name: 'AttivitaItem',
  props: {
    attivita: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead']),
    colorClass() {
      if (this.attivita.esito === 'da-fare' && new Date(this.attivita.dataEsecuzione) > new Date()) {
        return 'future-active';
      }
      return '';
    },
    daFare() {
      //const diff = new Date(attivita.dataEsecuzione) - new Date();
      return this.attivita.esito === 'da-fare';
    },
    getModalita() {
      if (this.attivita.tipo === 'appuntamento' || this.attivita.tipo === 'nota')
        return '';
      return ' ' + this.attivita.modalita + ' ';
    },
    getModalitaIcon() {
      return AttivitaService.getAttivitaIcon(this.attivita.tipo);
    },
    getTitle() {
      let title = '';
      let congiunzione = '';
      switch (this.attivita.tipo) {
        case 'email':
          title = 'Email';
          congiunzione = ' a ';
          break;
        case 'telefono':
          title = 'Telefonata';
          congiunzione = ' a ';
          break;
        case 'nota':
          title = 'Nota';
          congiunzione = ' su ';
          break;
        case 'appuntamento':
          title = 'Appuntamento';
          congiunzione = ' con ';
          break;
        case 'task':
          title = 'Task';
          congiunzione = ' su ';
          break;
      }
      title += this.getModalita;
      if (this.attivita.Contatto)
        return title + congiunzione + this.attivita.Contatto?.nome;
      return title + congiunzione + this.currentLead?.ragioneSociale;
    },
  },
  methods: {
    formatData(data) {
      return new Intl.DateTimeFormat('it-IT', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(data));
    },
    getData(data) {
      const date = new Date(data);
      const diff = date - new Date();
      if (diff > 0) {
        const days = Math.round(diff / (1000 * 60 * 60 * 24));
        let rtf = new Intl.RelativeTimeFormat('it-IT', { numeric: 'auto' });
        if (days < 7) {
          const giorno = Intl.DateTimeFormat('it-IT', { weekday: 'long' }).format(date);
          const ora = Intl.DateTimeFormat('it-IT', { hour: 'numeric', minute: 'numeric' }).format(date);
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24)), 'day') + ': ' + giorno + ', alle ' + ora;
        }
        if (days < 60)
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 7)), 'week');
        if (days < 365)
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 30)), 'month');
        return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 365)), 'year');
      }
      let dft = new Intl.DateTimeFormat('it-IT', { dateStyle: 'short', timeStyle: 'short' });
      return dft.format(date);
    },
  },
};
</script>

<style scoped>
.custom-border {
  border: none;
  background-color: rgb(232 232 229);
  cursor: pointer;
}

.custom-border.da-fare {
  border: none;
  background-color: #ffffff;
}

.titolo {
  font-size: 1.2em;
}
.future-active {
  color: #00a200;
  font-weight: 500;
}
</style>