<template>
  <v-container class="pt-0">
    <entities-contacts></entities-contacts>
    <entities-trattative></entities-trattative>
  </v-container>
</template>

<script>
import EntitiesContacts from './EntitiesContacts.vue';
import EntitiesTrattative from './EntitiesTrattative.vue';

export default {
  name: 'EntitiesPreview',
  components: {
    EntitiesTrattative,
    EntitiesContacts,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>