<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" width="70%" persistent>
      <v-card>
        <v-card-title>
          <v-btn v-if="!isDeleted" :icon="locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline'" class="float-right"
            @click="locked = !locked" :color="locked ? 'red' : 'green'" variant="text" density="compact"></v-btn>
          <v-chip v-else color="red" class="mr-2 float-right" size="small">Cancellata</v-chip>
          <span class="headline"><v-icon>mdi-text-box-outline</v-icon> {{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="form" @submit.prevent="save">
            <v-row>
              <v-col cols="12" sm="8" md="6" lg="4">
                <v-text-field v-if="editedItem?.Lead?.ragioneSociale" ref="ragioneSociale" label="Lead" variant="underlined"
                  v-model="editedItem.Lead.ragioneSociale" density="compact" hide-details="auto"
                  readonly></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataEmissione" type="date" label="Data Offerta" density="compact"
                  variant="underlined" :rules="[rules.required]" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataScadenza" type="date" label="Scadenza Offerta" density="compact"
                  variant="underlined" :rules="[rules.required]" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-text-field>
              </v-col>

              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataInizio" type="date" label="Inizio" density="compact"
                  variant="underlined" hide-details="auto" :readonly="locked" :disabled="isDeleted"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataTermine" type="date" label="Termine" density="compact"
                  variant="underlined" hide-details="auto" :readonly="locked" :disabled="isDeleted"></v-text-field>
              </v-col>

              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.valore" prefix="€" type="number" label="Valore" density="compact"
                  :rules="[rules.required]" variant="underlined" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-select v-model="editedItem.probabilita" :items="['bassa', 'media', 'alta']" density="compact"
                  :rules="[rules.required]" label="Probabilità" variant="underlined" hide-details="auto"
                  :disabled="isDeleted" :readonly="locked"></v-select>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-select v-model="editedItem.stato" :items="stati" item-title="name" return-object
                  :rules="[rules.required]" density="compact" label="Stato Trattativa" variant="underlined"
                  hide-details="auto" :disabled="isDeleted" :readonly="locked"></v-select>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-select v-model="editedItem.Contatto" variant="underlined" density="compact" :items="contacts"
                  item-title="nome" label="Contatto" return-object hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-combobox v-model="editedItem.origine" :items="['passaparola', 'sito', 'spontaneo', 'DEM', 'altro']"
                  density="compact" label="Origine" variant="underlined" hide-details="auto"
                  :disabled="isDeleted" :readonly="locked"></v-combobox>
              </v-col>
              <v-col cols="12" lg="9">
                <v-text-field v-model="editedItem.oggetto" variant="underlined" label="Oggetto" density="compact"
                  :rules="[rules.required]" hide-details="auto" :disabled="isDeleted" :readonly="locked"></v-text-field>
                <!--v-textarea v-model="editedItem.oggetto" label="Oggetto" density="compact"></!--v-textarea-->
              </v-col>
              <v-col cols="12" v-if="isEditMode">
                <div class="d-flex align-center">
                  <span class="mr-2">Offerta collegata:</span>
                  <v-chip v-if="editedItem.link" small color="blue lighten-4" class="mr-2" @click="download()"
                    :closable="!isDeleted && !locked" @click:close="cancellaDocumento()">
                    {{ editedItem.numero }}
                  </v-chip>
                  <div v-else>
                    <div class="d-flex align-center">
                      <v-skeleton-loader v-if="generaLoader" type="chip" width="200"></v-skeleton-loader>
                      <v-btn v-else prepend-icon="mdi-plus" color="green" density="compact" @click="apriOffertaDialog"
                        :disabled="locked || isDeleted" class="mr-3">Genera
                        Offerta</v-btn>
                      o
                      <v-file-input v-model="file" label="Scegli file" hide-details="auto" density="compact"
                        accept=".docx,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                        class="file-input ml-3 mr-3" :disabled="isDeleted || locked"></v-file-input>
                      <v-btn prepend-icon="mdi-upload" color="green" density="compact" @click="caricaOfferta"
                        :disabled="isDeleted || locked || file.length === 0">Carica Offerta</v-btn>
                    </div>
                    <!--<v-progress-linear v-if="loading" indeterminate color="yellow-darken-2"></v-progress-linear>-->
                  </div>

                </div>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pl-5 pr-5 pb-3">
          <v-btn color="red" text @click="deleteItem" v-if="isEditMode && !isDeleted" :disabled="locked || isSubmitted">Elimina</v-btn>
          <v-btn color="green" text @click="createNewContratto"
            v-if="isEditMode && editedItem.stato == 'accettata' && !isDeleted" :disabled="locked || isSubmitted">Chiudi e crea
            Contratto</v-btn>
          <v-spacer></v-spacer>
          {{ isSubmitted ? 'Salvataggio in corso...' : '' }}
          <v-btn color="blue darken-1" text @click="close" :disabled="isSubmitted">Annulla</v-btn>
          <v-btn color="primary" variant="elevated" @click="save"
            :disabled="!form || isDeleted || locked || isSubmitted">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <offerta-dialog v-model="generaOffertaDialog" :item="currentTrattativa" @save="generaOfferta"></offerta-dialog>
  </v-row>
</template>

<script>
import useLeadsStore from '@/stores/leads';
import { mapState } from 'pinia';
import OffertaDialog from '@/components/dialogs/OffertaDialog.vue';
import TrattativeService from '@/services/TrattativeService';
import axios from 'axios';
import { cloneDeep } from 'lodash';
import DialogManagerService from '@/services/DialogManagerService';

export default {
  props: {
      item: {
        type: Object,
        default: () => null,
      },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    OffertaDialog,
  },
  emits: ['update:modelValue', 'save', 'upgradeTrattativa', 'delete'],
  data() {
    return {
      showDialog: this.modelValue,
      form: false,
      rules: {
        required: value => !!value || 'Il campo non può essere vuoto',
        number: value => value >= 0 || 'Il numero deve essere >= 0',
      },
      editedItem: cloneDeep(this.item),
      locked: this.item?.id ? true : false,
      generaOffertaDialog: false,
      currentTrattativa: null,
      generaLoader: false,
      stati: ['in preparazione', 'in negoziazione', 'presentata', 'accettata', 'rifiutata'],
      file: [],
      isSubmitted: false,
    };
  },
  watch: {
    item(newValue) {
      this.editedItem = cloneDeep(newValue);
      if (!this.stato) {
        this.editedItem.stato = this.stati[0];
      }
    },
    modelValue(newValue) {
      this.showDialog = newValue;
    },
  },
  methods: {
    async createNewContratto() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      if (!this.editedItem.type) {
        this.editedItem.type = 'offerta';
      }
      await TrattativeService.upgradeTrattativa(this.editedItem, {
        currentLead: this.currentLead,
        postSaveFunctions: [(leadId, doReload, newItem) => {
          console.log('+++++++++++++++++++++openDialog', newItem.type, newItem);
          DialogManagerService.openDialog('contratto', newItem, []);
        }],
      });
      this.isSubmitted = false;
      this.$emit('upgradeTrattativa', this.editedItem);
      this.close();
    },
    async cancellaDocumento() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      if (!confirm('Sei sicuro di voler eliminare questo documento? Non verrà generato un nuovo numero di offerta.')) {
        this.isSubmitted = false;
        return;
      }
      this.generaLoader = true;
      this.editedItem.link = null;
      await TrattativeService.deleteDocumentoOfferta(this.editedItem.id);
      this.isSubmitted = this.generaLoader = false;
    },
    download2() {
      const offerta = this.editedItem;
      axios.get(this.generateLink(offerta), {
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', offerta.numero + '.docx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    download() {
      const offerta = this.editedItem;
      const link = document.createElement('a');
      link.href = this.generateGLink(offerta);
      //link.setAttribute('download', offerta.numero + '.docx');
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    generateLink(offerta) {
      return process.env.VUE_APP_API_URL + '/api/trattative/offerta/' + offerta.id + '/documento';
    },
    generateGLink(offerta) {
      return "https://docs.google.com/document/d/" + offerta.link + "/edit";
    },
    async caricaOfferta() {
      if (this.isSubmitted) return;
      this.isSubmitted = this.loading = true;
      console.log('caricaOfferta', this.file[0]);
      try {
        const res = await TrattativeService.uploadOfferta(this.file[0], "offerta", this.editedItem.id);
        console.log('uploadAllegato res', res);
        if (res.item) {
          this.editedItem.link = res.item.link;
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Errore durante il caricamento dell\'offerta');
      }
      this.isSubmitted = this.loading = false;
    },
    parseItalianFloat(val) {
      if (val === null || val === undefined) {
        return 0;
      }
      // check if last 3 characters are equal to .[0-9][0-9]
      if (val.slice(-3).match(/\.\d{2}/)) {
        return parseFloat(val) || 0;
      }
      // Replace Italian thousand separator and convert comma to period for parsing
      const numberString = val.replace(/\./g, '').replace(/,/g, '.');
      return parseFloat(numberString) || 0;
    },

    async generaOfferta(data) {
      if (this.isSubmitted) return;
      console.log('generaOfferta', data);
      this.isSubmitted = this.generaLoader = true;
      this.generaOffertaDialog = false;
      try {
        const response = await TrattativeService.generaOfferta(data, this.editedItem.id);
        console.log('generaOfferta response', response);
        this.editedItem.link = response.id;
        this.editedItem.additionalData = response.additionalData; // should be the same as data
        if (data.formData?.totale)
          this.editedItem.valore = this.parseItalianFloat(data.formData.totale);
      } catch (error) {
        console.error('Error generating offerta:', error);
        alert('Errore durante la generazione dell\'offerta');
      }
      this.isSubmitted = this.generaLoader = false;
      //this.$emit('createOfferta');
    },
    apriOffertaDialog() {
      console.log("this.editedItem", this.editedItem);
      this.currentTrattativa = {
        numero: this.editedItem.numero,
        dataOfferta: this.editedItem.dataEmissione,
        pagamentoGiorni: this.editedItem.Lead?.pagamentoGiorni || 60,
        ragioneSociale: this.editedItem.Lead?.ragioneSociale,
        firmatario: "",
        modello: "",
        oggetto: this.editedItem.oggetto,
        referente: this.editedItem.Contatto?.nome,
      }
      // }
      this.generaOffertaDialog = true;
    },
    async deleteItem() {
      if (this.isSubmitted) return;
      this.loading = true;
      if (confirm('Sei sicuro di voler eliminare questa offerta?')) {
        await TrattativeService.deleteTrattativa(this.editedItem, {
          currentLead: this.currentLead,
          postSaveFunctions: [],
        });
        this.loading = false;
        this.$emit('delete', this.editedItem);
        this.close();
      } else {
        this.loading = false;
      }
    },
    close() {
      this.updateModelValue(false); // Request to close the dialog
    },
    updateModelValue(value) {
      // Perform cleanup or any additional logic before the actual visibility change
      if (!value) {
        // Cleanup logic here, e.g., resetting form fields, stopping timers, etc.
        console.log("Performing cleanup before closing the dialog");
      }
      this.$emit('update:modelValue', value); // Emit an event to update the dialog's visibility
    },
    async save() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      await TrattativeService.saveTrattativa(this.editedItem, {
        currentLead: this.currentLead,
        postSaveFunctions: [],
      });
      this.isSubmitted = false;
      this.$emit('save', this.editedItem);
    },
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'contacts']),
    isDeleted() {
      // :disabled="isDeleted"
      return this.item?.active === 0;
    },
    isEditMode() {
      return this.item?.id !== undefined;
    },
    formTitle() {
      return this.isEditMode ? 'Modifica Offerta ' + this.editedItem.numero : 'Aggiungi Offerta';
    },
  },
};
</script>

<style scoped>
.file-input {
  min-width: 200px;
}
</style>
