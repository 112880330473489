<template>
  <div class="border-bottom">
    <div class="header">
      <!-- Conditionally render the button or dropdown based on dropdownList content -->
      <v-btn v-if="Object.keys(dropdownList).length === 0" color="blue" class="float-right ma-0 smaller" density="compact"
        prepend-icon="mdi-plus" variant="text" @click="emitAddEvent">
        Aggiungi
      </v-btn>
      <v-menu v-else offset-y>
        <template v-slot:activator="{ props }">
          <v-btn color="blue" class="float-right ma-0 smaller" density="compact" 
            variant="text" v-bind="props">
            Aggiungi
            <v-icon right>
              mdi-chevron-down
            </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-for="(item, key) in dropdownList" :key="key" @click="emitAddEventWithKey(key)">
            <v-list-item-title>{{ item }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <div @click="isOpen = !isOpen" class="clickable" :class="{ link: isOpen }">
        <v-icon class="link">{{ isOpen ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon>
        <slot name="title">
          {{ title }}
        </slot>
      </div>
    </div>
    <div v-if="isOpen" class="pb-1">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CollapsibleComponent',
  props: {
    title: String,
    open: {
      type: Boolean,
      default: true,
    },
    // New prop for the list of items
    dropdownList: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isOpen: this.open
    };
  },
  methods: {
    emitAddEvent() {
      this.$emit('add');
    },
    // New method to emit an event with the selected key
    emitAddEventWithKey(key) {
      console.log('emitAddEventWithKey', key);
      this.$emit('add', key);
    }
  }
}
</script>

<style scoped>
.header {
  font-weight: 500;
  align-items: center;
  padding: 10px 0;
}

.link {
  color: rgb(0, 0, 238);
}

.border-bottom {
  border-bottom: 1px solid #e1e1cd;
}

.content {
  padding-left: 20px;
}

.smaller {
  font-size: 0.8em;
}

.clickable {
  cursor: pointer;
  color: black;
}

.clickable:hover {
  cursor: pointer;
  color: rgb(0, 0, 238);
}
</style>