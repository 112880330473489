import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import axios from 'axios';
import router from './router'; // Make sure you have the router set up with Vue Router 4 for Vue 3
// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { it } from 'vuetify/locale';
import { loadFonts } from './plugins/webfontloader'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'


// Add a response interceptor
axios.interceptors.response.use(response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  }, error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Assuming '/login' is your login route
      router.push('/logout');
    }
    return Promise.reject(error);
  });

  /*
  // Delay function to simulate latency
const delay = (duration) => new Promise(resolve => setTimeout(resolve, duration));

// Adding a request interceptor
axios.interceptors.request.use(async config => {
  // Simulate a delay between 4000ms to 6000ms
  await delay(Math.floor(Math.random() * 2000) + 4000);
  return config;
}, error => {
  return Promise.reject(error);
});
*/
console.log(it);
const vuetify = createVuetify({
    components,
    directives,
    locale: {
      defaultLocale: 'it',
      messages: {
        it,  // Ensure the imported Italian locale object is correctly referenced here
      }
    },
});

loadFonts()

// Create a new Vue application
const app = createApp(App);

app.use(createPinia());

// Use the router instance
app.use(router);
app.use(vuetify);

app.component('VueDatePicker', VueDatePicker);

// Mount the app to the DOM
app.mount('#app');
