import { defineStore } from 'pinia'
import axios from 'axios'
import AuthService from '@/services/AuthService';

const useTemplatesStore = defineStore('templates', {
  state: () => ({
    templates: [],
    valid: false
  }),
  getters: {
  },
  actions: {
    async fetchTemplates() {
      if (this.valid && this.templates.length > 0) return this.templates; // Already fetched
      console.log("this.valid", this.valid);
      console.log("this.templates.length", this.templates.length);
      AuthService.refreshToken();
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/tenant/templates');
        console.log("templates", response.data);
        this.valid = true;
        return this.templates = response.data;
      } catch (error) {
        console.error('Failed to fetch templates:', error);
        throw error; // Handle error appropriately in your application
      }
    },
    invalidate() {
      this.valid = false;
    }
  },
  persist: true,
})

export default useTemplatesStore;