<template>
  <v-row @click.stop @mousedown.stop @mouseup.stop v-if="showActions" class="white-bg pa-1">
    <v-col cols="3" v-for="action in visibleActions" :key="action.key" style="background-color: white !important;">
      <v-btn :prepend-icon="action.icon" size="small" :text="action.text" density="comfortable" variant="text"
        class="text-none" :slim="true" stacked @click.stop="actionClick(action.key)"></v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ActionsDrawer',
  props: {
    item: {
      type: Object,
      required: true,
    },
    actions: {
      type: String,
      default: '',
    },
    showActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Define actions with visibility flag and other properties
      actionsList: [
        { key: 'telefono', icon: 'mdi-phone', text: 'Chiamata', visible: true },
        { key: 'nota', icon: 'mdi-note-outline', text: 'Nota', visible: true },
        { key: 'email', icon: 'mdi-email-outline', text: 'Email', visible: true },
        { key: 'appuntamento', icon: 'mdi-calendar-clock', text: 'Evento', visible: true },
      ],
    };
  },
  emits: ['actionClick'],
  computed: {
    activeActions() {
      if (this.actions.length === 0)
        return 'telefono,nota,email,appuntamento'.split(',').map(action => action.trim());
      return this.actions.split(',').map(action => action.trim());
    },
    visibleActions() {
      return this.actionsList.filter(action => this.activeActions.includes(action.key));
    },
  },
  methods: {
    actionClick(tipo) {
      this.$emit('actionClick', { tipo, item: this.item });
    },
  },
};
</script>

<style scoped>
.white-bg {
  background-color: white !important;
}
</style>