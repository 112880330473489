import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

class AuthService {
  login() {
    // Implement login logic here. This will likely redirect to the Google OAuth endpoint.
    window.location.href = `${process.env.VUE_APP_API_URL}/auth/google`;
  }

  logout() {
    // Implement logout logic here.

    // Remove the JWT token from local storage
    localStorage.removeItem('jwtToken');
    window.location.href = process.env.VUE_APP_API_URL + '/logout';

    return; // axios.get(`${process.env.VUE_APP_API_URL}/logout`);
  }
  refreshToken() {
    if (axios.defaults.headers.common['Authorization']) {
      return;
    }
    console.log("refreshing token");
    const jwtToken = localStorage.getItem('jwtToken');
    axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
    axios.defaults.withCredentials = true;
    console.log("token refreshed", jwtToken);
  }
  // Save the JWT token received from the backend in local storage
  saveToken(jwtToken) {
    console.log("setting jwtToken", jwtToken);
    localStorage.setItem('jwtToken', jwtToken);
    axios.defaults.headers.common['Authorization'] = `Bearer ${jwtToken}`;
    axios.defaults.withCredentials = true;
  }

  // Get the JWT token from local storage
  getToken() {
    const token = localStorage.getItem('jwtToken');
    if (!token || token === 'undefined' || token === '') {
      console.log("token not set");
      window.location.href = process.env.VUE_APP_API_URL + '/logout';
      return null;
    }
    return token;
  }

  // Decode the JWT token to get user details
  getUserDetails() {
    const token = this.getToken();
    if (token) {
      try {
        return jwtDecode(token).user;
      } catch (error) {
        console.error('Error decoding token:', error);
        return null;
      }
    }
    return null;
  }

  hasRole(role) {
    const user = this.getUserDetails();
    if (!user || !user.roles) {
      console.log("no user or roles");
      return false;
    }
    // Check if role is object or string
    let roleName = null;
    if (typeof role === 'object') {
      roleName = role.name;
    } else {
      roleName = role;
    }
    for(const userRole of user.roles) {
      if (userRole.name === roleName) return true;
    }
    return false;
  }

  hasPermission(permission) {
    const user = this.getUserDetails();
    if (!user || !user.roles) {
      console.log("no user or roles");
      return false;
    }
    for (const role of user.roles) {
      if (role.permissions.includes(permission)) return true;
    }
    console.log("no permission found: ", permission, user.roles);
    return false;
  }

  // Check if the user is currently logged in
  isLoggedIn() {
    const token = localStorage.getItem('jwtToken');
    return !!token; // Convert to boolean: true if token exists, false otherwise
  }
}

export default new AuthService();
