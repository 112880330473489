<template>
  <div style="margin-top:17px; margin-left: 24px" class="fill-height">
    <v-row v-if="loaded" class="fill-height">

      <v-col cols="3" class="border-right bg-white scrollable">
        <div>
          <router-link to="/app/leads">
            <v-btn prepend-icon="mdi-arrow-left" variant="plain">
              Leads
            </v-btn>
          </router-link>
        </div>
        <lead-preview></lead-preview>
      </v-col>
      <v-col :cols="midColSize" class="border-right bg-light-gray scrollable">
        <v-btn variant="text" density="compact" class="float-right" :icon="floatIcon" @click="changeColSize"></v-btn>
        <!-- Pane 2 content goes here -->
        <v-tabs v-model="tab">
          <v-tab value="panoramica">Panoramica</v-tab>
          <v-tab value="attivita">Attività</v-tab>
          <v-tab value="log">Log</v-tab>
        </v-tabs>
        <v-divider></v-divider>
        <v-window v-model="tab">
          <v-window-item value="panoramica">
            <trattative-preview></trattative-preview>
          </v-window-item>
          <v-window-item value="attivita">
            <attivita-preview></attivita-preview>
          </v-window-item>
          <v-window-item value="log">
            <log-preview></log-preview>
          </v-window-item>
        </v-window>

      </v-col>
      <v-col :cols="rightColSize" class="bg-white scrollable">
        <!-- Pane 3 content goes here -->
        <entities-preview></entities-preview>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col cols="12">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </v-col>
    </v-row>
  </div>
</template>

<style scoped>
.border-right {
  border-right: 1px solid #e1e1cd;
}

.bg-white {
  background-color: #fff;
}

.bg-light-gray {
  background-color: #fafaf6;
}

.scrollable {
  overflow-y: auto;
  /* Add scrollbar */
  height: 100%;
}
</style>

<script>
import LeadPreview from './LeadPreview.vue';
import LogPreview from './LogPreview.vue';
import AttivitaPreview from './AttivitaPreview.vue';
import EntitiesPreview from './EntitiesPreview.vue';
import TrattativePreview from './TrattativePreview.vue';
import useLeadsStore from '@/stores/leads';
import { mapState } from 'pinia';

export default {
  name: 'LeadPreviewComponent',
  components: {
    LeadPreview, LogPreview, EntitiesPreview, AttivitaPreview, TrattativePreview
  },
  computed: {
    ...mapState(useLeadsStore, ['fetchLead']),
  },
  async mounted() {
    this.loaded = false;
    await this.aggiornaLead(this.$route.params.id);
    window.scrollTo(0, 0);
    // this.$nextTick(() => {
    //   const scrollables = this.$el.querySelectorAll('.scrollable');
    //   scrollables.forEach(scrollable => {
    //     console.log("scrollable", scrollable)
    //     scrollable.scrollTop = 0;
    //   });
    // });
  },
  data: () => ({
    tab: 'attivita',
    midColSize: 6,
    rightColSize: 3,
    floatIcon: 'mdi-chevron-double-right',
    loaded: false,
  }),
  methods: {
    async aggiornaLead(id) {
      // simulate that this takes 5 seconds
      await this.fetchLead(id);
      this.loaded = true;
    },
    changeColSize() {
      if (this.midColSize === 6) {
        this.midColSize = 9;
        this.rightColSize = 0;
        this.floatIcon = 'mdi-chevron-double-left';
      } else {
        this.midColSize = 6;
        this.rightColSize = 3;
        this.floatIcon = 'mdi-chevron-double-right';
      }
    },
  },
};

</script>