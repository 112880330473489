<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" width="70%" persistent>
      <v-card>
        <v-card-title>
          <v-btn v-if="!isDeleted" :icon="locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline'" class="float-right"
            @click="locked = !locked" :color="locked ? 'red' : 'green'" variant="text" density="compact"></v-btn>
          <v-chip v-else color="red" class="mr-2 float-right" size="small">Cancellato</v-chip>
          <span class="headline"><v-icon>mdi-account</v-icon> {{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="editedItem.nome" label="Nome" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="editedItem.nickname" label="Nome Breve" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="editedItem.email" type="email" label="Email" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select v-model="editedItem.Role" :items="['Administrator', 'Sales', 'HR', 'Administration']" variant="underlined"
                density="compact" label="Ruolo" :readonly="locked" hide-details="auto" :disabled="isDeleted"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select v-model="editedItem.Tenants" :items="tenants" item-title="name" item-value="name" variant="underlined"
                density="compact" label="Tenant" :readonly="locked" hide-details="auto" :disabled="isDeleted" multiple></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-5 pb-3">
          <v-btn color="red" text @click="deleteItem" v-if="isEditMode && !isDeleted" :disabled="locked || isSubmitted">Elimina</v-btn>
          <v-spacer></v-spacer>
          {{ isSubmitted ? 'Salvataggio in corso...' : '' }}
          <v-btn color="blue darken-1" text @click="close" :disabled="isSubmitted">Annulla</v-btn>
          <v-btn color="primary" variant="elevated" @click="save" :disabled="invalid || locked || isDeleted">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import UsersService from '@/services/UsersService';
import { cloneDeep } from 'lodash';
import TenantsService from '@/services/TenantsService';

export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'save'],
  data() {
    return {
      editedItem: cloneDeep(this.item),
      locked: this.item?.id ? true : false,
      showDialog: this.modelValue,
      isSubmitted: false,
      tenants: [],
    };
  },
  async mounted() {
    console.log('UserDialog mounted');
    this.tenants = await TenantsService.fetchTenants();
  },
  watch: {
    item(newValue) {
      this.editedItem = cloneDeep(newValue);
      this.locked = newValue?.id ? true : false;
      console.log('User changed', newValue);
    },
    modelValue(newValue) {
      this.showDialog = newValue;
    },
  },
  methods: {
    async deleteItem() {
      if (!this.editedItem?.id) return;
      if (confirm('Sei sicuro di voler eliminare questo utente?')) {
        await UsersService.delete(this.editedItem.id);
        this.$emit('delete', this.editedItem);
        this.close();
      }
    },
    close() {
      this.updateModelValue(false); // Request to close the dialog
    },
    updateModelValue(value) {
      // Perform cleanup or any additional logic before the actual visibility change
      if (!value) {
        // Cleanup logic here, e.g., resetting form fields, stopping timers, etc.
        console.log("Performing cleanup before closing the dialog");
      }
      this.$emit('update:modelValue', value); // Emit an event to update the dialog's visibility
    },
    async save() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      console.log('Saving user', this.editedItem);
      await UsersService.saveUser(this.editedItem);
      this.isSubmitted = false;
      this.$emit('save', this.editedItem);
    },
  },
  computed: {
    isDeleted() {
      // :disabled="isDeleted"
      return this.item?.active === 0;
    },
    isEditMode() {
      return this.item?.id !== undefined;
    },
    formTitle() {
      return this.isEditMode ? 'Modifica utente' : 'Aggiungi utente';
    },
    invalid() {
      if (!this.editedItem) {
        console.log('Invalid: editedItem is null');
        return true;
      }
      if (!this.editedItem.nome) {
        console.log('Invalid: nome is null');
        return true;
      }
      if (this.editedItem.nome.length === 0) {
        console.log('Invalid: nome is empty');
        return true;
      }
      if (!this.editedItem.email) {
        console.log('Invalid: email is null');
        return true;
      }
      if (this.editedItem.email.length === 0) {
        console.log('Invalid: email is empty');
        return true;
      }
      if (!this.editedItem.email.includes('@')) {
        console.log('Invalid: email is invalid');
        return true;
      }
      if (!this.editedItem.nickname) {
        console.log('Invalid: nickname is null');
        return true;
      }
      if (this.editedItem.nickname.length === 0) {
        console.log('Invalid: nickname is empty');
        return true;
      }
      if (!this.editedItem.Role) {
        console.log('Invalid: role is null', this.editedItem.Role);
        return true;
      }
      if (!this.editedItem.Tenants?.length) {
        console.log('Invalid: tenants is null');
        return true;
      }
      return false;
    },
  },
};
</script>

<style scoped>
.input-disabled input:disabled {
  color: black;
}
</style>
