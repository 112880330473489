import { defineStore } from 'pinia'
import axios from 'axios'
import AuthService from '@/services/AuthService'

const useListsStore = defineStore('lists', {
  state: () => ({
    lists: [],
    validLists: false
  }),
  actions: {
    async fetchList(listName) {
      console.log("fetchList", listName);
      if (this.validLists && this.lists[listName]) return this.lists[listName]; // Already fetched
      AuthService.refreshToken();
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/lists/' + listName);
        this.lists[listName] = response.data
        console.log("list", this.lists);
        this.validLists = true;
        //return this.lists[listName] = response.data;
      } catch (error) {
        console.error('Failed to fetch list:', error);
        throw error; // Handle error appropriately in your application
      }
    },
    invalidate() {
      this.validLists = false;
    }
  },
})

export default useListsStore;