<template>
  <div :style="{ 'display': (inline ? 'inline-block' : 'block') }">
    <v-hover v-slot="{ isHovering, props }">
      <a :href="linkedinUrl" target="_blank">
        <span v-if="hideIcon" v-bind="props">{{ username }}</span>
        <v-icon v-else class="mr-1" :class="{ 'on-hover': isHovering }" v-bind="props">mdi-linkedin</v-icon>{{ username }}
      </a>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: 'LinkedInComponent',
  props: {
    linkedin: String,
    hideIcon: { type: Boolean, default: false},
    hideUsername: { type: Boolean, default: false},
    inline: { type: Boolean, default: false},
  },
  computed: {
    linkedinUrl() {
      if (this.linkedin.startsWith('http')) {
        return this.linkedin;
      } else if (this.linkedin.indexOf('/') !== -1) {
        return "https://" + this.linkedin;
      } else {
        return `https://www.linkedin.com/in/${this.linkedin}`;
      }
    },
    username() {
      if (this.hideUsername) {
        return '';
      }
      return this.linkedin.split('/').pop();
    }
  }
}
</script>

<style scoped>
a {
  color: rgb(0, 0, 238);
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.on-hover {
  transition: transform 0.3s ease;
  transform: scale(1.3); /* Increase the size by 20% */
}
</style>