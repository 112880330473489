
<template>
  <div>
    <v-row class="pt-5 pl-5 pr-5">
    <v-col cols="12" md="3" lg="2">
      <v-select v-model="utenzaApp" :items="utenti" item-title="nome" item-value="id" label="Seleziona Utente"
        density="compact" hide-details variant="underlined" @update:model-value="loadTasks"></v-select>
    </v-col>
  </v-row>

  <div class="kanban-board">
    <kanban-column
      v-for="column in colonne"
      :key="column.name"
      :column="column"
      :attivita="tasks.filter(task => Array.isArray(column.state) ? column.state.includes(task.stato) : task.stato === column.state)"
      @taskUpdated="handleTaskUpdate"
      @moveTask="handleMoveTask"
    />
  </div>
  </div>
</template>

<script>
import KanbanColumn from '@/components/kanban/KanbanColumn.vue';
import { mapState, mapWritableState } from 'pinia';
import useUsersStore from '@/stores/users';
import TrattativeService from '@/services/TrattativeService';

export default {
  components: {
    KanbanColumn
  },
  data() {
    return {
      tasks: [],
      colonne: [
        { name: 'In valutazione', state: 'in valutazione', style: { backgroundColor: '#f7f7f7' } , collapsed: true},
        { name: 'In attesa di contatto', state: 'in attesa di contatto', style: { backgroundColor: '#f7f7f7' }, collapsed: true },
        { name: 'Contattato', state: 'contattato', style: { backgroundColor: '#f7f7f7' } },
        { name: 'Qualificata', state: 'qualificata', style: { backgroundColor: '#f0fff0' } },
        { name: 'In preparazione', state: 'in preparazione', style: { backgroundColor: '#f7f7f7' } },
        { name: 'In negoziazione', state: 'in negoziazione', style: { backgroundColor: '#f7f7f7' }, collapsed: true },
        { name: 'Presentata', state: 'presentata', style: { backgroundColor: '#f7f7f7' } },
        { name: 'Accettata', state: 'accettata', style: { backgroundColor: '#f0fff0' } },
        { name: 'KO', state: ['non idonea', 'non qualificata', 'rifiutata', 'scaduta'], style: { backgroundColor: '#fff0f0' } },
      ],
      utenti: [],
      loadingTrattativeInScadenza: false,
    };
  },
  computed: {
    ...mapState(useUsersStore, ['users', 'fetchUsers', 'fixUtenze']),
    ...mapWritableState(useUsersStore, ['utenzaApp']),
  },
  async mounted() {
    this.utenti = [{ id: 0, nome: 'Tutti tranne Utente di Sistema' }].concat(await this.fetchUsers());
    this.fixUtenze();
    this.loadTasks();
    console.log('Kanban mounted');
    this.loadingTrattativeInScadenza = false;
  },
  methods: {
    handleTaskUpdate(task) {
      // find and update task by id in tasks array
      const index = this.tasks.findIndex(t => t.id === task.id);
      if (index !== -1) {
        this.tasks[index] = task;
      }
    },
    async loadTasks() {
      // Replace this with your actual data fetching logic
      await this.fetchTrattativeInScadenza();
      console.log('tasks', this.tasks)
    },
    handleMoveTask(task, fromColumn, toColumn) {
      console.log("handleMoveTask", task, fromColumn, toColumn)
      const offerteStates = ['in preparazione', 'in negoziazione', 'presentata', 'accettata'];
      if (task.type === 'opportunita' && offerteStates.includes(toColumn)) {
        task = TrattativeService.upgrade(task);
        if (task.stato === toColumn) {
          task.originalStato = task.stato;
          return;
        }
      }
      task.stato = toColumn;
      if (task.stato === 'non idonea,non qualificata,rifiutata,scaduta') {
        if (task.type === 'opportunita')
          task.stato = 'non idonea';
        else
          task.stato = 'rifiutata';
      }
      task = TrattativeService.update(task);
      task.originalStato = task.stato;
    },
    fetchTasks() {
      // Simulated fetch call
      return Promise.resolve([
        { id: 1, title: 'Task 1', state: 'todo' },
        { id: 2, title: 'Task 2', state: 'in-progress' },
        { id: 3, title: 'Task 3', state: 'done' }
      ]);
    },
    async fetchTrattativeInScadenza() {
      const page = 0;
      const itemsPerPage = 100;
      const sortBy = [];
      if (this.loadingTrattativeInScadenza) {
        return; // Prevent fetching if already loading
      }
      this.loadingTrattativeInScadenza = true;
      console.log("fetchTrattativeInScadenza utenza:", this.utenzaApp)
      TrattativeService.fetchTrattativeInScadenza(page, itemsPerPage, sortBy, this.utenzaApp, true).then((data) => {
        console.log("Data", data)
        // store item.state in item.originalState
        data.items.forEach(item => item.originalStato = item.stato.toString())  ;
        this.tasks = data.items;
        //this.totalTrattativeInScadenza = data.totalCount;
        this.loadingTrattativeInScadenza = false;
      }).catch((error) => {
        console.error('Error fetching trattative in scadenza:', error);
        this.loadingTrattativeInScadenza = false;
      })
      //this.leads = await this.leadsStore.fetchLeads();
    },

  }
};
</script>

<style>
.kanban-board {
  display: flex;
  gap: 10px;
  padding-left: 16px;
  padding-right: 8px;
  padding-top: 8px;
  padding-bottom: 8px;
}
</style>
