import axios from 'axios';
import AuthService from '@/services/AuthService';

class ContactsService {
  async get(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/contatti/' + id);
      console.log("contatto:", id, response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch contatto:', error);
      return null;
    }
  }
  async update(contact) {
    try {
      AuthService.refreshToken();
      const response = await axios.put(process.env.VUE_APP_API_URL + '/api/contatti/'+contact.id, contact);
      console.log("saveContact", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to save contact:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async delete(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.delete(process.env.VUE_APP_API_URL + '/api/contatti/'+id);
      console.log("deleteContact", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to delete contact:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async create(contact) {
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/contatti', contact);
      console.log("newContact", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to create new contact:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async saveContact(contatto, { currentLead, postSaveFunctions = [] }) {
    if (contatto.id) {
      console.log('update', contatto);
      await this.update(contatto);
    } else {
      if (!contatto.idLead && currentLead && currentLead.id) {
        contatto.idLead = currentLead.id;
      }
      console.log('create', contatto);
      await this.create(contatto);
    }
    console.log('saveContact', contatto);

    // Execute each post-save function in the array
    for (const func of postSaveFunctions) {
      if (typeof func === 'function') {
        await func(currentLead.id, true);
      }
    }
  }
}

export default new ContactsService();