<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" width="50%" persistent>
      <v-card>
        <v-card-title>
          <v-btn v-if="!isDeleted" :icon="locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline'" class="float-right"
            @click="locked = !locked" :color="locked ? 'red' : 'green'" variant="text" density="compact"></v-btn>
          <v-chip v-else color="red" class="mr-2 float-right" size="small">Cancellata</v-chip>
          <span class="headline">
            <v-icon>{{ formIcon }}</v-icon>
            {{ formTitle }}
            <v-icon class="clickable" color="primary" @click="changeModalita()" v-if="isInboundOutbound">{{ modalitaIcon
              }}</v-icon>
          </span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="4" class="pt-0 pb-0">
              <v-text-field ref="ragioneSociale" label="Ragione Sociale" variant="underlined"
                v-model="editedItem.Lead.ragioneSociale" density="compact" hide-details="auto" readonly></v-text-field>
            </v-col>
            <v-col v-if="editedItem.Lead?.telefono" cols="12" sm="3" md="2" lg="2" class="pt-0 pb-0">
              <div class="telephone-field">
                <label class="telephone-label">Telefono</label>
                <a target="_blank" :href="`tel:${editedItem.Lead.telefono}`" class="telephone-link" :title="editedItem.Lead.telefono">
                  {{ editedItem.Lead.telefono }}
                </a>
              </div>
            </v-col>
            <v-col v-if="isInboundOutbound" cols="4" sm="3" md="2" lg="2" class="pt-0 pb-0">
              <v-select v-model="editedItem.modalita" variant="underlined" density="compact"
                :items="['inbound', 'outbound']" return-object item-title="surname" label="Modalità"
                :disabled="isDeleted" :readonly="locked"></v-select>
            </v-col>
            <v-col cols="4" sm="6" md="3" lg="3" class="pt-0 pb-0">
              <v-select v-model="editedItem.Contatto" variant="underlined" density="compact" :readonly="locked"
                :items="contacts" item-title="nome" label="Contatto" return-object :disabled="isDeleted"></v-select>
            </v-col>
            <v-col v-if="editedItem.Contatto?.phone" cols="12" sm="3" md="2" lg="2" class="pt-0 pb-0">
              <div class="telephone-field">
                <label class="telephone-label">Telefono</label>
                <a target="_blank" :href="`tel:${editedItem.Contatto.phone}`" class="telephone-link" :title="editedItem.Contatto.phone">
                  {{ editedItem.Contatto.phone }}
                </a>
              </div>
            </v-col>
            <v-col cols="12" sm="8" md="4" lg="4" class="pt-0 pb-0">
              <v-text-field type="datetime-local" density="compact" variant="underlined"
                v-model="editedItem.dataEsecuzione" label="Data" :disabled="isDeleted"
                :readonly="locked"></v-text-field>
            </v-col>
            <v-col cols="4" sm="4" md="3" lg="3" class="pt-0 pb-0">
              <v-select v-model="editedItem.esito" variant="underlined" density="compact"
                :items="['da-fare', 'completata', 'non-completata', 'annullata']" return-object item-title="surname"
                label="Esito" :disabled="isDeleted" :readonly="locked"></v-select>
            </v-col>
            <v-col v-if="false && !locked && ! isDeleted && editedItem.tipo === 'nota' && editedItem.esito == 'completata'" cols="4" sm="4" md="3" lg="3"
              class="pt-0 pb-0 text-center">
              <v-btn variant="tonal" color="green" @click="ricordamelo()" class="mt-1" :disabled="isDeleted"
                :readonly="locked">Ricordamelo</v-btn>
            </v-col>
            <v-col cols="4" sm="6" md="3" lg="3" class="pt-0 pb-0">
              <v-select v-model="editedItem.AssegnatoA" variant="underlined" :items="users" label="Assegnata a"
                density="compact" item-title="nome" return-object :disabled="isDeleted" :readonly="locked"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0 pb-0">
              <v-textarea row="1" v-model="editedItem.descrizione" density="compact" :readonly="locked"
                :placeholder="descriptionPrompt" auto-grow :disabled="isDeleted"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-5 pb-3">
          <v-spacer></v-spacer>
          {{ isSubmitted ? 'Salvataggio in corso...' : '' }}
          <v-btn color="blue darken-1" text @click="close" :disabled="isSubmitted">Annulla</v-btn>
          <v-btn color="primary" variant="elevated" @click="save" :disabled="isDeleted || locked || isSubmitted">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import useLeadsStore from '@/stores/leads';
import useUsersStore from '@/stores/users';
import { mapState } from 'pinia';
import AuthService from '@/services/AuthService';
import AttivitaService from '@/services/AttivitaService';
import { cloneDeep } from 'lodash';

export default {
  name: 'AttivitaEditDialog',
  props: {
    item: {
      type: Object,
      default: () => null //{ return this.createNewAttivita() },
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'save'],
  data() {
    return {
      editedItem: cloneDeep(this.item), // this.setEditItem(this.item),
      locked: this.item?.id ? true : false,
      showDialog: this.modelValue,
      isSubmitted: false,
    };
  },
  mounted() {
      this.fetchUsers();
  },
  watch: {
    item(newValue) {
      console.log('Attivita changed', newValue)
      this.setEditItem(newValue);
    },
    modelValue(newValue) {
      this.showDialog = newValue;
    },
  },

  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'contacts', 'loadContatti']),
    ...mapState(useUsersStore, ['users', 'fetchUsers']),
    isDeleted() {
      // :disabled="isDeleted"
      return this.editedItem?.active === 0;
    },
    formIcon() {
      switch (this.editedItem.tipo) {
        case 'email':
          return 'mdi-email-outline';
        case 'telefono':
          return 'mdi-phone';
        case 'nota':
          return 'mdi-note-outline';
        case 'appuntamento':
          return 'mdi-calendar-clock';
        default:
          return '';
      }
    },
    formTitle() {
      switch (this.editedItem.tipo) {
        case 'email':
          return 'Email';
        case 'telefono':
          return 'Telefonata';
        case 'nota':
          return 'Nota';
        case 'appuntamento':
          return 'Evento';
        default:
          return '';
      }
    },
    modalitaIcon() {
      if (this.editedItem.modalita == 'inbound')
        return 'mdi-arrow-bottom-left';
      else if (this.editedItem?.modalita == 'outbound')
        return 'mdi-arrow-top-right';
      else
        return '';
    },
    descriptionPrompt() {
      switch (this.editedItem.tipo) {
        case 'email':
          return 'Inizia a digitare per scrivere l\'email';
        case 'telefono':
          return 'Inizia a digitare per descrivere la telefonata';
        case 'nota':
          return 'Inizia a digitare per lasciare una nota';
        case 'appuntamento':
          return 'Inizia a digitare per descrivere l\'evento';
        default:
          return '';
      }

    },
    isInboundOutbound() {
      return this.editedItem.tipo != 'nota' && this.editedItem.tipo != 'appuntamento';
    },
  },
  methods: {
    changeModalita() {
      if (this.editedItem.modalita == 'inbound')
        this.editedItem.modalita = 'outbound';
      else
        this.editedItem.modalita = 'inbound';
    },
    ricordamelo() {
      console.log(this.editedItem);
      console.log('ricordamelo');
      this.editedItem.esito = 'da-fare';
      console.log(this.editedItem);
    },

    setEditItem(newValue) {
      console.log('setEditItem', newValue);
      if (newValue == null) {
        this.editedItem = null;
        return;
      }
      this.editedItem = cloneDeep(newValue);
      if (this.editedItem.dataEsecuzione == null)
        this.editedItem.dataEsecuzione = (new Date()).toISOString().substring(0, 16);
      else
        this.editedItem.dataEsecuzione = this.formatDate(this.editedItem.dataEsecuzione);
      if (this.editedItem.idAssegnatoA == null) {
        const user = AuthService.getUserDetails();
        this.editedItem.idAssegnatoA = user?.id;
        this.editedItem.AssegnatoA = this.users.find(u => u.id == user?.id);
      }
    },
    formatDate(dateString) {
      if (!dateString) return '';
      let date = new Date(dateString);
      if (date.toString() === 'Invalid Date') {
        date = new Date(dateString.replace(' ', 'T'));
      }
      return (new Date(date)).toISOString().substring(0, 16)
    },
    close() {
      console.log("closing")
      this.updateModelValue(false); // Request to close the dialog
    },
    updateModelValue(value) {
      // Perform cleanup or any additional logic before the actual visibility change
      if (!value) {
        // Cleanup logic here, e.g., resetting form fields, stopping timers, etc.
        console.log("Performing cleanup before closing the dialog");
      }
      this.$emit('update:modelValue', value); // Emit an event to update the dialog's visibility
    },
    async save() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      await AttivitaService.saveAttivita(this.editedItem, {
        currentLead: this.currentLead,
        postSaveFunctions: [],
      });
      this.isSubmitted = false;
      this.$emit('save', this.editedItem);
    },
  },
};
</script>

<style scoped>
.clickable {
  cursor: pointer;
}
.align-vertical-center {
  display: flex;
  align-items: center; /* Centers the content vertically */
}

.telephone-field {
  display: flex;
  flex-direction: column;
  margin-top:-11px;
}

.telephone-label {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  padding-left: 0px; /* Aligns label as per Vuetify's underlined style */
  margin-bottom: 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.telephone-link {
  color: rgba(0, 0, 255);
  padding: 0px; /* Mimicking Vuetify's text-field padding */
  display: block; /* Ensures the underline spans the full width */
  text-decoration: none; /* Removes underline from link */
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.telephone-link:hover {
  text-decoration: underline; /* Adds underline on hover */
}
</style>