import { defineStore } from 'pinia'
import axios from 'axios'
import AuthService from '@/services/AuthService';

const useTenantsStore = defineStore('tenants', {
  state: () => ({
    tenants: [],
    currentTenant: 0,
    valid: false
  }),
  getters: {
  },
  actions: {
    setTenants() {
      if (this.valid && this.tenants.length > 0) return; // Already fetched
      AuthService.refreshToken();
      const user = AuthService.getUserDetails();
      console.log('user', user);
      if (user?.tenants?.length > 0) {
        this.tenants = user.tenants;
        this.currentTenant = user.currentTenant ?? 0;
        this.valid = true;
      }
    },
    getCurrentTenant() {
      this.setTenants();
      return this.tenants[this.currentTenant];
    },
    async changeTenant(index) {
      if (index === this.currentTenant) return false;
      if (index < 0 || index >= this.tenants.length) return false;
      const tenantId = this.tenants[index].id;
      try {
      AuthService.refreshToken();
      await axios.post(process.env.VUE_APP_API_URL + '/api/select-tenant', { tenantId });
        this.currentTenant = index;
        return true;
      } catch (error) {
        console.error('Failed to change tenant:', error);
      }
      return false;
    },
    invalidate() {
      this.valid = false;
    }
  },
})

export default useTenantsStore;