import axios from 'axios';
import AuthService from '@/services/AuthService';

class LeadsService {
  fetchLeadsPaged(page, itemsPerPage, sortBy, searchString, utenza = 0) {
    let params = '';
    if (sortBy.length) {
      params += `&sortBy=${sortBy[0].key}:${sortBy[0].order}`;
    }
    if (searchString && searchString.length >= 2) {
      params += `&search=${searchString}`;
    }
    if (utenza > 0) {
      params += `&utenza=${utenza}`;
    }
    console.log("search", searchString)
    // It's assumed AuthService.refreshToken() is synchronous or its promise is handled elsewhere
    AuthService.refreshToken();
    console.log('fetchLeadsPaged', page, itemsPerPage, sortBy);
    // Return the axios promise directly
    return axios.get(`${process.env.VUE_APP_API_URL}/api/leads?page=${page}&pageSize=${itemsPerPage}${params}`)
      .then(response => {
        console.log("leads", response.data);
        return response.data; // Return data for further processing if needed
      })
      .catch(error => {
        console.error('Failed to fetch leads:', error);
        throw error; // Re-throw the error to allow caller to handle it
      });
  }
  async fetchLogLead(id, page = 0, itemsPerPage = 10) {
    AuthService.refreshToken();
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads/' + id + '/attivita?isLog=true&page=' + page + '&pageSize=' + itemsPerPage);
      console.log("lead attivita:", id, response.data);
      response.data.items.forEach(attivita => {
        attivita.dataEsecuzione = attivita.dataEsecuzione.substring(0, 16);
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch lead attivita:', error);
      return null;
    }
  }
  async fetchPanoramicaLead(id) {
    AuthService.refreshToken();
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads/' + id + '/panoramica');
      console.log("lead panoramica:", id, response.data);
      // cycle through the lead's attivita and format the dataEsecuzione
      // as dataEsecuzione.substring(0, 16);
      response.data.items.forEach(attivita => {
        if (attivita.dataEsecuzione)
          attivita.dataEsecuzione = attivita.dataEsecuzione.substring(0, 16);
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch lead panoramica:', error);
      return null;
    }
  }
  async fetchAttivitaLead(id, type = 'all', page = 0, itemsPerPage = 10) {
    AuthService.refreshToken();
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads/' + id + '/attivita?type=' + type + '&isLog=false&page=' + page + '&pageSize=' + itemsPerPage);
      console.log("lead attivita:", id, response.data);
      // format each attivita.dataEsecusione
      response.data.items.forEach(attivita => {
        attivita.dataEsecuzione = attivita.dataEsecuzione.substring(0, 16);
      });
      return response.data;
    } catch (error) {
      console.error('Failed to fetch lead attivita:', error);
      return null;
    }
  }
  async searchPIVA(piva) {
    AuthService.refreshToken();
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads/piva/' + piva);
      console.log("piva:", piva, response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch piva:', error);
      return null;
    }
  }
  async fetchContattiLead(id, page = 0, itemsPerPage = 10) {
    AuthService.refreshToken();
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads/' + id + '/contatti?page=' + page + '&pageSize=' + itemsPerPage);
      console.log("lead contatti:", id, response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch lead attivita:', error);
      return null;
    }
  }
  searchSpecializzazione(searchString) {
    console.log("searchSpecializzazioni", searchString);
    return axios.get(`${process.env.VUE_APP_API_URL}/api/leads/specializzazioni?q=${searchString}`)
      .then(response => {
        console.log("specializzazione", response.data);
        return response.data; // Return data for further processing if needed
      })
      .catch(error => {
        console.error('Failed to fetch specializzazioni:', error);
        throw error; // Re-throw the error to allow caller to handle it
      });
  }
}

export default new LeadsService();