import { defineStore } from 'pinia'
import axios from 'axios'
import AuthService from '@/services/AuthService';
import LeadsService from '@/services/LeadsService';

const useLeadsStore = defineStore('leads', {
  state: () => ({
    leads: [],
    validLeads: false,
    currentLead: null,
    attivitaChanged: false,
    totalLogCount: 0,
    contacts: [],
    logs: [],
  }),
  actions: {
    async loadMoreLog(id = null, reset = false) {
      if (id == null) {
        console.log("*****currentLead", this.currentLead);
        if (this.currentLead == null) return;
        id = this.currentLead.id;
      } else {
        console.log("*****id", id);
      }
      let page = this.logs.length / 10 + 1;
      if (reset) {
        console.log("reset logs");
        this.attivitaChanged = !this.attivitaChanged;
        this.logs = [];
        this.totalLogCount = 0;
        page = 0;
      }
      const logs = await LeadsService.fetchLogLead(id, page);
      console.log("logs", logs);
      this.logs = this.logs.concat(logs.items);
      if (logs.totalCount !== this.totalLogCount) {
        this.totalLogCount = logs.totalCount;
      }
    },
    async loadContatti(id = null, reset = false) {
      if (id == null) {
        console.log("*****currentLead", this.currentLead);
        if (this.currentLead == null) return;
        id = this.currentLead.id;
      } else {
        console.log("*****id", id);
      }
      let page = 0;
      if (reset) {
        console.log("reset contatti");
        this.contacts = [];
        page = 0;
      }
      const contatti = await LeadsService.fetchContattiLead(id, page);
      console.log("contatti", contatti);
      this.contacts = contatti.items.map(item => ({
        ...item,
        nome: `${item.name} ${item.surname}`
      }));

    },
    async fetchLeads() {
      if (this.validLeads && this.leads.length > 0) return this.leads; // Already fetched
      AuthService.refreshToken();
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads');
        console.log("leads", response.data.items);
        this.validLeads = true;
        return this.leads = response.data.items;
      } catch (error) {
        console.error('Failed to fetch leads:', error);
        throw error; // Handle error appropriately in your application
      }
    },
    clearCurrentState() {
      this.currentLead = null;
      console.log("reset logs");
      this.attivitaChanged = !this.attivitaChanged;
      this.logs = [];
      this.totalLogCount = 0;
      this.contacts = [];
    },
    async fetchLead(id) {
      if (this.currentLead && this.currentLead.id === id) return this.currentLead; // Already fetched
      AuthService.refreshToken();
      this.clearCurrentState();
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads/' + id);
        console.log("lead:", id, response.data);
        this.currentLead = response.data;
        return response.data;
      } catch (error) {
        console.error('Failed to fetch lead:', error);
        return null;
      }
    },
    async createLead(lead) {
      console.log('createLead', lead);
      AuthService.refreshToken();
      try {
        const response = await axios.post(process.env.VUE_APP_API_URL + '/api/leads', lead);
        console.log("newLead", response.data);
        this.invalidate();
        return response.data.item;
      } catch (error) {
        console.error('Failed to create new lead:', error);
        throw error; // Handle error appropriately in your application
      }
    },
    async updateLead(leadId, changes) {
      console.log('updateLead', leadId, changes);
      AuthService.refreshToken();
      try {
        const response = await axios.put(process.env.VUE_APP_API_URL + '/api/leads/' + leadId, changes);
        this.currentLead = response.data.item;
        return response.data.item;
      } catch (error) {
        console.error('Failed to update lead:', error);
        return null;
      }
    },
    deleteLead(leadId) {
      if (this.currentLead && this.currentLead.id === leadId) {
        this.clearCurrentState();
      }
      console.log('deleteLead', leadId);
      AuthService.refreshToken();
      return axios.delete(process.env.VUE_APP_API_URL + '/api/leads/' + leadId)
        .then(response => {
          console.log('deleteLead', leadId, response.data);
          this.invalidate();
          return response.data;
        })
        .catch(error => {
          console.error('Failed to delete lead:', error);
          throw error; // Handle error appropriately in your application
        });
    },
    invalidate() {
      console.log('invalidate leadsStore');
      this.validLeads = false;
      this.leads = [];
    }
  },
})

export default useLeadsStore;