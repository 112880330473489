<template>
  <v-card class="mt-3 pa-3 custom-border" :class="{ 'da-fare': daFare }" width="100%" variant="flat">
    <v-card-text class="pa-0">
      <v-row>
        <v-col cols="12">
          <div class="contenuto">
            <div class="mb-1 d-flex justify-space-between">
              <v-chip size="small" :color="getTypeColor">
                {{ getStatoLabel }}
              </v-chip>
              <span :style="{ color: getStatoColor }">{{ trattativa.stato }}</span>
            </div>
            <div class="mb-2 mt-2 d-flex justify-space-between card-title">
              <span>
                {{ trattativa.oggetto }}
              </span>
              <span class="float-right">{{ trattativa.numero }}</span>
            </div>
            <div
              v-if="daFare">
              <div class="d-flex justify-space-between mb-1">
                <span>Scadenza:</span>
                <span :class="coloreScadenza(trattativa.dataScadenza)" :title="formatData(trattativa.dataScadenza)">{{ getData(trattativa.dataScadenza) }}</span>
              </div>
            </div>
            <div v-if="trattativa.type === 'contratto' || trattativa.type === 'offerta'">
              <div class="d-flex justify-space-between mb-1">
                <span>Inizio:</span>
                <span>{{ trattativa.dataInizio }}</span>
              </div>
              <div class="d-flex justify-space-between mb-1">
                <span>Termine:</span>
                <span>{{ trattativa.dataTermine }}</span>
              </div>
            </div>
            <div v-else class="d-flex justify-space-between mb-1">
              <span>Prob.:</span>
              <span>{{ trattativa.probabilita }}</span>
            </div>
            <div class="d-flex justify-space-between mb-1">
              <span>Valore:</span>
              <span>{{ formatNumber(trattativa.valore) }}</span>
            </div>
            <div class="text-medium-emphasis mt-2" v-if="trattativa.Contatto">
              <v-icon>mdi-account-circle-outline</v-icon>
              {{ trattativa.Contatto?.nome }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'pinia';
import useLeadsStore from '@/stores/leads';

export default {
  name: 'TrattativaItem',
  props: {
    trattativa: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead']),
    colorClass() {
      if (this.trattativa.esito === 'da-fare' && new Date(this.trattativa.dataEsecuzione) > new Date()) {
        return 'future-active';
      }
      return '';
    },
    daFare() {
      return ['in valutazione', 'in attesa di contatto', 'contattato', 'in preparazione','in negoziazione', 'presentata'].includes(this.trattativa.stato);
    },
    getModalita() {
      if (this.trattativa.tipo === 'appuntamento' || this.trattativa.tipo === 'nota')
        return '';
      return ' ' + this.trattativa.modalita + ' ';
    },
    getStatoLabel() {
      if (!this.trattativa.type) return '';
      switch (this.trattativa.type) {
        case 'contratto':
          return 'Contratto';
        case 'opportunita':
          return 'Opportunità';
        case 'offerta':
          return 'Offerta';
      }
      return '';
    },
    getTypeColor() {
      if (!this.trattativa.type) return 'black';
      if (this.trattativa.type == 'contratto')
        return 'green';
      if (this.trattativa.type == 'opportunita')
        return 'gray';
      if (this.trattativa.type == 'offerta')
        return 'blue';
      return 'black';
    },
    getStatoColor() {
      if (!this.trattativa.stato) return 'black';
      if (this.trattativa.stato.substr(0, 3) == 'non' || this.trattativa.stato == 'rifiutata' || this.trattativa.stato == 'scaduto')
        return 'red';
      if (this.trattativa.stato == 'qualificata' || this.trattativa.stato == 'accettata' || this.trattativa.stato == 'attivo')
        return 'green';
      if (this.trattativa.stato == 'sospeso')
        return 'orange';
      return 'black';
    },

  },
  methods: {
    formatNumber(value) {
      return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
    },
    coloreScadenza(data) {
      return this.scadenzaFutura(data) ? 'futura' : 'scaduta';
    },
    scadenzaFutura(data) {
      return new Date(data) >= new Date();
    },
    formatData(data) {
      return new Intl.DateTimeFormat('it-IT', { dateStyle: 'short' }).format(new Date(data));
    },
    getData(data) {
      const date = new Date(data);
      const diff = date - new Date();
      if (diff > 0) {
        const days = Math.round(diff / (1000 * 60 * 60 * 24));
        let rtf = new Intl.RelativeTimeFormat('it-IT', { numeric: 'auto' });
        if (days < 7) {
          const giorno = Intl.DateTimeFormat('it-IT', { weekday: 'long' }).format(date);
          const ora = Intl.DateTimeFormat('it-IT', { hour: 'numeric', minute: 'numeric' }).format(date);
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24)), 'day') + ': ' + giorno + ', alle ' + ora;
        }
        if (days < 60)
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 7)), 'week');
        if (days < 365)
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 30)), 'month');
        return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 365)), 'year');
      }
      let dft = new Intl.DateTimeFormat('it-IT', { dateStyle: 'short' });
      return dft.format(date);
    },
  },
};
</script>

<style scoped>
.custom-border {
  border: none;
  background-color: rgb(232 232 229);
  cursor: pointer;
}

.custom-border.da-fare {
  border: none;
  background-color: #ffffff;
}

.titolo {
  font-size: 1.2em;
}

.future-active {
  color: #00a200;
  font-weight: 500;
}
</style>