<template>
  <v-card class="clickable-icon d-flex flex-column align-center justify-center" @click="onClick" variant="flat">
    <v-icon>{{ icon }}</v-icon>
    <span class="text-caption mt-2">{{ text }}</span>
  </v-card>
</template>

<script>
export default {
  name: 'ClickableIcon',
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    onClick() {
      console.log('action');
      this.$emit('action');
    },
  },
};
</script>

<style scoped>
.clickable-icon {
  cursor: pointer;
  text-align: center;
  width: 64px; /* Adjust size as needed */
  height: 64px; /* Adjust size as needed */
  display: inline-flex !important; /* Make it inline and keep flex properties */
  margin: 4px; /* Optional: Adds some space around the component */
}
.clickable-icon:hover {
  color: rgb(0, 0, 238);
}
</style>
