<template>
  <v-card>
    <v-card-title>
      <v-row class="mt-1 ml-1 pb-1">
      <v-icon icon="mdi-account" start></v-icon>Utenti
      <v-btn size="x-small" prepend-icon="mdi-plus" class="ml-5" color="primary"
        @click="openCreateUserDialog">Nuovo Utente</v-btn>
      <v-spacer class="mt-1"></v-spacer>
      <v-text-field v-model="search" density="compact" append-icon="mdi-magnify" label="Filtra"
        variant="underlined" hide-details></v-text-field>
      </v-row>
    </v-card-title>
    <div class="pa-5">
      <v-data-table-server
        :headers="headers"
        :items="users"
        :items-length="totalItems"
        :loading="loading"
        :search="search"
        :items-per-page="10"
        items-per-page-text="Utenti per pagina"
        loading-text="Caricamento in corso..."
        no-data-text="Nessun utente trovato"
        page-text="{0}-{1} di {2}"
        @update:options="loadItems">
        <template v-slot:[`item.nome`]="{ item }">
          <a href="#" @click.prevent="openEditDialog(item)">
            {{ item.nome ? item.nome : '---'}}
          </a>
        </template>
      </v-data-table-server>

    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
import AuthService from '@/services/AuthService';
import DialogManagerService from '@/services/DialogManagerService';

export default {
  name: 'UsersComponent',
  data() {
    return {
      search: '',
      users: [],
      headers: [
        { title: 'Nome', value: 'nome', sortable: true },
        { title: 'Email', value: 'email', sortable: true },
        { title: 'Role', value: 'Role', sortable: true },
      ],
      totalItems: 0,
      loading: false,
    };
  },
  // async mounted() {
  //   try {
  //     AuthService.refreshToken();
  //     const response = await axios.get(process.env.VUE_APP_API_URL + '/api/users');
  //     this.users = response.data;
  //   } catch (error) {
  //     console.error('Error fetching users:', error);
  //   }
  // },
  methods: {
    async loadItems({ page, pageSize, sortBy }) {
      this.loading = true;
      try {
        AuthService.refreshToken();
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/users', {
          params: {
            page,
            pageSize,
            sortBy: sortBy[0] ? `${sortBy[0].key}:${sortBy[0].order}` : null,
            search: this.search.length > 0 ? this.search : null,
          },
        });
        this.users = response.data.items;
        this.totalItems = response.data.totalCount;
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        this.loading = false;
      }
    },
    openCreateUserDialog() {
      const user = {
        nome: '',
        email: '',
        nickname: '',
        active: 1,
        tenants: [],
        role: 'Sales',
      }
      ; // Null indicates creating a new lead
      DialogManagerService.openDialog('user', user, [this.updateModifiedItem]);

      this.$emit('openCreateUserDialog');
    },
    updateModifiedItem(unusedId, unusedVal, item) {
      console.log('updateModifiedItem', item);
      // replace the item in the list of users, if not found, add it
      const index = this.users.findIndex((u) => u.id === item.id);
      if (index >= 0) {
        this.users.splice(index, 1, item);
      } else {
        this.users.push(item);
      }
    },
    async openEditDialog(item) {
      DialogManagerService.openDialog('user', item, [this.updateModifiedItem]);
    },

  },
};
</script>
