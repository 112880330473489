<template>
  <div class="kanban-column" :style="column.style" :data-state="column.state" :class="{ 'collapsed': collapsed }">
    <div v-if="collapsed" class="vertical">
      <draggable class="drag-area" v-model="tasks" group="tasks" :sort="false" item-key="id" :move="onMove" @add="onAdd"
        style="height: 80vh; overflow-y: auto">
        <template #header>
          <v-btn icon="mdi-chevron-right" density="compact" class="float-left square-icon-button" variant="tonal"
            @click="collapsed = !collapsed"></v-btn>
          <h2 class="mt-9">{{ column.name + ' ' + tasks.length }}</h2>
        </template>
        <template #item="{element}">
          {{element?.pippo}}
        </template>
      </draggable>
    </div>
    <div v-else class="horizontal">
      <draggable class="drag-area" v-model="tasks" group="tasks" :sort="false" item-key="id" :move="onMove" @add="onAdd"
        style="/*height: 80vh; overflow-y: auto*/">
        <template #header>
          <v-btn icon="mdi-chevron-left" density="compact" class="float-right square-icon-button" variant="tonal"
            @click="collapsed = !collapsed"></v-btn>
          <h2 class="mb-2">{{ column.name }}</h2>
        </template>
        <template #item="{ element }">
          <kanban-task :item="element" @task-updated="handleTaskUpdate"/>
        </template>
      </draggable>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import KanbanTask from '@/components/kanban/KanbanTask.vue';

export default {
  components: {
    Draggable,
    KanbanTask
  },
  emits: ['taskUpdated', 'moveTask'],
  props: {
    column: Object,
    attivita: Array
  },
  data() {
    return {
      tasks: this.attivita,
      collapsed: this.column?.collapsed || false,
    };
  },
  watch: {
    attivita(newTasks) {
      this.tasks = newTasks;
    }
  },
  methods: {
    onMove(event) {
      const fromState = this.column.state;
      const toState = event.to.parentNode.parentNode.dataset.state;
      const item = event.draggedContext.element;
      return this.checkMove(item, fromState, toState);
    },
    onAdd(event) {
      const fromState = this.column.state;
      const toState = event.to.parentNode.parentNode.dataset.state;
      const item = event.item.__draggable_context.element;
      console.log('onAdd', item, fromState, toState)
      this.$emit('moveTask', item, fromState, toState);
    },
    handleTaskUpdate(updatedTask) {
      this.$emit('taskUpdated', updatedTask)
    },
    checkMove(item, from, to) {
      if (to === undefined) {
        return false;
      }
      if (from === undefined) {
        return false;
      }
      if (item === undefined) {
        return false;
      }
      from = from.toString();
      to = to.toString();
      if (from === to) {
        return false;
      }
      const fromOppStates = ['in valutazione', 'in attesa di contatto', 'contattato'];
      const fromPrepStates = ['in preparazione', 'in negoziazione', 'presentata', 'accettata'];
      if (from === 'non idonea,non qualificata,rifiutata,scaduta') {
        if (item.type === 'opportunita' && fromOppStates.includes(to)) {
          return true;
        }
        if (item.type === 'offerta' && fromPrepStates.includes(to)) {
          return true;
        }
        return false;
      }
      if (from === 'qualificata') {
        return true;
      }
      if (fromOppStates.includes(from) && (fromOppStates.includes(to) || to === 'qualificata' || to === 'non idonea,non qualificata,rifiutata,scaduta')) {
        return true;
      }
      if (fromPrepStates.includes(from) && (fromPrepStates.includes(to) || to === 'non idonea,non qualificata,rifiutata,scaduta')) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
h2 {
  font-weight: 500;
  font-size: 0.9em;
  text-transform: uppercase;
  color: #999;
  padding: 5px;
  text-align: left;
}

.vertical {
  writing-mode: vertical-rl;
  text-orientation: mixed;
  height: 100%;
}

.horizontal {
  writing-mode: horizontal-tb;
  text-orientation: mixed;
  height: 100%;
}

.kanban-column {
  width: 260px;
  /* Fixed width */
  padding: 7px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.kanban-column.collapsed {
  width: 55px;
}

/* fill all container height */
.drag-area {
  height: 100%;
  overflow-y: auto;

  min-height: 50px;
  border: none;
}

.square-icon-button {
  border-radius: 3px !important;
  /* Remove border rounding */
  border: 1px solid #ccc;
}
</style>
