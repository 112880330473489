<template>
  <v-container class="pt-0">
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Cerca"
      single-line
      hide-details class="mt-3" density="compact"></v-text-field>
    <v-tabs v-model="tab" align-tabs="center" density="compact">
      <v-tab class="small-tab" value="all">Tutte</v-tab>
      <v-tab class="small-tab" value="todo">Da Fare</v-tab>
      <v-tab class="small-tab" value="telefonata">Chiamate</v-tab>
      <v-tab class="small-tab" value="note">Note</v-tab>
      <v-tab class="small-tab" value="email">Email</v-tab>
      <v-tab class="small-tab" value="appuntamenti">Appuntamenti</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <v-window v-model="tab">
      <v-window-item value="all">
        <v-container>
          <attivita-all></attivita-all>
        </v-container>
      </v-window-item>
      <v-window-item value="todo">
        <v-container>
          <attivita-all type="todo"></attivita-all>
        </v-container>
      </v-window-item>
      <v-window-item value="telefonata">
        <v-container>
          <attivita-all type="telefono"></attivita-all>
        </v-container>
      </v-window-item>
      <v-window-item value="note">
        <v-container>
          <attivita-all type="nota"></attivita-all>
        </v-container>
      </v-window-item>
      <v-window-item value="email">
        <v-container>
          <attivita-all type="email"></attivita-all>
        </v-container>
      </v-window-item>
      <v-window-item value="appuntamenti">
        <v-container>
          <attivita-all type="appuntamento"></attivita-all>
        </v-container>
      </v-window-item>
    </v-window>
  </v-container>
</template>

<script>
import AttivitaAll from './AttivitaAll.vue';

export default {
  name: 'AttivitaPreview',
  components: {
    AttivitaAll
  },
  data() {
    return {
      tab: 'todo',
      search: '',
    };
  },
  methods: {
  },
};
</script>

<style scoped>
.small-tab {
  text-transform: none !important;
}
</style>