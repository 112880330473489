<template>
    <div class="home">
      <v-img src="../128w/logo.png" width="128" height="128" aspect-ratio="1" style="max-height: 200px;"></v-img>
      <h1>Benvenuto in Luna CRM</h1>
      <v-btn prepend-icon="mdi-google" color="blue" density="default" elevated="4" @click="login">Login con Google</v-btn>
    </div>
  </template>

  <script>
  import AuthService from '../services/AuthService';

  export default {
    name: 'HomeView',
    methods: {
      login() {
        AuthService.login();
      }
    }
  };
  </script>

  <style scoped>
  .home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  button {
    padding: 10px 20px;
    margin-top: 20px;
    font-size: 18px;
    cursor: pointer;
  }
  </style>
