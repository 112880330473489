<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" width="70%" persistent>
      <v-card>
        <v-card-title>
          <v-btn v-if="!isDeleted" :icon="locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline'" class="float-right"
            @click="locked = !locked" :color="locked ? 'red' : 'green'" variant="text" density="compact"></v-btn>
          <v-chip v-else color="red" class="mr-2 float-right" size="small">Cancellato</v-chip>
          <span class="headline"><v-icon>mdi-help-box-outline</v-icon> {{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="form" @submit.prevent="save">
            <v-row>
              <v-col cols="12" sm="8" md="6" lg="4">
                <v-text-field v-if="editedItem?.Lead?.ragioneSociale" ref="ragioneSociale" label="Lead" variant="underlined"
                  v-model="editedItem.Lead.ragioneSociale" density="compact" hide-details="auto"
                  readonly></v-text-field>
              </v-col>

              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataEmissione" type="date" label="Data" density="compact"
                  variant="underlined" :rules="[rules.required]" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataScadenza" type="date" label="Scadenza" density="compact"
                  variant="underlined" :rules="[rules.required]" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-text-field>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-select v-model="editedItem.stato" :rules="[rules.required]" :items="stati" item-title="name"
                  return-object density="compact" label="Stato Trattativa" variant="underlined" hide-details="auto"
                  :disabled="isDeleted" :readonly="locked"></v-select>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.valore" :rules="[rules.required]" prefix="€" type="number"
                  label="Valore" density="compact" variant="underlined" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-select v-model="editedItem.probabilita" :items="['bassa', 'media', 'alta']" :rules="[rules.required]"
                  density="compact" label="Probabilità" variant="underlined" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-select>
              </v-col>
              <v-col cols="12" md="4" lg="3">
                <v-select v-model="editedItem.Contatto" variant="underlined" density="compact" :items="contacts"
                  item-title="nome" label="Contatto" return-object hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-combobox v-model="editedItem.origine"
                  :items="['passaparola', 'sito', 'spontaneo', 'DEM', 'altro']" density="compact" label="Origine"
                  variant="underlined" hide-details="auto" :disabled="isDeleted" :readonly="locked"></v-combobox>
              </v-col>
              <v-col cols="12" lg="9">
                <v-text-field v-model="editedItem.oggetto" :rules="[rules.required]" variant="underlined"
                  label="Oggetto" density="compact" hide-details="auto" :disabled="isDeleted"
                  :readonly="locked"></v-text-field>
                <!--v-textarea v-model="editedItem.oggetto" label="Oggetto" density="compact"></!--v-textarea-->
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pl-5 pr-5 pb-3">
          <v-btn color="red" text @click="deleteItem" v-if="isEditMode && !isDeleted"
            :disabled="locked || isSubmitted">Elimina</v-btn>
          <v-btn color="green" text @click="createNewOfferta" :disabled="locked || isSubmitted"
            v-if="isEditMode && editedItem.stato == 'qualificata' && !isDeleted">Chiudi
            e crea Offerta</v-btn>
          <v-spacer></v-spacer>
          {{ isSubmitted ? 'Salvataggio in corso...' : '' }}
          <v-btn color="blue darken-1" text @click="close" :disabled="isSubmitted">Annulla</v-btn>
          <v-btn color="primary" variant="elevated" @click="save" :disabled="!form || isDeleted || locked || isSubmitted">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import useLeadsStore from '@/stores/leads';
import { mapState } from 'pinia';
import TrattativeService from '@/services/TrattativeService';
import { cloneDeep } from 'lodash';
import DialogManagerService from '@/services/DialogManagerService';

export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'save', 'upgradeTrattativa', 'delete'],
  data() {
    return {
      showDialog: this.modelValue,
      form: false,
      rules: {
        required: value => !!value || 'Il campo non può essere vuoto',
        number: value => value >= 0 || 'Il numero deve essere >= 0',
      },
      editedItem: cloneDeep(this.item),
      locked: this.item?.id ? true : false,
      stati: ['in valutazione', 'in attesa di contatto', 'contattato', 'qualificata', 'non idonea', 'non qualificata'],
      isSubmitted: false,
    };
  },
  watch: {
    item(newValue) {
      console.log('opportunita changed', newValue);
      this.editedItem = cloneDeep(newValue);
      if (!this.editedItem.stato) {
        this.editedItem.stato = this.stati[0];
      }
    },
    modelValue(newValue) {
      this.showDialog = newValue;
    },
  },
  methods: {
    updateModelValue(value) {
      this.$emit('update:modelValue', value);
    },
    close() {
      this.updateModelValue(false);
    },
    async save() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      await TrattativeService.saveTrattativa(this.editedItem, {
        currentLead: this.currentLead,
        postSaveFunctions: [],
      });
      this.isSubmitted = false;
      this.$emit('save', this.editedItem);
    },
    async deleteItem() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      if (confirm('Sei sicuro di voler eliminare questa opportunità?')) {
        await TrattativeService.deleteTrattativa(this.editedItem, {
          currentLead: this.currentLead,
          postSaveFunctions: [],
        });
        this.isSubmitted = false;
        this.$emit('delete', this.editedItem);
        this.close();
      } else {
        this.isSubmitted = false;
      }
    },
    async createNewOfferta() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      await TrattativeService.upgradeTrattativa(this.editedItem, {
        currentLead: this.currentLead,
        postSaveFunctions: [(leadId, doReload, newItem) => {
          console.log('---------------------openDialog', newItem.type, newItem);
          DialogManagerService.openDialog('offerta', newItem, []);
        }],
      });
      this.isSubmitted = false;
      this.$emit('upgradeTrattativa', this.editedItem);
      this.close();
    },
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'contacts']),
    isDeleted() {
      // :disabled="isDeleted"
      return this.item?.active === 0;
    },
    isEditMode() {
      return this.item?.id !== undefined;
    },
    formTitle() {
      return this.isEditMode ? 'Modifica opportunità' : 'Aggiungi opportunità';
    },
  },
};
</script>