import { defineStore } from 'pinia'
import axios from 'axios'
import AuthService from '@/services/AuthService';

const useCollaboratoriStore = defineStore('collaboratori', {
  state: () => ({
    collaboratori: [],
    valid: false
  }),
  getters: {
  },
  actions: {
    async fetchCollaboratori() {
      if (this.valid && this.collaboratori.length > 0) return this.collaboratori; // Already fetched
      AuthService.refreshToken();
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/collaboratori');
        console.log("collaboratori", response.data.items);
        this.valid = true;
        return this.collaboratori = response.data.items;
      } catch (error) {
        console.error('Failed to fetch collaboratori:', error);
        throw error; // Handle error appropriately in your application
      }
    },
    invalidate() {
      this.valid = false;
    }
  },
})

export default useCollaboratoriStore;