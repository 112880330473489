<template>
  <v-sheet border rounded :height="100" class="pa-3">
    <div class="text-button text-center">
      <v-icon v-if="icon.length">{{ icon }}</v-icon>
      {{ title }}
    </div>
    <div v-if="!link || link.length === 0" class="text-h5 font-weight-medium text-center">{{ value }}</div>
    <div v-else class="text-h5 font-weight-medium text-center">
      <router-link :to="`${link}`" class="well">{{ value }}</router-link>
    </div>
  </v-sheet>
</template>

<script>
export default {
  name: 'StatsWell',
  props: {
    title: {
      type: String,
      default: 'Statistiche'
    },
    value: {
      type: [Number, String],
      default: '0'
    },
    icon: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped>
.titoletto {
  font-weight: 500;
  text-transform: uppercase;
}
.statistica {
  padding-top: 20px;
  font-size: 2em;
  font-weight: 500;
}
a.well {
  text-decoration: none;
  color: blue;
}
a.well:hover {
  text-decoration: underline;
}
a.well:visited {
  color: blue;
}
.text-button {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>