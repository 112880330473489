import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AppView from '../views/AppView.vue';
import DipendentiComponent from '../components/DipendentiComponent.vue';
import RolesComponent from '../components/RolesComponent.vue';
import UsersComponent from '../components/UsersComponent.vue';
import LeadsComponent from '../components/LeadsComponent.vue';
import Trattativeomponent from '../components/TrattativeComponent.vue';
import AuthService from '@/services/AuthService';
import LeadPreviewComponent from '@/components/LeadPreview/LeadPreviewComponent.vue';
import AuthView from '@/views/AuthView.vue';
import DashboardView from '@/views/DashboardView.vue';
import KanbanView from '@/views/KanbanView.vue';

// Define routes as an array of objects
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/auth',
    name: 'AuthView',
    component: AuthView,
  },
  {
    path: '/app',
    name: 'AppView',
    component: AppView,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'dipendenti',
        component: DipendentiComponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'ruoli',
        component: RolesComponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'utenti',
        component: UsersComponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'leads',
        name: 'elencoLeads',
        component: LeadsComponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'trattative',
        name: 'Trattative',
        component: Trattativeomponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'dashboard',
        name: 'elencoLeadsdashboard',
        component: DashboardView,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'lead',
        path: 'leads/:id',
        component: LeadPreviewComponent,
        meta: {
          requiresAuth: true,
        },
      },
      {
        name: 'kanban',
        path: 'kanban',
        component: KanbanView,
        meta: {
          requiresAuth: true,
        },
      }
    ],
  },
  {
    path: '/logout',
    name: 'logout',
    beforeEnter: (to, from, next) => {
      localStorage.removeItem('user');
      AuthService.logout();
      next({ name: 'home' });
    }
  },
];

// Create the router instance and pass the `routes` option
const router = createRouter({
  history: createWebHistory(),
  routes, // short for `routes: routes`
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    const user = localStorage.getItem('user');
    console.log("beforeEach user", to, user);
    if (user) {
      next();
    } else {
      try {
        const user = AuthService.getUserDetails();
        if (!user) {
          console.error('User not found');
          next({ name: 'home' });
        }
        localStorage.setItem('user', JSON.stringify(user));
        console.log("calling next")
        next();
      } catch (error) {
        console.error('Error fetching user:', error);
        next({ name: 'home' });
      }
    }
  } else {
    next();
  }
});


export default router;
