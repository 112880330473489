<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" persistent>
      <v-card>
        <v-card-title>
          <v-btn v-if="!isDeleted" :icon="locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline'" class="float-right"
            @click="locked = !locked" :color="locked ? 'red' : 'green'" variant="text" density="compact"></v-btn>
          <v-chip v-else color="red" class="mr-2 float-right" size="small">Cancellato</v-chip>
          <div class="float-left">
            <v-icon icon="mdi-office-building-outline" start></v-icon>{{ isEditMode ? 'Modifica Lead' : 'Nuovo Lead' }}
          </div>
          <v-switch v-model="editedLead.blackList" class="float-left ml-5" label="Blacklist" density="compact"
            hide-details="auto" color="black" :disabled="isDeleted || loading" :readonly="locked"></v-switch>
          <v-switch v-model="editedLead.doNotContact" class="float-left ml-5" label="Non contattare" density="compact"
            hide-details="auto" color="red" :disabled="isDeleted || loading" :readonly="locked"></v-switch>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="form" @submit.prevent="submitLead">
            <v-row>
              <v-col cols="4" sm="3" md="2" lg="1">
                <v-text-field density="compact" ref="codice" label="Codice" hide-details="auto"
                  v-model="editedLead.codice" :disabled="isDeleted || !editedLead?.id"
                  :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="partitaIva" label="P.IVA"
                :rules="[() => rules.eitherOr(editedLead.partitaIva, editedLead.codiceFiscale)]" @blur="validateEitherOr"
                  v-model="editedLead.partitaIva" @click:append="SearchPIVA"
                  :append-icon="isDeleted || locked ? null : 'mdi-text-search'" :disabled="isDeleted || loading"
                  :readonly="locked" hide-details="auto"></v-text-field>
                <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
              </v-col>
              <v-col cols="8" md="6" lg="5">
                <v-text-field density="compact" ref="ragioneSociale" :rules="[rules.required]" label="Ragione Sociale"
                  v-model="editedLead.ragioneSociale" :disabled="isDeleted" :readonly="locked"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="codiceFiscale" label="Codice Fiscale"
                :rules="[() => rules.eitherOr(editedLead.codiceFiscale, editedLead.partitaIva)]" @blur="validateEitherOr"
                  v-model="editedLead.codiceFiscale" :disabled="isDeleted" :readonly="locked"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="2" lg="2">
                <v-select :items="tipologie" density="compact" label="Tipo" v-model="editedLead.classificazione"
                  :disabled="isDeleted" :readonly="locked" hide-details="auto"></v-select>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="CreatoDa" label="Creato Da" v-model="editedLead.CreatoDa.nome"
                  disabled hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-select :items="users" density="compact" item-title="nome" return-object label="Assegnato a"
                  v-model="editedLead.AssegnatoA" :disabled="isDeleted" :readonly="locked"
                  hide-details="auto"></v-select>
              </v-col>
              <v-col cols="4" sm="3" md="2" lg="1">
                <v-text-field density="compact" ref="codiceSdi" label="Codice SDI" v-model="editedLead.codiceSdi"
                  :disabled="isDeleted" :readonly="locked" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="8" md="6" lg="3">
                <v-text-field density="compact" ref="pec" label="PEC" v-model="editedLead.pec" hide-details="auto"
                  :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="pagamentoGiorni" label="Pagamento gg" hide-details="auto"
                  v-model="editedLead.pagamentoGiorni" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="telefono" label="Telefono" hide-details="auto"
                  v-model="editedLead.telefono" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-autocomplete :items="lists['settori']" density="compact" hide-details="auto" item-title="name"
                  return-object label="Settore" v-model="editedLead.Settore" :disabled="isDeleted"
                  :readonly="locked"><!--@update:model-value="settoreChanged"--></v-autocomplete>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="specializzazione" label="Qualifica" hide-details="auto"
                  v-model="editedLead.specializzazione" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="fatturato" label="Fatturato" hide-details="auto"
                  v-model="editedLead.fatturato" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="sitoWeb" label="Sito Web" hide-details="auto"
                  v-model="editedLead.sitoWeb" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="instagram" label="Instagram" hide-details="auto"
                  v-model="editedLead.instagram" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="twitter" label="Twitter" hide-details="auto"
                  v-model="editedLead.twitter" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="linkedin" label="LinkedIn" hide-details="auto"
                  v-model="editedLead.linkedin" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="2" lg="1">
                <v-text-field density="compact" ref="codiceAteco" label="Cod. ATECO" hide-details="auto"
                  v-model="editedLead.codiceAteco" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="8" md="6" lg="5">
                <v-text-field density="compact" ref="descrizioneAteco" label="Descrizione Ateco" hide-details="auto"
                  v-model="editedLead.descrizioneAteco" :disabled="isDeleted"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="dataInizioAttivita" label="Inizio Attività" hide-details="auto"
                  v-model="editedLead.dataInizioAttivita" :disabled="isDeleted"></v-text-field>
              </v-col>
              <v-col cols="8" md="6" lg="4">
                <v-text-field density="compact" ref="soci" label="Proprietà" v-model="editedLead.soci"
                  :disabled="isDeleted" :readonly="locked" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="numeroDipendenti" label="Dipendenti" hide-details="auto"
                  v-model="editedLead.numeroDipendenti" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" md="6" lg="4">
                <v-text-field density="compact" ref="indirizzo" label="Indirizzo"
                  v-model="editedLead.indirizzo" :disabled="isDeleted" :readonly="locked"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="localita" label="Località"
                  v-model="editedLead.localita" :disabled="isDeleted" :readonly="locked"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="2" lg="1">
                <v-text-field density="compact" ref="cap" label="CAP"
                  v-model="editedLead.cap" :disabled="isDeleted" :readonly="locked" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="4" sm="3" md="2" lg="1">
                <v-text-field density="compact" ref="provincia" label="Provincia" hide-details="auto"
                  v-model="editedLead.provincia" :disabled="isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="nazione" label="Nazione" v-model="editedLead.nazione"
                  :disabled="isDeleted" :readonly="locked" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-checkbox ref="indirizzoFatturazioneDiverso" label="Indirizzo di fatturazione diverso"
                  hide-details="auto" v-model="editedLead.indirizzoFatturazioneDiverso" :disabled="isDeleted"
                  :readonly="locked"></v-checkbox>
              </v-col>
              <v-col v-if="editedLead.indirizzoFatturazioneDiverso" cols="8" md="6" lg="4">
                <v-text-field density="compact" ref="indirizzoFatturazione" label="Indirizzo"
                  v-model="editedLead.indirizzoFatturazione" hide-details="auto"
                  :disabled="!editedLead.indirizzoFatturazioneDiverso || isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col v-if="editedLead.indirizzoFatturazioneDiverso" cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="localitaFatturazione" label="Località"
                  v-model="editedLead.localitaFatturazione" hide-details="auto"
                  :disabled="!editedLead.indirizzoFatturazioneDiverso || isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col v-if="editedLead.indirizzoFatturazioneDiverso" cols="4" sm="3" md="2" lg="1">
                <v-text-field density="compact" ref="capFatturazione" label="CAP" v-model="editedLead.capFatturazione"
                  :disabled="!editedLead.indirizzoFatturazioneDiverso || isDeleted" :readonly="locked"
                  hide-details="auto"></v-text-field>
              </v-col>
              <v-col v-if="editedLead.indirizzoFatturazioneDiverso" cols="4" sm="3" md="2" lg="1">
                <v-text-field density="compact" ref="provinciaFatturazione" label="Provincia"
                  v-model="editedLead.provinciaFatturazione" hide-details="auto"
                  :disabled="!editedLead.indirizzoFatturazioneDiverso || isDeleted" :readonly="locked"></v-text-field>
              </v-col>
              <v-col v-if="editedLead.indirizzoFatturazioneDiverso" cols="6" sm="4" md="3" lg="2">
                <v-text-field density="compact" ref="nazioneFatturazione" label="Nazione"
                  v-model="editedLead.nazioneFatturazione" hide-details="auto"
                  :disabled="!editedLead.indirizzoFatturazioneDiverso || isDeleted" :readonly="locked"></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          {{ isSubmitted ? 'Salvataggio in corso...' : '' }}
          <v-btn color="primary" @click="close" :disabled="isSubmitted">Chiudi</v-btn>
          <v-btn :disabled="!form || isDeleted || locked || isSubmitted" class="ml-5 mr-5 pl-3" variant="elevated" color="primary"
            @click="submitLead">{{
      isEditMode ? 'Aggiorna' : 'Nuovo' }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapState } from 'pinia';
import useListsStore from '@/stores/lists';
import useUsersStore from '@/stores/users';
import useLeadStore from '@/stores/leads';
import LeadsService from '@/services/LeadsService';
import { cloneDeep } from 'lodash';

export default {
  props: {
    item: {
      type: Object,
      default: () => null // Default to null for new lead creation
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'save'],
  data() {
    return {
      form: false,
      editedLead: cloneDeep(this.item),
      locked: this.item?.id ? true : false,
      rules: {
        required: value => !!value || 'Il campo non può essere vuoto',
        number: value => value >= 0 || 'Il numero deve essere >= 0',
        fiveDigits: value => (value && /^\d{5}$/.test(value)) || 'Il campo deve contenere 5 numeri',
        eitherOr: (value, otherValue) => !!value || !!otherValue || 'Una tra partita IVA o codice fiscale è necessaria',
      },
      tipologie: ['lead', 'suspect', 'prospect', 'customer', 'ex-customer', 'not-qualified', 'not-interested', 'ex-lead', 'other'],
      showDialog: this.modelValue,
      loading: false,
      isSubmitted: false,
    };
  },
  computed: {
    //...mapState(useSettoriStore, ['settori', 'fetchSettori']),
    ...mapState(useUsersStore, ['users', 'fetchUsers']),
    ...mapState(useListsStore, ['lists', 'fetchList']),
    ...mapState(useLeadStore, ['currentLead', 'updateLead', 'createLead']),
    isDeleted() {
      // :disabled="isDeleted"
      return this.item?.active === 0;
    },
    isEditMode() {
      return this.item?.id;
    },
  },
  async mounted() {
    this.$nextTick(() => {
      if (!this.editedLead.partitaIva && !this.editedLead.codiceFiscale) {
        this.form = false;
      }
    });
    if (this.users?.length === 0) {
      await this.fetchUsers();
    }
    if (!this.lists['settori'] || this.lists['settori']?.length === 0) {
      await this.fetchList('settori');
    }
  },
  watch: {
    item(newVal) {
      this.editedLead = cloneDeep(newVal);
    },
    modelValue(newVal) {
      this.showDialog = newVal;
    },
  },
  methods: {
    validateEitherOr() {
      // Function to check either or validation when either field changes
      this.$refs.form?.validate();
    },
    close() {
      this.updateModelValue(false); // Request to close the dialog
    },
    updateModelValue(value) {
      // Perform cleanup or any additional logic before the actual visibility change
      if (!value) {
        // Cleanup logic here, e.g., resetting form fields, stopping timers, etc.
        console.log("Performing cleanup before closing the dialog");
      }
      this.$emit('update:modelValue', value); // Emit an event to update the dialog's visibility
    },
    async submitLead() {
      if (this.form && !this.isSubmitted) {
        this.isSubmitted = true;
        if (this.editedLead?.AssegnatoA?.id) {
          this.editedLead.idAssegnatoA = this.editedLead.AssegnatoA.id;
        }
        console.log('submitting lead', this.editedLead);
        try {

          if (!this.editedLead.id) {
            // New lead
            const item = await this.createLead(this.editedLead);
            this.$emit('save', item);
            this.$router.push(`/app/leads/${item.id}`);
          } else {
            // Update lead
            await this.updateLead(this.currentLead.id, this.editedLead);
            this.$emit('save', this.editedLead);
          }
        } catch (error) {
          if (error.response?.status === 409) {
            alert('Errore: la partita iva è già presente');
          } else {
            alert('Errore durante il salvataggio');
          }
          console.error('Error saving lead:', error);
        } finally {
          this.isSubmitted = false;
        }
      }
    },
    async SearchPIVA() {
      if (this.loading) return;
      this.loading = true;
      if (this.editedLead.partitaIva?.length !== 11) {
        alert('La partita IVA deve essere lunga 11 caratteri');
        this.loading = false;
        return;
      }
      const data = await LeadsService.searchPIVA(this.editedLead.partitaIva);
      if (data) {
        // Merge the data from the PIVA search with the current lead
        this.editedLead = { ...this.editedLead, ...data };
      }
      console.log('searching PIVA', this.editedLead.partitaIva);
      this.loading = false;
    }
  }
};
</script>
