<template>
  <div class="mb-5">
    <collapsible-component @add="aggiungiContatto">
      <template v-slot:title>Contatti</template>
      <div v-if="contacts.length === 0" class="text-center">
        <p class="mb-3 italico">Nessun contatto</p>
      </div>
      <div v-else>
        <v-card v-for="contatto in contacts" :key="contatto.id" class="mb-3 custom-border" variant="outlined"
          @mouseenter="contatto.showNav = true" @mouseleave="contatto.showNav = false">
        <v-card-text>

          <div class="float-right"><v-chip v-if="contatto.ruolo" color="primary">{{ contatto.ruolo }}</v-chip></div>
          <div class="card-title clickable" @click="editContact(contatto)">{{ contatto.nome }}
          </div>
          <div v-if="contatto.titolo" class="mb-1">{{ contatto.titolo }}</div>
          <div><a target="_blank" :href="`mailto:${contatto.email}`">
              {{ contatto.email }}
            </a>
          </div>
          <a target="_blank" :href="`tel:${contatto.phone}`">
            {{ contatto.phone }}
          </a>
          <linkedin-component class="mb-1" v-if="contatto.linkedin" :linkedin="contatto.linkedin"></linkedin-component>
          <div>{{ contatto.note }}</div>
        </v-card-text>
          <actions-drawer :item="contatto" @actionClick="chiama" :showActions="contatto?.showNav"></actions-drawer>
        </v-card>
      </div>
    </collapsible-component>
  </div>
</template>
<script>
import CollapsibleComponent from '@/components/controls/CollapsibleComponent.vue';
import LinkedinComponent from '@/components/controls/LinkedinComponent.vue';
import { mapState } from 'pinia';
import useLeadsStore from '@/stores/leads';
import ActionsDrawer from '@/components/controls/ActionsDrawer.vue';
import useUsersStore from '@/stores/users';
import AuthService from '@/services/AuthService';

import DialogManagerService from '@/services/DialogManagerService';

export default {
  name: 'EntitiesContacts',
  components: {
    CollapsibleComponent, LinkedinComponent, ActionsDrawer,
  },
  data() {
    return {
      currentContact: null,
      contactDialog: false,
      currentAttivita: null,
    };
  },
  async mounted() {
    console.log('EntitiesContacts mounted');
    if (this.contacts.length === 0) {
      console.log("Carico contatti");
      await this.loadContatti(this.$route.params.id);
    }
  },
  computed: {
    ...mapState(useUsersStore, ['users']),
    ...mapState(useLeadsStore, ['currentLead', 'loadMoreLog', 'contacts', 'loadContatti']),
  },
  methods: {
    chiama(itemData) {
      const { tipo, item } = itemData;
      console.log('chiama', tipo, item);
      const user = AuthService.getUserDetails();
      this.currentAttivita = {
        tipo,
        idLead: this.currentLead.id,
        Lead: this.currentLead,
        idAssegnatoA: user?.id,
        AssegnatoA: this.users.find(u => u.id == user?.id),
        Contatto: item,
        dataEsecuzione: (new Date()).toISOString().substring(0, 16),
        modalita: 'outbound',
        esito: tipo === 'appuntamento' ? 'da-fare' : 'completata',
        descrizione: ''
      };
      DialogManagerService.openDialog('attivita', this.currentAttivita, [this.loadMoreLog]);
    },
    aggiungiContatto() {
      this.currentContact = {
        idLead: this.currentLead?.id,
        Lead: this.currentLead,
        name: '',
        surname: '',
        email: '',
        phone: '',
        linkedin: '',
        ruolo: 'commerciale',
        titolo: '',
        note: '',
      };
      DialogManagerService.openDialog('contatto', this.currentContact, [this.loadContatti, this.loadMoreLog]);
    },
    editContact(contatto) {
      this.currentContact = contatto;
      DialogManagerService.openDialog(
        'contatto', contatto, [this.loadContatti, this.loadMoreLog],
        {
          'delete': async () => {
            await this.loadContatti(this.currentLead.id);
            await this.loadMoreLog(this.currentLead.id, true);
          },
        }
      );
      console.log('editContact', contatto);
    },
  },
};
</script>

<style scoped>
.card-title {
  font-weight: 500;
  font-size: 1.15em;
}
a {
  color: rgb(0, 0, 238);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.clickable {
  cursor: pointer;
  color: black;
}

.clickable:hover {
  cursor: pointer;
  color: rgb(0, 0, 238);
}

.custom-border {
  border-color: lightgray;
}

.custom-border:hover {
  border-color: black;
}
</style>
