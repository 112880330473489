<template>
  <div style="margin-top:17px; margin-left: 24px">
    <v-row class="v-card-title">
      <v-icon icon="mdi-office-building-outline" start></v-icon>Lead
      <v-btn size="x-small" prepend-icon="mdi-plus" class="ml-5" color="primary"
        @click="openCreateLeadDialog">Nuovo Lead</v-btn>
      <v-spacer class="mt-1"></v-spacer>
      <v-select v-model="utenzaApp" :items="utenti" item-title="nome" item-value="id" label="Filtra per utente"
        density="compact" hide-details variant="underlined" class="pr-3 pt-0 pb-0 v-col-2"></v-select>
      <v-text-field v-model="search" density="compact" append-icon="mdi-magnify" label="Filtra"
        variant="underlined" hide-details></v-text-field>
    </v-row>
    <v-row class="pa-5">
      <v-data-table-server
        :headers="headers"
        :items="leads"
        :items-length="totalItems"
        :loading="loading"
        :search="cerca"
        v-model:items-per-page.sync="itemsPerPage"
        :page="pageLead"
        :hover="true"
        items-per-page-text="Lead per pagina"
        loading-text="Caricamento in corso..."
        no-data-text="Nessun Lead trovato"
        page-text="{0}-{1} di {2}"
        @update:options="loadItems">
        <template v-slot:[`item.codice`]="{ item }">
          <a href="#" @click.prevent="openEditDialog(item)">
            {{ item.codice ? item.codice : '---'}}
          </a>
        </template>
        <template v-slot:[`item.ragioneSociale`]="{ item }">
          <router-link :to="`leads/${item.id}`">
            {{ item.ragioneSociale }}
          </router-link>
        </template>
      </v-data-table-server>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import useLeadsStore from '@/stores/leads';
import useUsersStore from '@/stores/users';
import LeadsService from '@/services/LeadsService';
import DialogManagerService from '@/services/DialogManagerService';
import AuthService from '@/services/AuthService';

export default {
  name: 'LeadsComponent',
  data() {
    return {
      search: '',
      cerca: '',
      leads: [],
      totalItems: 0,
      loading: true,
      headers: [
        { title: 'ID', value: 'codice', sortable: true },
        { title: 'Ragione Sociale', value: 'ragioneSociale', sortable: true },
        { title: 'P.IVA', value: 'partitaIva', sortable: true },
        { title: 'Tipo', value: 'classificazione', sortable: true },
        { title: 'Settore', value: 'Settore.name', sortable: true },
        { title: 'Referente', value: 'AssegnatoA.nome', sortable: true },
        // ... other headers ...
      ],
      //currentLead: null,
      editDialog: false,
      utenti: [],
      lastSortBy: [],
    };
  },
  computed: {
    ...mapState(useLeadsStore, ['createLead']),
    ...mapWritableState(useLeadsStore, ['currentLead']),
    ...mapState(useUsersStore, ['users', 'fetchUsers', 'fixUtenze']),
    ...mapWritableState(useUsersStore, ['utenzaApp', 'itemsPerPage', 'pageLead']),
    filteredLeads() {
      return this.leads.filter(lead => {
        return Object.values(lead).some(value => {
          return value ? value.toString().toLowerCase().includes(this.search.toLowerCase()) : false
        });
      });
    },
  },
  watch: {
    search(newSearch, oldSearch) {
      if (newSearch !== oldSearch) {
        this.updateRoute();
      }
      this.cerca = String(Date.now());
    },
    itemsPerPage(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateRoute();
      }
    },
    pageLead(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateRoute();
      }
    },
    utenzaApp(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.updateRoute();
      }
      this.cerca = String(Date.now());
    },
    '$route'(to) {
      if (to.query.utenza && to.query.utenza !== this.utenzaApp) {
        console.log("updating utenzaApp", to.query.utenza)
        this.utenzaApp = parseInt(to.query.utenza);
      }
      if (to.query.page && to.query.page !== this.pageLead) {
        console.log("updating page", to.query.page)
        this.pageLead = parseInt(to.query.page);
      }
      if (to.query.itemsPerPage && to.query.itemsPerPage !== this.itemsPerPage) {
        console.log("updating page", to.query.page)
        this.itemsPerPage = parseInt(to.query.itemsPerPage);
      }
      if (to.query.search && to.query.search !== this.search) {
        console.log("updating page", to.query.page)
        this.search = to.query.search;
        this.cerca = String(Date.now());
      }
    },
  },
  async mounted() {
    this.utenti = [{ id: 0, nome: 'Tutti' }].concat(await this.fetchUsers());
    this.readRouteParameters();
    if (this.utenzaApp === null) {
      this.fixUtenze();
    }
    this.loading = false;
    this.loadItems({ page: this.pageLead, itemsPerPage: this.itemsPerPage, sortBy: [] });
  },
  methods: {
    openEditDialog(item) {
      this.currentLead = item;
      DialogManagerService.openDialog('lead', this.currentLead, [() => this.loadItems({ page: this.pageLead, itemsPerPage: this.itemsPerPage, sortBy:this.lastSortBy })]);
    },
    updateItemsPerPage(newVal) {
      this.itemsPerPage = newVal;
    },
    readRouteParameters() {
      const { utenza, page, itemsPerPage, search } = this.$route.query;
      if (utenza) {
        console.log("reading utenzaApp", utenza);
        this.utenzaApp = parseInt(utenza);
      }
      if (page) {
        console.log("reading page", page);
        this.pageLead = parseInt(page);
      }
      if (itemsPerPage) {
        console.log("reading itemsPerPage", itemsPerPage);
        this.itemsPerPage = parseInt(itemsPerPage);
      }
      if (search) {
        console.log("reading search", search);
        this.search = search;
      }
    },
    updateRoute() {
      console.log("updateRoute()");
      const query = {
        ...this.$route.query,
        page: this.pageLead, // Ensure you manage pageLead data property correctly
        itemsPerPage: this.itemsPerPage,
        search: this.search,
        utenza: this.utenzaApp
      };
      this.$router.push({ name: 'elencoLeads', query }).catch(err => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
    loadItems({ page, itemsPerPage, sortBy }) {
      if (this.loading) {
        return; // Prevent fetching if already loading
      }
      this.loading = true;
      if (page !== this.pageLead) {
        this.pageLead = page;
      }
      if (itemsPerPage !== this.itemsPerPage) {
        this.itemsPerPage = itemsPerPage;
      }
      if (sortBy !== this.lastSortBy) {
        this.lastSortBy = sortBy;
      }
      console.log("loadItems");
      LeadsService.fetchLeadsPaged(page, itemsPerPage, sortBy, this.search, this.utenzaApp)
        .then((data) => {
          console.log("Data", data)
          this.leads = data.items;
          this.totalItems = data.totalCount;
        })
        .catch((error) => {
          console.error('Error fetching leads:', error);
        }).finally(() => {
          this.loading = false;
        });
    },
    openCreateLeadDialog() {
      const user = AuthService.getUserDetails();
      this.currentLead = {
        codice: '',
        ragioneSociale: '',
        partitaIva: '',
        classificazione: 'lead',
        idCreatoDa: user.id,
        CreatoDa: this.users.find(u => u.id == user?.id),
        idAssegnatoA: user.id,
        AssegnatoA: this.users.find(u => u.id == user?.id),
        Settore: null,
      }
      ; // Null indicates creating a new lead
      DialogManagerService.openDialog('lead', this.currentLead, [() => this.loadItems({ page: this.pageLead, itemsPerPage: this.itemsPerPage, sortBy:this.lastSortBy })]);
    },
  },
};
</script>

<style scoped>
:deep(a), :deep(a:visited) {
  color: rgb(0,0,238); /* Or your desired color */
}
:deep(a:hover) {
  background-color: #f5f5fe;
  color: rgb(0,0,0); /* Or your desired color */
}
</style>