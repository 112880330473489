import { has } from 'lodash';
import { reactive, toRefs } from 'vue';

const state = reactive({
  dialogs: [],
});

const closeDialog = (index) => {
  state.dialogs.splice(index, 1);
};

export default {
  openDialog(dialogType, item, postSaveActions = [], extraListeners = {}) {
    state.dialogs.push({ dialogType, props: { item }, postSaveActions, extraListeners });
    console.log('openDialog', state.dialogs[state.dialogs.length - 1]);
  },
  closeDialog(index) {
    state.dialogs.splice(index, 1);
  },
  saveDialog(index, item) {
    // if item has ragioneSociale it's a lead so get id, otherwise get idLead
    const idLead = has(item, 'ragioneSociale') ? item.id : item?.idLead;
    state.dialogs[index].postSaveActions.forEach(async (action) => await action(idLead, true, item));
    closeDialog(index);
    // Run post save actions
  },
  getDialogs() {
    const res = toRefs(state).dialogs;
    console.log('getDialogs', res);
    return res;
  },
};
