<template>
  <div :style="{ 'display': (inline ? 'inline-block' : 'block') }">
    <v-hover v-slot="{ isHovering, props }">
      <a :href="twitterUrl" target="_blank">
        <span v-if="hideIcon">{{ username }}</span>
        <v-icon v-else class="mr-1" :class="{ 'on-hover': isHovering }" v-bind="props">mdi-twitter</v-icon>{{ username }}
      </a>
    </v-hover>
  </div>
</template>

<script>
export default {
  name: 'TwitterComponent',
  props: {
    twitter: String,
    hideIcon: { type: Boolean, default: false},
    hideUsername: { type: Boolean, default: false},
    inline: { type: Boolean, default: false},
  },
  data() {
    return {
      isHovering: false
    }
  },
  computed: {
    twitterUrl() {
      if (this.twitter.startsWith('http')) {
        return this.twitter;
      } else if (this.twitter.indexOf('/') !== -1) {
        return "https://" + this.twitter;
      } else {
        return `https://www.twitter.com/in/${this.twitter}`;
      }
    },
    username() {
      if (this.hideUsername) {
        return '';
      }
      return this.twitter.split('/').pop();
    }
  }
}
</script>

<style scoped>
a {
  color: black;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
.on-hover {
  transition: transform 0.3s ease;
  transform: scale(1.3); /* Increase the size by 20% */
}
</style>