/**
 * plugins/webfontloader.js
 *
 * webfontloader documentation: https://github.com/typekit/webfontloader
 */

export async function loadFonts () {
    const webFontLoader = await import(/* webpackChunkName: "webfontloader" */'webfontloader')

    webFontLoader.load({
      google: {
        families: ['Roboto:400,500,700&display=swap'],
        //families: ['Roboto:100,300,400,500,700,900&display=swap'],
        //families: ['Lora:400,400i,700,700i&display=swap'],
      },
    })
  }
