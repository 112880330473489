<template>
  <div>
    <div>
      <div>
        <v-chip class="float-right" :color="chipColor" size="small">{{ currentLead?.classificazione }}</v-chip>
        <p style="font-size: 1.25rem">{{ currentLead?.ragioneSociale }}</p>
        <a :href="address(currentLead?.sitoWeb)" target="_blank" v-if="currentLead?.sitoWeb">
            <v-btn append-icon="mdi-open-in-new" density="compact" variant="plain" class="pl-0 text-none">
              {{ domain(currentLead?.sitoWeb) }}
            </v-btn>
          </a>
          <linkedin-component :inline="true" :hide-username="true" v-if="currentLead?.linkedin" :linkedin="currentLead?.linkedin"></linkedin-component>
          <twitter-component :inline="true" :hide-username="true" v-if="currentLead?.twitter" :twitter="currentLead?.twitter"></twitter-component>
          <instagram-component :inline="true" :hide-username="true" v-if="currentLead?.instagram" :instagram="currentLead?.instagram"></instagram-component>
      </div>
      <v-row v-if="currentLead" class="mt-1 mb-0">
        <v-col cols="12" lg="7" class="pt-1"> <!-- Add align-self-start class -->
          <ReadOnlyText v-if="currentLead?.idAssegnatoA" label="Assegnato a" :text="currentLead?.AssegnatoA?.nome" :editable="false" />
        </v-col>
        <v-col cols="12" lg="5">
          <v-switch v-model="currentLead.blackList"  label="Blacklist" density="compact" class="custom-switch"
            hide-details="auto" color="black" @update:model-value="onEditField('blackList', $event)"></v-switch>
          <v-switch v-model="currentLead.doNotContact"  label="Non contattare" density="compact" class="custom-switch"
            hide-details="auto" color="red" @update:model-value="onEditField('doNotContact', $event)"></v-switch>
        </v-col>
      </v-row>
      <ClickableIcon icon="mdi-phone" text="Chiamata" @action="chiama('telefono')" />
      <ClickableIcon icon="mdi-note-outline" text="Nota" @action="chiama('nota')" />
      <ClickableIcon icon="mdi-email-outline" text="Email" @action="chiama('email')" />
      <ClickableIcon icon="mdi-calendar-clock" text="Evento" @action="chiama('appuntamento')" />
      <ClickableIcon icon="mdi-text-box-outline" text="Offerta" @action="openEditOffertaDialog" />
      <v-divider></v-divider>
      <div>
        <div @click="toggleLeadInfo" class="clickable-schevron">
          <v-icon>{{ isLeadInfoVisible ? 'mdi-chevron-down' : 'mdi-chevron-right' }}</v-icon> Informazioni azienda <br />
        </div>
        <div v-if="isLeadInfoVisible">
          <div v-if="currentLead?.partitaIva && currentLead?.codiceFiscale && currentLead?.partitaIva.trim() == currentLead?.codiceFiscale.trim()">
            <ReadOnlyText label="Partita Iva e Codice Fiscale" :text="currentLead?.partitaIva" :editable="false" />
          </div>
          <div v-else>
            <ReadOnlyText v-if="currentLead?.partitaIva" @save="onEditField('partitaIva', $event)" label="Partita Iva"
              :text="currentLead?.partitaIva" />
            <ReadOnlyText v-if="currentLead?.codiceFiscale" @save="onEditField('codiceFiscale', $event)" label="Codice Fiscale"
              :text="currentLead?.codiceFiscale" />
          </div>
          <ReadOnlyText v-if="currentLead?.Settore && currentLead?.Settore.name" :items="lists['settori']" item-text="name"
            @save="onEditSelect('Settore', $event)" label="Settore" :obj="currentLead?.Settore" />
          <ReadOnlyText v-if="currentLead?.specializzazione" @save="onEditField('specializzazione', $event)" label="Qualifica" :text="currentLead?.specializzazione" />
          <ReadOnlyText v-if="currentLead?.pec" @save="onEditField('pec', $event)" label="pec" :text="currentLead?.pec" />
          <ReadOnlyText v-if="currentLead?.fatturato" @save="onEditNumber('fatturato', $event)" label="Fatturato"
            :text="formatFatturato(currentLead?.fatturato)" />
          <ReadOnlyText v-if="currentLead?.numeroDipendenti" @save="onEditField('numeroDipendenti', $event)"
            label="Numero dipendenti" :text="currentLead?.numeroDipendenti.toString()" />
          <ReadOnlyText v-if="currentLead?.descrizioneAteco" :editable="false" label="Descrizione attività Ateco"
            :text="currentLead?.codiceAteco + ' ' + currentLead?.descrizioneAteco" />
          <ReadOnlyText v-if="currentLead?.soci" @save="onEditField('soci', $event)" label="Proprietà" :text="currentLead?.soci" />
        </div>
        <v-divider class="mt-3"></v-divider>
      </div>
      <div class="pt-3">
        <v-btn prepend-icon="mdi-pencil" density="compact" @click="modificaLead">Scheda</v-btn>
        <v-btn icon="mdi-delete" variant="text" title="Elimina" density="compact" class="float-right" @click="cancellaLead"></v-btn>
      </div>
    </div>
    <v-sheet v-if="showSaveBanner > 0" :border="false" class="ma-0 pa-0 fixed-bottom-sheet">
      <v-card elevation="0" class="ma-0">
        <v-card-text>
          <v-btn color="warning" @click="salvaModifiche">Salva</v-btn>
          <v-btn color="warning" variant="outlined" @click="annullaModifiche" class="ml-3 mr-3">Annulla</v-btn>
          Hai modificato <span style="font-weight:500">{{ Object.keys(changes).length }}</span> proprietà
        </v-card-text>
      </v-card>
    </v-sheet>
    <v-snackbar v-model="showMessage" :color="messageType" bottom taimeout="5000">
      {{ messageType == 'success' ? 'Salvataggio avvenuto con successo' : 'Errore durante il salvataggio' }}
      <v-btn outlined @click="chiudiMessaggio" class="ml-4">Chiudi</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import useLeadsStore from '@/stores/leads';
import useListsStore from '@/stores/lists';
import useUsersStore from '@/stores/users';
import { mapState, mapWritableState } from 'pinia';
import AuthService from '@/services/AuthService';
import ClickableIcon from '@/components/controls/ClickableIcon.vue';
import ReadOnlyText from '@/components/controls/ReadOnlyText.vue';
import DialogManagerService from '@/services/DialogManagerService';
import LinkedinComponent from '@/components/controls/LinkedinComponent.vue';
import TwitterComponent from '@/components/controls/TwitterComponent.vue';
import InstagramComponent from '@/components/controls/InstagramComponent.vue';

export default {
  name: 'LeadComponent',
  data: () => {
    return {
      originalLead: {},
      isLeadInfoVisible: true,
      currentOfferta: null,
      editOffertaDialog: false,
      showSaveBanner: 0,
      changes: {},
      showMessage: false,
      messageType: 'success',
      blackList: false,
      doNotContact: false,
    };
  },
  computed: {
    ...mapWritableState(useLeadsStore, ['currentLead', 'attivita', 'fetchLead', 'updateLead', 'loadMoreLog', 'deleteLead']),
    ...mapState(useListsStore, ['lists', 'fetchList']),
    ...mapState(useUsersStore, ['users', 'fetchUsers']),
    leadId() {
      return this.$route.params.id;
    },
    chipColor() {
      switch (this.currentLead?.classificazione) {
        case 'lead':
          return 'grey';
        case 'customer':
          return 'success';
        case 'suspect':
          return 'secondary';
        case 'prospect':
          return 'primary';
          case 'not-qualified':
          return 'red'
        case 'not-interested':
          return 'red'
        case 'ex-lead':
        case 'ex-customer':
          return 'warning'
        default:
          return 'grey';
      }
    }
  },
  async mounted() {
    this.originalLead = Object.assign({}, this.currentLead);
    this.changes = {};
    // await this.aggiornaLead(this.$route.params.id);
    console.log(this.currentLead);
    if (! this.lists['settori'] || this.lists['settori']?.length === 0) {
      await this.fetchList('settori');
    }
    this.fetchUsers();
  },
  components: { ClickableIcon, ReadOnlyText, LinkedinComponent, TwitterComponent, InstagramComponent },
  methods: {
    // just show the domain part of a url
    address(url) {
      if (!url) return '';
      if (url.indexOf('://') === -1) url = 'https://' + url;
      return url;
    },
    domain(url) {
      try {
        if (!url) return '';
        if (url.indexOf('://') === -1) url = 'https://' + url;
        return new URL(url).hostname;
      } catch (error) {
        return "url non valido";
      }
    },
    async aggiornaLead(id) {
      await this.fetchLead(id);
      this.originalLead = Object.assign({}, this.currentLead);
      this.changes = {};
    },
    modificaLead() {
      DialogManagerService.openDialog('lead', this.currentLead, [this.loadMoreLog, this.aggiornaLead]);
    },
    cancellaLead() {
      if (!confirm('Sei sicuro di voler eliminare ' + this.currentLead?.ragioneSociale + '?')) return;
      this.deleteLead(this.currentLead?.id).then(() => {
        this.$router.push({ name: 'elencoLeads' });
      });
    },
    annullaModifiche() {
      Object.keys(this.changes).forEach(key => {
        this.currentLead[key] = this.originalLead[key];
      });
      this.changes = {};
      this.showSaveBanner = 0;
    },
    async salvaModifiche() {
      const newlead = await this.updateLead(this.currentLead?.id, this.changes);
      this.showSaveBanner = 0;
      if (newlead) {
        this.messageType = 'success';
        await this.aggiornaLead(this.currentLead.id);
        await this.loadMoreLog(this.currentLead.id, true);
      } else {
        this.messageType = 'error';
      }
      this.showMessage = true;
    },
    chiudiMessaggio() {
      this.showMessage = false;
    },
    onEditSelect(fieldName, fieldValue) {
      console.log("onEditSelect", fieldName, fieldValue);
      if (fieldValue.id != this.originalLead[fieldName].id) {
        this.changes[fieldName] = fieldValue;
        this.currentLead[fieldName] = fieldValue;
      } else {
        if (fieldName in this.changes) {
          delete this.changes[fieldName];
        }
      }
      this.showSaveBanner = Object.keys(this.changes).length;
    },
    onEditField(fieldName, fieldValue) {
      console.log("editField", fieldName, fieldValue);
      if (fieldValue != this.originalLead[fieldName]) {
        this.changes[fieldName] = fieldValue;
        this.currentLead[fieldName] = fieldValue;
      } else {
        if (fieldName in this.changes) {
          delete this.changes[fieldName];
        }
      }
      this.showSaveBanner = Object.keys(this.changes).length;
    },
    onEditNumber(fieldName, fieldValue) {
      console.log("onEditNumber", fieldName, fieldValue);
      console.log("originalLead[fieldName]", this.originalLead[fieldName]);
      if (fieldValue != this.originalLead[fieldName]) {
        this.changes[fieldName] = fieldValue;
        this.currentLead[fieldName] = fieldValue;
      } else {
        if (fieldName in this.changes) {
          delete this.changes[fieldName];
        }
      }
      this.showSaveBanner = Object.keys(this.changes).length;
    },
    openEditOffertaDialog() {
      let date = new Date();
      const todaysDate = date.toISOString().substring(0, 10);
      date.setMonth(date.getMonth() + 1);
      const futureDate = date.toISOString().substring(0, 10);
      this.currentOfferta= {
        idLead: this.currentLead?.id,
        Lead: Object.assign({}, this.currentLead),
        dataEmissione: todaysDate,
        dataScadenza: futureDate,
        dataInizio: null,
        dataTermine: null,
        valore: '1000',
        probabilita: 'bassa',
        origine: 'spontaneo',
        Contatto: null,
        stato: 'in preparazione',
        oggetto: '',
        type: 'offerta',
      }
      DialogManagerService.openDialog('offerta', this.currentOfferta, [this.loadMoreLog]);
    },
    chiama(type) {
      const user = AuthService.getUserDetails();
      const currentAttivita = {
        tipo: type,
        idLead: this.currentLead.id,
        Lead: this.currentLead,
        idAssegnatoA: user?.id,
        AssegnatoA: this.users.find(u => u.id == user?.id),
        dataEsecuzione: (new Date()).toISOString().substring(0, 16),
        modalita: 'outbound',
        esito: type === 'appuntamento' ? 'da-fare' : 'completata',
        descrizione: ''
      };
      DialogManagerService.openDialog('attivita', currentAttivita, [this.loadMoreLog]);
      console.log('chiama', type);
    },
    toggleLeadInfo() {
      this.isLeadInfoVisible = !this.isLeadInfoVisible; // Toggle the state
    },
    formatFatturato(value) {
      return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    }
  },
}
</script>

<style scoped>
.clickable-schevron {
  cursor: pointer;
  color: darkgray;
  font-weight: 500;
  font-size: smaller;
}

.clickable-schevron:hover {
  cursor: pointer;
  color: rgb(0, 0, 238);
  font-weight: 500;
  font-size: smaller;
}

.fixed-bottom-sheet {
  position: fixed;
  bottom: 0;
  /*left: 0;
  right: 0;*/
  z-index: 100;
  /* Ensure it sits above other content */
}

/* Removes underline for read-only text fields */
.no-border .v-input__control .v-input__slot:before,
.no-border .v-input__control .v-input__slot:after {
  border: none;
}

::v-deep .custom-switch .v-label {
  font-size: 0.8em; /* Adjust label size */
}

::v-deep .custom-switch .v-selection-control__wrapper {
  transform: scale(0.8); /* Adjust switch size */
}
</style>