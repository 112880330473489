<template>
  <v-row align="center" v-for="attivita in activities.items" :key="attivita.id">
    <attivita-item :attivita="attivita" @click="editAttivita(attivita)"></attivita-item>
    <v-divider></v-divider>
  </v-row>
</template>

<script>
import LeadsService from '@/services/LeadsService';
import { mapState } from 'pinia';
import useLeadsStore from '@/stores/leads';
import DialogManagerService from '@/services/DialogManagerService';
import AttivitaService from '@/services/AttivitaService';
import AttivitaItem from './AttivitaItem.vue';

export default {
  name: 'AttivitaAll',
  components: {
    AttivitaItem
  },
  props: {
    type: {
      type: String,
      default: 'all',
    },
  },
  data() {
    return {
      activities: {
        items: [],
        totalCount: 0
      },
      valid: true,
    };
  },
  watch: {
    type() {
      console.log('type changed', this.type);
      this.reload();
    },
    attivitaChanged() {
      console.log('attivitaChanged', this.attivitaChanged);
      this.reload();
    },
  },
  async mounted() {
    console.log('AttivitaAll mounted');
    await this.reload();
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'attivitaChanged', 'loadMoreLog']),
  },
  methods: {
    async reload() {
      console.log('fetching activities', this.$route.params.id, this.type)
      this.activities = await LeadsService.fetchAttivitaLead(
        this.$route.params.id,
        this.type
      );
    },
    colorClass(attivita) {
      if (attivita.esito === 'da-fare' && new Date(attivita.dataEsecuzione) > new Date()) {
        return 'future-active';
      }
      return '';
    },
    editAttivita(attivita) {
      DialogManagerService.openDialog('attivita', attivita, [this.reload, this.loadMoreLog]);
      console.log('editAttivita', attivita);
    },
    daFare(attivita) {
      //const diff = new Date(attivita.dataEsecuzione) - new Date();
      return attivita.esito === 'da-fare';
    },
    formatData(data) {
      return new Intl.DateTimeFormat('it-IT', { dateStyle: 'short', timeStyle: 'short' }).format(new Date(data));
    },
    getData(data) {
      const date = new Date(data);
      const diff = date - new Date();
      if (diff > 0) {
        const days = Math.round(diff / (1000 * 60 * 60 * 24));
        let rtf = new Intl.RelativeTimeFormat('it-IT', { numeric: 'auto' });
        if (days < 7) {
          const giorno = Intl.DateTimeFormat('it-IT', { weekday: 'long' }).format(date);
          const ora = Intl.DateTimeFormat('it-IT', { hour: 'numeric', minute: 'numeric' }).format(date);
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24)), 'day') + ': ' + giorno + ', alle ' + ora;
        }
        if (days < 60)
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 7)), 'week');
        if (days < 365)
          return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 30)), 'month');
        return rtf.format(Math.round((date - new Date()) / (1000 * 60 * 60 * 24 * 365)), 'year');
      }
      let dft = new Intl.DateTimeFormat('it-IT', { dateStyle: 'short', timeStyle: 'short' });
      return dft.format(date);
    },
    getModalita(attivita) {
      if (attivita.tipo === 'appuntamento' || attivita.tipo === 'nota')
        return '';
      return ' ' + attivita.modalita + ' ';
    },
    getModalitaIcon(attivita) {
      return AttivitaService.getAttivitaIcon(attivita.tipo);
    },
    getTitle(attivita) {
      let title = '';
      let congiunzione = '';
      switch (attivita.tipo) {
        case 'email':
          title = 'Email';
          congiunzione = ' a ';
          break;
        case 'telefono':
          title = 'Telefonata';
          congiunzione = ' a ';
          break;
        case 'nota':
          title = 'Nota';
          congiunzione = ' su ';
          break;
        case 'appuntamento':
          title = 'Appuntamento';
          congiunzione = ' con ';
          break;
        case 'task':
          title = 'Task';
          congiunzione = ' su ';
          break;
      }
      title += this.getModalita(attivita);
      if (attivita.Contatto)
        return title + congiunzione + attivita.Contatto?.nome;
      return title + congiunzione + this.currentLead?.ragioneSociale;
    },
    getTypes() {
      if (this.type === 'all')
        return ['email', 'telefono', 'nota', 'appuntamento', 'task'];
      return [this.type];
    }
  },
};
</script>

<style scoped>
.custom-border {
  border: none;
  background-color: rgb(232 232 229);
  cursor: pointer;
}

.custom-border.da-fare {
  border: none;
  background-color: #ffffff;
}

.titolo {
  font-size: 1.2em;
}
.future-active {
  color: #00a200;
  font-weight: 500;
}
</style>