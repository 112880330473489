<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" width="70%" persistent>
      <v-card>
        <v-card-title>
          <v-btn v-if="!isDeleted" :icon="locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline'" class="float-right"
            @click="locked = !locked" :color="locked ? 'red' : 'green'" variant="text" density="compact"></v-btn>
          <v-chip v-else color="red" class="mr-2 float-right" size="small">Cancellato</v-chip>
          <span class="headline"><v-icon>mdi-text-box-check-outline</v-icon> {{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-form v-model="form" @submit.prevent="save">
            <v-row>
              <v-col cols="12" sm="8" md="6" lg="4">
                <v-text-field ref="ragioneSociale" label="Lead" variant="underlined"
                  v-model="editedItem.Lead.ragioneSociale" density="compact" hide-details="auto"
                  readonly></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataScadenza" type="date" label="Data Firma" density="compact" variant="underlined"
                  hide-details="auto" :readonly="locked" :disabled="isDeleted"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataInizio" type="date" label="Inizio" density="compact" variant="underlined"
                  hide-details="auto" :readonly="locked" :disabled="isDeleted"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.dataTermine" type="date" label="Termine" density="compact" variant="underlined"
                  hide-details="auto" :readonly="locked" :disabled="isDeleted"></v-text-field>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-text-field v-model="editedItem.valore" prefix="€" type="number" label="Valore" density="compact" variant="underlined"
                  hide-details="auto" :readonly="locked" :disabled="isDeleted"></v-text-field>
              </v-col>
              <v-col cols="12" sm="4" md="3" lg="3">
                <v-select v-model="editedItem.Contatto" density="compact" :items="contacts" item-title="nome" variant="underlined"
                  label="Contatto" return-object hide-details="auto" :readonly="locked"
                  :disabled="isDeleted"></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <v-combobox v-model="editedItem.origine" :items="['passaparola', 'sito', 'spontaneo', 'DEM', 'altro']" variant="underlined"
                  density="compact" label="Origine" hide-details="auto" :readonly="locked"
                  :disabled="isDeleted"></v-combobox>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="editedItem.oggetto" label="Oggetto" density="compact" :rules="[rules.required]" variant="underlined"
                  hide-details="auto" :readonly="locked" :disabled="isDeleted"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" v-if="editedItem.link">
                <div class="d-flex align-center">
                  <span class="mr-2">Offerta collegata:</span>
                  <v-chip small color="blue lighten-4" class="mr-2" @click="download()">
                    {{ editedItem.numero }}
                  </v-chip>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="auto" align-self="center">
                Documenti:
              </v-col>
              <v-col cols="auto">
                <v-file-input v-model="file" label="Scegli file" hide-details="auto" density="compact"
                  class="file-input" :disabled="isDeleted || locked || isSubmitted"></v-file-input>
                <v-progress-linear v-if="loading" indeterminate color="yellow-darken-2"></v-progress-linear>
              </v-col>
              <v-col cols="auto" align-self="center">
                <v-btn color="primary" text density="compact" @click="uploadAllegato"
                  :disabled="file === null || isDeleted || locked || isSubmitted">Carica</v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-chip v-for="allegato in editedItem.Allegatos" :key="allegato.id" small color="green lighten-4"
                  class="mr-2" @click="downloadAllegato(allegato)" :closable="!locked && !isDeleted && !isSubmitted"
                  @click:close="cancellaAllegato(allegato)">
                  {{ allegato.nome }}
                </v-chip>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="pr-5 pb-3">
          <v-btn color="red" text @click="deleteItem" v-if="isEditMode && !isDeleted" :disabled="locked || isSubmitted">Elimina</v-btn>
          <v-spacer></v-spacer>
          {{ isSubmitted ? 'Salvataggio in corso...' : '' }}
          <v-btn color="blue darken-1" text @click="close" :disabled="isSubmitted">Annulla</v-btn>
          <v-btn color="primary" variant="elevated" @click="save" :disabled="! form || locked || isDeleted || isSubmitted">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TrattativeService from '@/services/TrattativeService';
import useLeadsStore from '@/stores/leads';
import { mapState } from 'pinia';
import axios from 'axios';
import { cloneDeep } from 'lodash';

export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'save', 'delete'],
  data() {
    return {
      locked: this.item?.id ? true : false,
      loading: false,
      form: false,
      editedItem: cloneDeep(this.item),
      rules: {
        required: value => !!value || 'Il campo non può essere vuoto',
        number: value => value >= 0 || 'Il numero deve essere >= 0',
      },
      file: null,
      showDialog: this.modelValue,
      isSubmitted: false,
    };
  },
  watch: {
    item(newValue) {
      console.log('watch contratto', newValue)
      this.editedItem = cloneDeep(newValue);
    },
    modelValue(newValue) {
      this.showDialog = newValue;
    },
  },
  methods: {
    async deleteItem() {
      if (confirm('Sei sicuro di voler eliminare questo contratto?')) {
        await TrattativeService.deleteTrattativa(this.editedItem, {
          currentLead: this.currentLead,
          postSaveFunctions: [],
        });
        this.$emit('delete', this.editedItem);
        this.close();
      }
    },
    close() {
      this.updateModelValue(false); // Request to close the dialog
    },
    updateModelValue(value) {
      // Perform cleanup or any additional logic before the actual visibility change
      if (!value) {
        // Cleanup logic here, e.g., resetting form fields, stopping timers, etc.
        console.log("Performing cleanup before closing the dialog");
      }
      this.$emit('update:modelValue', value); // Emit an event to update the dialog's visibility
    },
    async save() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      await TrattativeService.saveTrattativa(this.editedItem, {
        currentLead: this.currentLead,
        postSaveFunctions: [],
      });
      this.isSubmitted = false;
      this.$emit('save', this.editedItem);
    },
    async cancellaAllegato(allegato) {
      if (!confirm('Sei sicuro di voler cancellare ' + allegato.nome + '?')) return;
      console.log('cancellaAllegato', allegato);
      const res = await TrattativeService.deleteAllegato(allegato.id);
      console.log('cancellaAllegato res', res);
      this.editedItem.Allegatos = this.editedItem.Allegatos.filter(a => a.id !== allegato.id);
    },
    async downloadAllegato(allegato) {
      console.log('downloadAllegato', allegato);
      const res = await TrattativeService.download(allegato.id);
      console.log('downloadAllegato res', res);
      const url = window.URL.createObjectURL(res);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', allegato.nome);
      document.body.appendChild(link);
      link.click();
    },
    download2() {
      const contratto = this.editedItem;
      axios.get(this.generateLink(contratto), {
        responseType: 'blob',
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', contratto.numero + '.docx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    download() {
      const offerta = this.editedItem;
      const link = document.createElement('a');
      link.href = this.generateGLink(offerta);
      link.setAttribute('target', '_blank');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },

    generateLink(contratto) {
      return process.env.VUE_APP_API_URL + '/api/trattative/offerta/' + contratto.idOfferta + '/documento';
    },
    async uploadAllegato() {
      console.log('uploadAllegato', this.file[0]);
      this.loading = true;
      const res = await TrattativeService.upload(this.file[0], "contratto", this.editedItem.id);
      console.log('uploadAllegato res', res);
      if (res.item) {
        this.editedItem.Allegatos.push(res.item);
        this.file = [];
      }
      this.loading = false;
    },
    /*async createNewContatto() {
      return {
        idLead: this.currentLead?.id,
        name: '',
        surname: '',
        email: '',
        phone: '',
        linkedin: '',
        ruolo: 'commerciale',
        titolo: '',
        note: '',
      };
    },*/
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'contacts']),
    isDeleted() {
      // :disabled="isDeleted"
      return this.item?.active === 0;
    },
    isEditMode() {
      return this.item !== null;
    },
    formTitle() {
      return 'Modifica contratto ' + this.editedItem.numero;
    },
  },
};
</script>

<style scoped>
.file-input {
  min-width: 200px;
}
</style>
