<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" fullscreen :scrim="false">
      <v-card>
        <v-card-title>
          <v-icon icon="mdi-file-document" start></v-icon>{{ isEditMode ? 'Modifica Offerta' : 'Nuova Offerta' }}
        </v-card-title>
        <v-card-text>
          <v-form v-model="form" @submit.prevent="submitOfferta">
            <v-row>
              <v-col cols="12" sm="6" md="2" lg="1">
                <v-text-field ref="numero" label="numero" v-model="editedOfferta.numero" variant="underlined" readonly density="compact" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="2">
                <v-text-field ref="dataOfferta" :rules="[rules.required]" type="date" label="Data Offerta" variant="underlined"
                  v-model="editedOfferta.dataOfferta" density="compact" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="2" lg="1">
                <v-text-field ref="pagamentoGiorni" type="number" label="Pagamento gg" variant="underlined"
                  v-model="editedOfferta.pagamentoGiorni" density="compact" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="8" md="6" lg="4">
                <v-text-field ref="ragioneSociale"  label="Ragione Sociale" variant="underlined"
                  v-model="editedOfferta.ragioneSociale" density="compact" hide-details="auto"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="3" lg="2">
                <v-select ref="referente" v-model="editedOfferta.referente" variant="underlined" density="compact" :items="contacts"
                item-title="nome" item-value="nome" label="Referente" hide-details="auto"></v-select>
              </v-col>
              <!--v-col cols="4">
                <v-select ref="firmatario" :items="firmatari" item-text="nome" item-value="id" variant="underlined"
                  label="Firmatario" v-model="editedOfferta.firmatario" density="compact" hide-details="auto"></v-select>
              </v-col-->
              <v-col cols="12" sm="6" md="3" lg="2">
                <v-select ref="templates" :items="templates" item-title="label" item-value="template"
                  label="Seleziona un modello" v-model="editedOfferta.modello" variant="underlined"
                  @update:model-value="updateFields" density="compact" hide-details="auto"></v-select>
              </v-col>
              <v-col :cols="field.type === 'array' ? 12 : 4" v-for="field in fields" :key="field.key">
                <template v-if="field.type === 'array'">
                  <v-row>
                    <v-col cols="2" v-for="subField in field.values" :key="subField.key">
                      <b>{{ subField.label }}</b>
                    </v-col>
                  </v-row>
                  <v-row v-for="(item, index) in formData[field.key]" :key="index" class="ma-0">
                    <v-col class="pa-0 ma-0" cols="2" v-for="subField in field.values" :key="subField.key">
                      <v-autocomplete v-if="subField.type === 'dipendente'" density="compact" :label="subField.label"
                        :id="index + ':' + subField.key" v-model="item[subField.key]"
                        :items="collaboratori" :item-title="cognomeNome"
                        @update:model-value="(newValue) => associaDipendente(newValue, item)"
                        item-value="codice" hide-details="auto"></v-autocomplete>
                      <v-text-field v-else density="compact" :label="subField.label"
                        :id="index + ':' + subField.key" v-model="item[subField.key]" type="text"
                        @update:model-value="(newValue) => aggiornaImporto(newValue, item, subField.key)" hide-details="auto"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-btn type="button" prepend-icon="mdi-shape-square-plus" @click="addActivity(field.key)"
                    class="mt-2 mb-4" density="compact">Aggiungi {{ field.label }}</v-btn>
                </template>
                <!--
                  { "label": "Collaboratore", "key": "collaboratore", "type": "dipendente" },
                  <template v-else-if="field.type === 'dipendente'">
                  <v-autocomplete :items="collaboratori" density="compact" :item-title="cognomeNome"
                    item-value="id" :label="field.label" :id="field.key" v-model="formData[field.key]" hide-details="auto"></v-autocomplete>
                </template>-->
                <template v-else>
                  <v-textarea v-if="field.type === 'text'" :label="field.label" :id="field.key" v-model="formData[field.key]"
                    style="max-width: 600px;" density="compact" hide-details="auto"></v-textarea>
                    <v-text-field v-else density="compact" :label="field.label" :id="field.key"
                    v-model="formData[field.key]" type="text" :disabled="field.formula ? true : false" hide-details="auto"></v-text-field>
                </template>
              </v-col>
              <!-- Add other fields as necessary -->
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn color="primary" @click="close">Chiudi</v-btn>
          <v-btn :disabled="!form" class="ml-5 mr-5"
            variant="elevated" color="primary" @click="submitOfferta">Genera Offerta</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
//import AuthService from '@/services/AuthService';
import { mapState } from 'pinia';
//import useCollaboratoriStore from '@/stores/collaboratori';
//import useFirmatariStore from '@/stores/firmatari';
import useTemplatesStore from '@/stores/templates';
import useLeadsStore from '@/stores/leads';
import cloneDeep from 'lodash/cloneDeep';

export default {
  props: {
    item: {
      type: Object,
      default: () => null // Default to null for new offerta creation
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const theItem = cloneDeep(this.item);
    return {
      showDialog: this.modelValue,
      form: false,
      editedOfferta: theItem,
      rules: {
        required: value => !!value || 'Il campo non può essere vuoto',
        number: value => value >= 0 || 'Il numero deve essere >= 0',
        fiveDigits: value => (value && /^\d{5}$/.test(value)) || 'Il campo deve contenere 5 numeri',
      },
      formData: {},
      fields: [],
      totals: {},
      watchers: [],
    };
  },
  emits: ['update:model-value', 'save'],
  computed: {
    //...mapState(useCollaboratoriStore, ['collaboratori', 'fetchCollaboratori']),
    ...mapState(useTemplatesStore, ['templates', 'fetchTemplates']),
    ...mapState(useLeadsStore, ['contacts']),
    isEditMode() {
      return this.item !== null && this.item.id;
    },
  },
  watch: {
    item(newVal) {
      console.log('OffertaDialog item changed', newVal);
      this.editedOfferta = cloneDeep(newVal);
      this.formData = {};
      //this.editedOfferta.modello = '';
      /*const selectedTemplateData = this.templates.find(t => t.filename === this.editedOfferta.modello);
      if (selectedTemplateData) {
        this.fields = selectedTemplateData.data;
        this.watchFormFields();
      }*/
    },
    fields(newFields, oldFields) {
      console.log('OffertaDialog fields changed', newFields, oldFields);
      if (newFields !== oldFields) {
        this.watchFormFields();
      }
    },
    modelValue(newValue) {
      console.log('OffertaDialog modelValue changed', newValue);
      this.showDialog = newValue;
    },
  },
  async mounted() {
    console.log('OffertaDialog mounted');
    //this.fetchFirmatari();
    this.fetchTemplates();
    //this.fetchCollaboratori();
  },
  methods: {
    aggiornaImporto(newValue, item, key) {
      if (key === 'quantita' || key === 'costo') {
        const quantity = parseFloat(item.quantita.toString());
        const cost = parseFloat(item.costo.toString());
        if (quantity > 0 && cost > 0) {
          item.importo = (quantity * cost).toString();
        }
      }
    },
    associaDipendente(item, field) {
      const dipendente = this.collaboratori.find(d => d.codice === item);
      if (dipendente) {
        if (dipendente.tariffa) {
          field.costo = dipendente.tariffa.toString();
          if (field.quantita) {
            const quantity = parseFloat(field.quantita.toString());
            if (quantity > 0) {
              field.importo = (quantity * dipendente.tariffa).toString();
            }
          }
        }
      } else {
        field.costo = '';
        field.importo = '';
        console.log('Dipendente non trovato', item, this.collaboratori);
      }
    },
    cognomeNome(item) {
      if (!item || !item.cognome || !item.nome) {
        return '';
      }
      return `${item.cognome} ${item.nome}: ${this.formatItalianFloat(item.tariffa)} €`;
    },
    initializeFormDataFromOfferta() {
      console.log("initializeFormDataFromOfferta");
      this.fields.forEach(field => {
        if (field.offertaMapping) {
          this.formData[field.key] = this.resolvePath(this.editedOfferta, field.offertaMapping) || this.parseDefault(field.default);
        } else if (field.type === 'array' && field.values) {
          // Handle array type fields
          const arrayValues = this.getArrayValuesFromOfferta(field.key, field.values);
          if (arrayValues !== null) {
            this.formData[field.key] = this.getArrayValuesFromOfferta(field.key, field.values);
          }
        } else {
          this.formData[field.key] = this.parseDefault(field.default);
        }
      });
    },
    resolvePath(obj, path) {
      return path.split('.').reduce((prev, curr) => {
        return prev ? prev[curr] : null;
      }, obj);
    },
    getArrayValuesFromOfferta(arrayKey, subFields) {
      // Ensure that the arrayKey exists in editedOfferta and is an array
      if (!this.editedOfferta[arrayKey] || !Array.isArray(this.editedOfferta[arrayKey])) {
        return null;
      }
      return this.editedOfferta[arrayKey].map(item => {
        let mappedItem = {};
        subFields.forEach(subField => {
          if (subField.offertaMapping) {
            // Resolve the path for each subfield
            const path = `${arrayKey}.${subField.offertaMapping}`;
            mappedItem[subField.key] = this.resolvePath(this.editedOfferta, path);
          } else {
            // If no mapping is defined, fall back to a direct key match
            mappedItem[subField.key] = item[subField.key] || this.parseDefault(subField.default);
          }
        });
        return mappedItem;
      });
    },
    updateFields() {
      console.log("updateFields");
      const selectedTemplateData = this.templates.find(t => t.template === this.editedOfferta.modello);
      if (selectedTemplateData) {
        this.fields = selectedTemplateData.data;
        this.initializeFormData(); // This replaces the previous formData reset
        this.watchFormFields();
      } else {
        this.fields = [];
        this.formData = {};
      }
    },
    initializeFormData() {
      console.log("initializeFormData");
      this.formData = {};
      this.fields.forEach(field => {
        if (field.type === 'array' && field.values) {
          this.formData[field.key] = [];
          // Initialize the array with a structure based on 'values' and include default values if specified
          this.formData[field.key].push(
            field.values.reduce((obj, subfield) => {
              obj[subfield.key] = this.parseDefault(subfield.default) || ''; // Use default value if provided
              return obj;
            }, {})
          );
        } else {
          this.formData[field.key] = this.parseDefault(field.default) || ''; // Use default value if provided
        }
      });
      this.initializeFormDataFromOfferta();
      console.log("formData", this.formData);
    },
    parseDefault(defaultValue) {
      if (defaultValue === null || defaultValue === undefined) {
        console.log("parseDefault", 'null or undefined');
        return '';
      }
      if (defaultValue == "{oggi}") {
        console.log("parseDefault", Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date()));
        return new Intl.DateTimeFormat('it-IT', { day: 'numeric', month: 'long', year: 'numeric' }).format(new Date());
      }
      console.log("parseDefault", defaultValue);
      return defaultValue;
    },
    parseItalianFloat(val) {
      if (val === null || val === undefined) {
        return 0;
      }
      // check if last 3 characters are equal to .[0-9][0-9]
      if (val.slice(-3).match(/\.\d{2}/)) {
        return parseFloat(val) || 0;
      }
      // Replace Italian thousand separator and convert comma to period for parsing
      const numberString = val.replace(/\./g, '').replace(/,/g, '.');
      return parseFloat(numberString) || 0;
    },
    formatItalianFloat(val) {
      const formatter = new Intl.NumberFormat('it-IT', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return formatter.format(val);
    },
    calculateArraySum(sumKey, formula) {
      // Assuming the formula is a simple SUM over an array field's specific subkey
      const match = formula.match(/ARRAYSUM\((.*?)\.(.*?)\)/);
      if (match) {
        const [, arrayKey, subKey] = match;
        this.totals[sumKey] = this.formData[arrayKey].reduce((sum, item) => {
          // Replace Italian thousand separator and convert comma to period for parsing
          return sum + this.parseItalianFloat(item[subKey]);
        }, 0);

        this.formData[sumKey] = this.formatItalianFloat(this.totals[sumKey]);
      }
    },
    clearWatchers() {
      console.log("clearWatchers");
      this.watchers.forEach((unwatch) => {
        if (typeof unwatch === 'function') {
          unwatch(); // Stop the watcher
        }
      });
      this.watchers = []; // Reset the watchers array
    },
    watchFormFields() {
      console.log("watchFormFields");
      // Clear existing watchers
      this.clearWatchers();
      this.fields.forEach(field => {
        if (field.formula) {
          // Store the stop handle for each watcher
          const unwatch = this.$watch(
            () => this.formData,
            () => {
              this.calculateArraySum(field.key, field.formula);
            },
            { deep: true }
          );
          this.watchers.push(unwatch); // Store the unwatch function to clear it later
        }
        if (field.offertaMapping) {
          // Store the stop handle for each watcher
          const unwatch = this.$watch(
            () => this.resolvePath(this.editedOfferta, field.offertaMapping),
            (newValue) => {
              this.formData[field.key] = newValue;
            }
          );
          this.watchers.push(unwatch); // Store the unwatch function to clear it later
        }
      });
    },
    close() {
      // clear the form
      this.updateModelValue(false); // Request to close the dialog
      this.showDialog = false;
    },
    updateModelValue(value) {
      if (typeof value !== 'boolean') {
        console.log("typeof value", typeof value);
        return;
      }
      // Perform cleanup or any additional logic before the actual visibility change
      if (!value) {
        // Cleanup logic here, e.g., resetting form fields, stopping timers, etc.
        this.editedOfferta = {};
        this.editedOfferta.modello = '';
        this.fields = [];
        this.watchFormFields();
      }
      console.log("updateModelValue", value);
      this.$emit('update:model-value', value); // Emit an event to update the dialog's visibility
    },
    formatValueByType(value, type) {
      switch (type) {
        case 'currency':
          return this.formatItalianFloat(this.parseItalianFloat(value)) + " €";
        default:
          return value;
      }
    },
    formatFormData() {
        let formattedData = {};

        this.fields.forEach(field => {
            if (field.type === 'array') {
                // Handle array fields
                formattedData[field.key] = this.formData[field.key].map(item => {
                    let formattedItem = {};
                    field.values.forEach(subField => {
                        formattedItem[subField.key] = this.formatValueByType(item[subField.key], subField.type);
                    });
                    return formattedItem;
                });
            } else {
                // Handle non-array fields directly
                formattedData[field.key] = this.formatValueByType(this.formData[field.key], field.type);
            }
        });
        return formattedData;
    },
    submitOfferta() {
      console.log("submitOfferta", this.form, this.editedOfferta, this.formData);

      this.$emit('save', { offerta: cloneDeep(this.editedOfferta), formData: this.formatFormData() });
    },
    removeAction(field, index) {
      this.formData[field.key].splice(index, 1);
    },
    addActivity(activityFieldKey) {
      // Find the field definition for the array type field
      const fieldDefinition = this.fields.find(field => field.key === activityFieldKey);

      if (!fieldDefinition || !fieldDefinition.values) {
        console.error(`Field with key '${activityFieldKey}' not found or has no values defined.`);
        return;
      }

      // Create a new activity based on the 'values' structure and use default values if they exist
      const newActivity = fieldDefinition.values.reduce((acc, subField) => {
        const hasDefault = Object.prototype.hasOwnProperty.call(subField, 'default');
        acc[subField.key] = hasDefault ? subField.default : '';
        return acc;
      }, {});

      this.formData[activityFieldKey].push(newActivity);
    },
  }
};
</script>
