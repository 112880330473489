import axios from 'axios';
import AuthService from '@/services/AuthService';

class TrattativeService{
  async get(id, tipo) {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/trattative/'+tipo+'/' + id);
      console.log("trattativa:", id, response.data);
      // if (tipo === 'opportunita') {
      //   response.data.dataScadenza = response.data.dataScadenza.substring(0, 10);
      // } else if (tipo === 'offerta') {
      //   response.data.dataScadenza = response.data.dataScadenza.substring(0, 10);
      //   response.data.dataOfferta = response.data.dataOfferta.substring(0, 10);
      // } else if (tipo === 'contratto') {
      //   response.data.dataScadenza = response.data.dataScadenza.substring(0, 10);
      //   response.data.dataInizio = response.data.dataInizio.substring(0, 10);
      // }
      // response.data
      return response.data;
    } catch (error) {
      console.error('Failed to fetch trattativa:', error);
      return null;
    }
  }
  async download(idAllegato, tipo, id) {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/trattative/'+tipo+'/'+id+'/allegati/'+idAllegato, {
        responseType: 'blob'
      });
      console.log("download(idAllegato)", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to download file:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async upload(file, tipo,  id) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/trattative/'+tipo+'/'+id+'/allegati', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("upload(file)", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to upload file:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async uploadOfferta(file, tipo,  id) {
    const formData = new FormData();
    formData.append('file', file);
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/trattative/'+tipo+'/'+id+'/carica-offerta', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log("upload(file)", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to upload file:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async upgrade(trattativa) {
    if (! trattativa.type || ! trattativa.id || trattativa.type === 'contratto') throw new Error('Invalid trattativa');
    try {
      AuthService.refreshToken();
      const response = await axios.put(process.env.VUE_APP_API_URL + '/api/trattative/'+trattativa.type+'/'+trattativa.id+'/upgrade');
      console.log("upgrade(trattativa)", response.data);
      return response.data.item;
    }
    catch (error) {
      console.error('Failed to upgrade trattativa:', error);
      throw error; // Handle error appropriately in your application
    }
  }

  async update(trattativa) {
    if (! trattativa.type || ! trattativa.id) throw new Error('Invalid trattativa');
    try {
      AuthService.refreshToken();
      const response = await axios.put(process.env.VUE_APP_API_URL + '/api/trattative/'+trattativa.type+'/'+trattativa.id, trattativa);
      console.log("update(trattativa)", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to save trattativa:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async create(trattativa) {
    if (! trattativa.type) throw new Error('Invalid trattativa');
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/trattative/'+trattativa.type, trattativa);
      console.log("create(trattativa)", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to create new trattativa:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async delete(trattativa) {
    if (! trattativa.type || ! trattativa.id) throw new Error('Invalid trattativa');
    try {
      AuthService.refreshToken();
      const response = await axios.delete(process.env.VUE_APP_API_URL + '/api/trattative/'+trattativa.type+'/'+trattativa.id);
      console.log("delete(trattativa)", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to delete trattativa:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async fetchTrattativePaged(tipo, page = 0, itemsPerPage = 10, sortBy, searchString, statoTrattativa, utenza = 0) {
    let params = '';
    if (sortBy.length) {
      params += `&sortBy=${sortBy[0].key}:${sortBy[0].order}`;
    }
    if (searchString && searchString.length >= 2) {
      params += `&search=${searchString}`;
    }
    if (statoTrattativa && statoTrattativa.indexOf('tutt') === -1) {
      params += `&stato=${statoTrattativa}`;
    }
    if (utenza) {
      params += `&utenza=${utenza}`;
    }
    console.log("fetchTrattativePaged() params", params)
    try {
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/trattative/'+tipo+'?page=' + page + '&pageSize=' + itemsPerPage + params);
      console.log("trattative:", response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch trattative:', error);
      return null;
    }
  }
  async fetchTrattativeInScadenza(page = 0, itemsPerPage = 10, sortBy, user, isKanban = false) {
    let sort = '';
    if (sortBy.length) {
      sort = `&sortBy=${sortBy[0].key}:${sortBy[0].order}`;
    }
    console.log("sort", sort)
    let utenza = '';
    if (user) {
      utenza += `&utenza=${user}`;
    }
    if (isKanban) {
      utenza += `&kanban=true`;
    }
    console.log("utenza", utenza)
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/trattative-aperte'+'?page=' + page + '&pageSize=' + itemsPerPage + sort + utenza);
      console.log("trattative aperte:", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to fetch trattative:', error);
      return null;
    }
  }
  async fetchTrattativeLead(id, page = 0, itemsPerPage = 10) {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/leads/' + id + '/trattative?page=' + page + '&pageSize=' + itemsPerPage);
      console.log("lead trattative:", id, response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch lead trattative:', error);
      return null;
    }
  }
  async saveTrattativa(trattativa, { currentLead, postSaveFunctions = [] }) {
    if (trattativa.id) {
      console.log('update', trattativa);
      await this.update(trattativa);
    } else {
      if (!trattativa.idLead && currentLead && currentLead.id) {
        trattativa.idLead = currentLead.id;
      }
      console.log('create', trattativa);
      await this.create(trattativa);
    }
    console.log('saveTrattativa', trattativa);

    // Execute each post-save function in the array
    for (const func of postSaveFunctions) {
      if (typeof func === 'function') {
        await func(currentLead.id, true);
      }
    }
  }
  async upgradeTrattativa(trattativa, { currentLead, postSaveFunctions = [] }) {
    if (! trattativa.id) {
      throw new Error('Invalid trattativa');
    }
    console.log('^^^^upgradeTrattativa', trattativa);
    const res = await this.upgrade(trattativa);

    // Execute each post-save function in the array
    for (const func of postSaveFunctions) {
      if (typeof func === 'function') {
        await func(currentLead.id, true, res.item);
      }
    }
    return res.item;
  }
  async deleteTrattativa(trattativa, { currentLead, postSaveFunctions = [] }) {
    if (trattativa.id) {
      console.log('delete', trattativa);
      await this.delete(trattativa);
    }
    console.log('deleteTrattativa', trattativa);

    // Execute each post-save function in the array
    for (const func of postSaveFunctions) {
      if (typeof func === 'function') {
        await func(currentLead.id, true);
      }
    }
  }
  async generaOfferta(trattativa, id) {
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/trattative/offerta/'+id+'/documento', trattativa);
      console.log("generatePdf(trattativa)", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to generate trattativa pdf:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async deleteDocumentoOfferta(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.delete(process.env.VUE_APP_API_URL + '/api/trattative/offerta/'+id+'/documento/');
      console.log("deleteDocumentoOfferta", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to delete trattativa pdf:', error);
      throw error; // Handle error appropriately in your application
    }
  }
}

export default new TrattativeService();