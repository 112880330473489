import axios from 'axios';
import AuthService from '@/services/AuthService';

class TenantsService {
  async fetchTenants() {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/tenants');
      console.log("tenants", response.data);
      return response.data.items;
    } catch (error) {
      console.error('Failed to fetch tenants:', error);
      throw error; // Handle error appropriately in your application
    }
  }
/*  async get(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/tenants/' + id);
      console.log("tenant:", id, response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch tenant:', error);
      return null;
    }
  }
  async update(tenant) {
    try {
      AuthService.refreshToken();
      const response = await axios.put(process.env.VUE_APP_API_URL + '/api/tenants/'+tenant.id, tenant);
      console.log("saveTenant", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to save tenant:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async delete(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.delete(process.env.VUE_APP_API_URL + '/api/tenants/'+id);
      console.log("deleteTenant", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to delete tenant:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async create(tenant) {
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/tenants', tenant);
      console.log("newTenant", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to create new tenant:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async saveTenant(tenant) {
    console.log('saveTenant');
    if (tenant.id) {
      console.log('update', tenant);
      await this.update(tenant);
    } else {
      console.log('create', tenant);
      await this.create(tenant);
    }
  }*/
}

export default new TenantsService();