<template>
  <div class="kanban-task">
    <a href="#" @click.prevent="openEditDialog(task)" class="float-right numero">
      {{ task.numero }}
    </a>
  <p>
    {{ task?.Lead?.ragioneSociale }}
  </p>
  <p class="title">{{ task?.oggetto }}</p>
  <p>Scadenza: {{ task?.dataScadenza }}</p>
  </div>
</template>

<script>
import DialogManagerService from '@/services/DialogManagerService';

export default {
  props: {
    item: Object,
  },
  emits: ['taskUpdated'],
  data() {
    return {
      task: this.item,
    };
  },
  watch: {
    item(newItem) {
      this.task = newItem;
    }
  },
  methods: {
    openEditDialog(task) {
      //this.$emit('editTask', task);
      DialogManagerService.openDialog(task.type, task, [this.updateItem]);
    },
    updateItem(idLead, unused, item) {
      this.$emit('taskUpdated', item);
    }
  }
};
</script>

<style>
.kanban-task {
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  background-color: white;
  margin-bottom: 10px;
  cursor: grab;
  font-size: 14px;
}
.kanban-task p.title {
  font-weight: 500;
}
.kanban-task a {
  text-decoration: none;
}
.kanban-task a:hover {
  text-decoration: underline;
}
.numero {
  font-size: 12px;
  font-weight: 600;
}
</style>
