<template>
  <div class="read-only-text" @mouseenter="hover = true" @mouseleave="hover = false">
    <div v-if="readOnly">
      <label class="label">{{ label }}</label>
      <div class="content" v-if="items.length">{{ itemText.length > 0 ? theObject[itemText] : theObject }}</div>
      <div class="content" v-else>{{ theText }}</div>
      <v-icon v-if="hover && editable" class="edit-icon" @click="edit">mdi-pencil</v-icon>
    </div>
    <div v-else-if="items.length" style="margin-top:5px;">
      <v-select ref="textInput" :label="label" variant="underlined"
        v-model="theObject" :items="items" :item-title="itemText" hide-details="auto"
         @update:model-value="modificaSelect" return-object></v-select>
      <v-icon class="save-icon" @click="save" color="success">mdi-check</v-icon>
    </div>
    <div v-else style="margin-top:5px;">
      <v-text-field ref="textInput" :label="label" v-model="theText" variant="underlined"
        hide-details="auto" @keydown="handleKeydown"></v-text-field>
      <v-icon class="save-icon" @click="save" color="success">mdi-check</v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReadOnlyText',
  props: {
    label: String,
    text: String,
    editable: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    itemText: {
      type: String,
      default: '',
    },
    obj: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      hover: false,
      readOnly: true,
      theText: this.text,
      wasNumber: false,
      theObject: this.obj,
    };
  },
  watch: {
    text(newValue) {
      console.log('text changed', newValue);
      this.theText = newValue;
    },
    obj(newValue) {
      console.log('object changed', newValue);
      this.theObject = newValue;
    },
  },
  methods: {
    isValidNumberFormat(input) {
      // Regular expression to match the number format
      const numberRegex = /^(0|[1-9]\d{0,2}(\.\d{3})*)(,\d{1,2})?\s*€$/;
      // Check if the input matches the regex
      return numberRegex.test(input);
    },
    parseNumber(input) {
      // Regular expression to match the number format
      const numberRegex = /^(0|[1-9]\d{0,2}(\.\d{3})*)(,\d{1,2})?\s*€$/;
      // Check if the input matches the regex
      if (numberRegex.test(input)) {
        // If it matches, remove non-numeric characters and parse as a number
        const numericString = input.replace(/[^\d,.]/g, '').replaceAll('.', '');
        return parseInt(numericString);
      } else {
        // If it doesn't match, return the original string
        return input;
      }
    },
    formatNumber(value) {
      return new Intl.NumberFormat('it-IT', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(value);
    },
    edit() {
      this.readOnly = false;
      if (this.isValidNumberFormat(this.theText)) {
        this.theText = this.parseNumber(this.theText);
        this.wasNumber = true;
      }
      this.$nextTick(() => {
        this.$refs.textInput.focus();
      });
    },
    save() {
      this.readOnly = true;
      if (this.wasNumber) {
        this.theText = this.formatNumber(this.theText);
        this.wasNumber = false;
      }
      // Emit an event or implement saving logic
      if (this.items.length > 0)
        this.$emit('save', this.theObject);
      else
        this.$emit('save', this.parseNumber(this.theText));
    },
    modificaSelect(newValue) {
      console.log('modificaSelect', newValue);
      this.save();
    },
    handleKeydown(event) {
      // Check if ESC key is pressed
      if (event.key === "Escape") {
        this.readOnly = true;
      }
      if (event.key === "Enter") {
        this.save();
      }
    },
  },
};
</script>

<style scoped>
.read-only-text {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: 8px;
  padding-right: 32px;
}

.label {
  color: rgba(0, 0, 0, 0.6);
  font-size: 0.75rem;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.content {
  padding: 0;
}

.edit-icon {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 50%;
  margin-top: 15px;
  transform: translateY(-50%);
  /* Center the icon vertically */

  visibility: hidden;
  /* Hide by default */
}

.save-icon {
  color: rgba(0, 0, 0, 0.54);
  position: absolute;
  right: 0;
  cursor: pointer;
  top: 50%;
  margin-top: 15px;
  transform: translateY(-50%);
  /* Center the icon vertically */
}

.read-only-text:hover .edit-icon {
  visibility: visible;
  /* Show on hover */
}

.read-only-text .edit-icon:hover {
  color: rgb(0, 0, 238);
}
</style>
