<template>
  <v-card>
    <v-card-title>
      <v-icon icon="mdi-account-key" start></v-icon>Ruoli
      <v-spacer></v-spacer>
    </v-card-title>
    <div class="pa-5">
      <v-data-table :headers="headers" :items="roles" :items-per-page="10"
      items-per-page-text="Ruoli per pagina"
      loading-text="Caricamento in corso..."
      no-data-text="Nessun ruolo trovato"
      page-text="{0}-{1} di {2}"></v-data-table>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios';
import AuthService from '@/services/AuthService';

export default {
  name: 'RolesComponent',
  data() {
    return {
      search: '',
      roles: [],
      headers: [
        { title: 'Nome', value: 'name', sortable: true },
        { title: 'Permessi', value: 'permissions', sortable: true },
        // ... other headers ...
      ],
    };
  },
  async mounted() {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/roles');
      this.roles = response.data.items;
    } catch (error) {
      console.error('Error fetching dipendenti:', error);
    }
  },
};
</script>
