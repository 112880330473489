<template>
  <v-app>
    <v-app-bar
      :color="tenantsStore.tenants[tenantsStore.currentTenant] && tenantsStore.tenants[tenantsStore.currentTenant].color || '#fbb03b'"
       height="48">
      <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>CRM Luna Labs
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <!--<v-btn variant="text" icon="mdi-magnify"></v-btn>

        <v-btn variant="text" icon="mdi-filter"></v-btn>

        <v-btn variant="text" icon="mdi-dots-vertical"></v-btn>-->
      <div v-if="user.tenants?.length > 1">
        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props">
              {{ tenantsStore.tenants[tenantsStore.currentTenant].name }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(tenant, index) in tenantsStore.tenants" :key="tenant.id" @click="selectTenant(index)">
              <v-list-item-title>{{ tenant.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-else>hello</div>
      <v-btn variant="text" @click="logout">
        Logout
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="showDrawer" location="left" :rail="drawer" expand-on-hover class="mt-1">
      <v-list-item v-for="item in filteredItems" :key="item.value" :prepend-icon="item.icon" @click="navigateTo(item.value)"
        :active="isItemActive(item.value)">
        {{ item.title }}
      </v-list-item>
    </v-navigation-drawer>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapStores } from 'pinia';
import useTenantsStore from '@/stores/tenants';
import useLeadsStore from '@/stores/leads';
import AuthService from '@/services/AuthService';

export default {
  name: 'AppView',
  computed: {
    ...mapStores(useTenantsStore, ['tenants', 'currentTenant', 'setTenants', 'changeTenant']),
    ...mapStores(useLeadsStore, ['leads']),
    filteredItems() {
      return this.items.filter(item => {
        if (item.permission) {
          return AuthService.hasPermission(item.permission);
        }
        return true;
      });
    },
  },
  data: () => ({
    user: { tenants: [] },
    drawer: true,
    showDrawer: true,
    group: null,
    items: [
      {
        title: 'Utenti',
        value: 'utenti',
        icon: 'mdi-account',
        permission: 'manage_users',
      },
      {
        title: 'Ruoli',
        value: 'ruoli',
        icon: 'mdi-account-key',
        permission: 'manage_users',
      },
      {
        title: 'Dashboard',
        value: 'dashboard',
        icon: 'mdi-finance',
        permission: 'manage_offers',
      },
      {
        title: 'Lead',
        value: 'leads',
        icon: 'mdi-office-building-outline',
        permission: 'manage_offers',
      },
      {
        title: 'Trattative',
        value: 'trattative',
        icon: 'mdi-text-box',
        permission: 'manage_offers',
      },
      {
        title: 'Kanban',
        value: 'kanban',
        icon: 'mdi-table-column',
        permission: 'manage_offers',
      }
      /*{
        title: 'Dipendenti',
        value: 'dipendenti',
        icon: 'mdi-account-multiple',
      },*/
    ],
  }),

  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    async selectTenant(index) {
      console.log("selectTenant", index);
      await this.tenantsStore.changeTenant(index);
      //this.$router.go()
      this.$nextTick(() => {
        this.$router.push({ path: `/app/leads` });
      });
    },
    logout() {
      this.$router.push({ path: `/logout` });
    },
    navigateTo(route) {
      this.$router.push({ path: `/app/${route}` });
    },
    isItemActive(itemValue) {
      // This assumes your route structure is like '/app/{itemValue}'
      return this.$route.path.includes(itemValue);
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  },
  mounted() {
    console.log("AppView mounted");
    //console.log(window.location.search);
    const jsuser = localStorage.getItem('user');
    console.log("jsuser", jsuser);
    this.user = JSON.parse(jsuser);
    console.log("Loaded user", this.user);
    this.tenantsStore.setTenants();
    this.tenantsStore.currentTenant = this.user.currentTenant;
    //this.$router.push('/app/dashboard');
  }
}
</script>