<template>
  <div style="margin-top:17px; margin-left: 24px">
    <v-row class="v-card-title">
      <v-icon icon="mdi-account-multiple" start></v-icon>Dipendenti
      <v-spacer class="mt-1"></v-spacer>
      <v-text-field v-model="search" append-icon="mdi-magnify" variant="underlined"
        density="compact" label="Filtra" hide-details></v-text-field>
    </v-row>
    <v-row class="pa-5">
      <v-data-table :headers="headers" :items="filteredDipendenti" :items-per-page="10" :search="search"></v-data-table>
    </v-row>
  </div>
</template>

<script>
import useCollaboratoriStore from '@/stores/collaboratori';
import { mapStores } from 'pinia';

export default {
  name: 'DipendentiComponent',
  data() {
    return {
      search: '',
      dipendenti: [],
      headers: [
        { title: 'ID', value: 'codice', sortable: true },
        { title: 'Cognome', value: 'cognome', sortable: true },
        { title: 'Nome', value: 'nome', sortable: true },
        { title: 'Costo Annuo', value: 'costoAnnuo', sortable: false, align: 'end' },
        { title: 'Tariffa', value: 'tariffa', sortable: false },
        { title: 'Fondo', value: 'fondo', sortable: true, align: 'center' },
        { title: 'Progetto', value: 'progetto', sortable: true, align: 'center' },
        { title: 'Assunzione', value: 'dataAssunzione', sortable: false },
        { title: 'Dimissioni', value: 'dataCessazione', sortable: false },
        { title: 'Tipo', value: 'topo', sortable: false },
      ],
    };
  },
  computed: {
    ...mapStores(useCollaboratoriStore, ['collaboratori', 'fetchCollaboratori']),
    filteredDipendenti() {
      return this.dipendenti.map(dipendente => {
        const formattedDataAssunzione = new Date(dipendente.dataAssunzione).toLocaleDateString('it-IT', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        });
        const formattedDataCessazione = dipendente.dataCessazione ? (new Date(dipendente.dataCessazione).toLocaleDateString('it-IT', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
        })) : '-';
        const formattedCostoAnnuo = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(dipendente.costoAnnuo);
        const formattedTariffa = new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(dipendente.tariffa);
        // Map the 'assunto' field to 'Sì' or 'No'
        return {
          ...dipendente,
          dataAssunzione: formattedDataAssunzione,
          dataCessazione: formattedDataCessazione,
          costoAnnuo: formattedCostoAnnuo,
          tariffa: formattedTariffa,
        };
      }).filter(dipendente => {
        return Object.values(dipendente).some(value => {
          return value ? value.toString().toLowerCase().includes(this.search.toLowerCase()) : false
        }
        );
      });
    },
  },
  async mounted() {
    try {
      this.dipendenti = await this.collaboratoriStore.fetchCollaboratori();
    } catch (error) {
      console.error('Error fetching dipendenti:', error);
    }
  },
};
</script>
