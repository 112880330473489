<template>
  <v-row justify="center">
    <v-dialog v-model="showDialog" @input="updateModelValue" width="70%" persistent>
      <v-card>
        <v-card-title>
          <v-btn v-if="!isDeleted" :icon="locked ? 'mdi-lock-outline' : 'mdi-lock-open-outline'" class="float-right"
            @click="locked = !locked" :color="locked ? 'red' : 'green'" variant="text" density="compact"></v-btn>
          <v-chip v-else color="red" class="mr-2 float-right" size="small">Cancellato</v-chip>
          <span class="headline"><v-icon>mdi-account</v-icon> {{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="4">
              <v-text-field ref="ragioneSociale" label="Lead" variant="underlined"
                v-model="editedItem.Lead.ragioneSociale" density="compact" hide-details="auto" readonly></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="editedItem.name" label="Nome" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="editedItem.surname" label="Cognome" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="4">
              <v-text-field v-model="editedItem.email" type="email" label="Email" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field v-model="editedItem.phone" label="Telefono" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3" lg="3">
              <v-text-field v-model="editedItem.linkedin" label="Linkedin" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select v-model="editedItem.ruolo" :items="['amministrativo', 'commerciale', 'operativo', 'altro']" variant="underlined"
                density="compact" label="Ruolo" :readonly="locked" hide-details="auto" :disabled="isDeleted"></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="3">
              <v-text-field v-model="editedItem.titolo" label="Titolo" density="compact" :readonly="locked" variant="underlined"
                hide-details="auto" :disabled="isDeleted"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-textarea v-model="editedItem.note" label="Note" density="compact" :readonly="locked"
                hide-details="auto" :disabled="isDeleted"></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pr-5 pb-3">
          <v-btn color="red" text @click="deleteItem" v-if="isEditMode && !isDeleted" :disabled="locked || isSubmitted">Elimina</v-btn>
          <v-spacer></v-spacer>
          {{ isSubmitted ? 'Salvataggio in corso...' : '' }}
          <v-btn color="blue darken-1" text @click="close" :disabled="isSubmitted">Annulla</v-btn>
          <v-btn color="primary" variant="elevated" @click="save" :disabled="locked || isDeleted">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import useLeadsStore from '@/stores/leads';
import { mapState } from 'pinia';
import ContactsService from '@/services/ContactsService';
import { cloneDeep } from 'lodash';


export default {
  props: {
    item: {
      type: Object,
      default: () => null,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'save'],
  data() {
    return {
      editedItem: cloneDeep(this.item),
      locked: this.item?.id ? true : false,
      showDialog: this.modelValue,
      isSubmitted: false,
    };
  },
  watch: {
    item(newValue) {
      this.editedItem = cloneDeep(newValue);
      this.locked = newValue?.id ? true : false;
      console.log('Contact changed', newValue);
    },
    modelValue(newValue) {
      this.showDialog = newValue;
    },
  },
  methods: {
    async deleteItem() {
      if (!this.editedItem?.id) return;
      if (confirm('Sei sicuro di voler eliminare questo contatto?')) {
        await ContactsService.delete(this.editedItem.id, {
          currentLead: this.currentLead,
          postSaveFunctions: [],
        });
        this.$emit('delete', this.editedItem);
        this.close();
      }
    },
    close() {
      this.updateModelValue(false); // Request to close the dialog
    },
    updateModelValue(value) {
      // Perform cleanup or any additional logic before the actual visibility change
      if (!value) {
        // Cleanup logic here, e.g., resetting form fields, stopping timers, etc.
        console.log("Performing cleanup before closing the dialog");
      }
      this.$emit('update:modelValue', value); // Emit an event to update the dialog's visibility
    },
    async save() {
      if (this.isSubmitted) return;
      this.isSubmitted = true;
      await ContactsService.saveContact(this.editedItem, {
        currentLead: this.currentLead,
        postSaveFunctions: [],
      });
      this.isSubmitted = false;
      this.$emit('save', this.editedItem);
    },
    /*async createNewContatto() {
      return {
        idLead: this.currentLead?.id,
        name: '',
        surname: '',
        email: '',
        phone: '',
        linkedin: '',
        ruolo: 'commerciale',
        titolo: '',
        note: '',
      };
    },*/
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead']),
    isDeleted() {
      // :disabled="isDeleted"
      return this.item?.active === 0;
    },
    isEditMode() {
      return this.item?.id !== undefined;
    },
    formTitle() {
      return this.isEditMode ? 'Modifica contatto' : 'Aggiungi contatto';
    },
  },
};
</script>

<style scoped>
.input-disabled input:disabled {
  color: black;
}
</style>
