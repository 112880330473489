<template>
  <div style="margin-top:17px; margin-left: 24px">
    <v-row class="v-card-title">
      <v-icon icon="mdi-text-box-outline" start></v-icon>Trattative
      <!--v-btn-- size="x-small" prepend-icon="mdi-plus" class="ml-5" color="primary"
        @click="openCreateLeadDialog">Nuovo</!--v-btn-->
      <v-spacer class="mt-1"></v-spacer>
      <v-select v-model="utenzaApp" :items="utenti" item-title="nome" item-value="id" label="Filtra per utente"
        density="compact" hide-details variant="underlined" class="pr-3 pt-0 pb-0 v-col-2"></v-select>
      <v-select v-model="modello" :items="['Opportunità', 'Offerte', 'Contratti']" label="Filtra per tipo"
        density="compact" variant="underlined" hide-details class="pr-3 pt-0 pb-0 v-col-2"></v-select>
      <v-select v-if="modello == 'Offerte' || modello == 'Opportunità' || modello == 'Contratti'" v-model="stato" :items="statoFilters[getType]" label="Filtra per stato"
        density="compact" variant="underlined" hide-details class="pr-3 pt-0 pb-0 v-col-2"></v-select>
      <v-text-field v-model="search" density="compact" append-icon="mdi-magnify" label="Cerca"
        variant="underlined" hide-details class="pr-3 pt-0 pb-0 v-col-3"></v-text-field>
    </v-row>
    <v-row class="pa-5">
      <v-data-table-server
        :headers="headers"
        :items="leads"
        :items-length="totalItems"
        :loading="loading"
        :search="cerca"
        v-model:items-per-page.sync="itemsPerPage"
        :hover="true"
        items-per-page-text="Trattative per pagina"
        loading-text="Caricamento in corso..."
        no-data-text="Nessuna trattativa trovata"
        page-text="{0}-{1} di {2}"
        @update:options="loadItems">
        <template v-slot:[`item.Lead.ragioneSociale`]="{ item }">
          <router-link :to="`/app/leads/${item.idLead}`">
            {{ item.Lead?.ragioneSociale }}
          </router-link>
        </template>
        <template v-slot:[`item.numero`]="{ item }">
          <a href="#" @click.prevent="openEditDialog(item)">
            {{ item.numero ? item.numero : '---'}}
          </a>
        </template>
      </v-data-table-server>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapWritableState } from 'pinia';
import useLeadsStore from '@/stores/leads';
import useUsersStore from '@/stores/users';
import DialogManagerService from '@/services/DialogManagerService';
import AuthService from '@/services/AuthService';
import TrattativeService from '@/services/TrattativeService';

export default {
  name: 'LeadsComponent',
  data() {
    return {
      search: '',
      cerca: '',
      modello: 'Offerte',
      leads: [],
      totalItems: 0,
      loading: true,
      headers: [
        { title: 'Numero', value: 'numero', sortable: true },
        { title: 'oggetto', value: 'oggetto', sortable: true },
        { title: 'Lead', value: 'Lead.ragioneSociale', sortable: true },
        { title: 'Scadenza', value: 'dataEmissione', sortable: true },
        { title: 'Stato', value: 'stato', sortable: true },
      ],
      currentLead: null,
      editDialog: false,
      statoFilters: {
        offerta: ['tutte', 'in preparazione', 'in negoziazione', 'presentata', 'accettata', 'rifiutata', 'scaduta',],
        contratto: ['tutti', "attivo", "scaduto", "chiuso", "sospeso"],
        opportunita: ['tutte', 'in valutazione', 'in attesa di contatto', 'contattato', 'qualificata', 'non idonea', 'non qualificata', 'scaduta'],
      },
      stato: 'tutte',
      utenti: [],
    };
  },
  computed: {
    ...mapState(useLeadsStore, ['createLead']),
    ...mapState(useUsersStore, ['users', 'fetchUsers', 'fixUtenze']),
    ...mapWritableState(useUsersStore, ['utenzaApp', 'itemsPerPage']),
    getType() {
      let modello = 'undefined';
      switch (this.modello) {
        case 'Offerte':
          modello = 'offerta';
          break;
        case 'Contratti':
          modello = 'contratto';
          break;
        case 'Opportunità':
          modello = 'opportunita';
          break;
      }
      return modello;
    }
  },
  watch: {
    search() {
      this.cerca = String(Date.now());
    },
    modello(newVal) {
      this.updateRoute({ modello: newVal });
      this.cerca = String(Date.now());
      this.stato = (this.modello == 'Contratti' ? 'tutti' : 'tutte');
    },
    stato(newVal) {
      this.updateRoute({ stato: newVal });
      this.cerca = String(Date.now());
    },
    utenzaApp(newVal) {
      this.updateRoute({ utenza: newVal });
      this.cerca = String(Date.now());
    },
    '$route'(to) {
      if (to.query.modello && to.query.modello !== this.modello) {
        this.modello = to.query.modello;
      }
      if (to.query.stato && to.query.stato !== this.stato) {
        this.stato = to.query.stato;
      }
      if (to.query.utenza && to.query.utenza !== this.utenzaApp) {
        this.utenzaApp = parseInt(to.query.utenza);
      }
    },
  },
  async mounted() {
    this.utenti = [{ id: 0, nome: 'Tutti' }].concat(await this.fetchUsers());
    this.readRouteParameters();
    if (this.utenzaApp === null) {
      this.fixUtenze();
    }
    this.loading = false;
    this.loadItems({ page: 1, itemsPerPage: this.itemsPerPage, sortBy: [] });
  },
  methods: {
    updateItemsPerPage(newVal) {
      this.itemsPerPage = newVal;
    },
    readRouteParameters() {
      const { modello, stato, utenza } = this.$route.query;
      if (modello) {
        console.log("reading modello", modello);
        this.modello = modello;
      }
      if (stato) {
        console.log("reading stato", stato);
        this.stato = stato;
      }
      if (utenza) {
        console.log("reading utenzaApp", utenza);
        this.utenzaApp = parseInt(utenza);
      }
    },
    updateRoute(params) {
      this.$router.push({ name: 'Trattative', query: { ...this.$route.query, ...params } }).catch(err => {
        if (err.name !== "NavigationDuplicated") {
          throw err;
        }
      });
    },
    loadItems({ page, itemsPerPage, sortBy }) {
      if (this.loading) {
        return; // Prevent fetching if already loading
      }
      this.loading = true;
      AuthService.refreshToken();
      TrattativeService.fetchTrattativePaged(this.getType, page, itemsPerPage, sortBy, this.search, this.stato, this.utenzaApp).then((data) => {
        console.log("Data", data)
        this.leads = data.items;
        this.totalItems = data.totalCount;
      }).catch((error) => {
        console.error('Error fetching leads:', error);
      }).finally(() => {
        this.loading = false;
      });
    },
    async openEditDialog(item) {
      item.type = this.getType;
      DialogManagerService.openDialog(this.getType, item, [
          (a, b, c) => { this.$router.push(`/app/leads/${c.idLead}`); }
        ],
        {
          'upgradeTrattativa': (item) => { this.$router.push(`/app/leads/${item.idLead}`); },
          'delete': () => { this.cerca = String(Date.now()); console.log('deleteTrattativa');},
        }
      );
    },
    async fetchLeads() {
      //this.leads = await this.leadsStore.fetchLeads();
    },
    openCreateLeadDialog() {
      const user = AuthService.getUserDetails();
      this.currentLead = {
        codice: '',
        ragioneSociale: '',
        partitaIva: '',
        classificazione: 'lead',
        idCreatoDa: user.id,
        CreatoDa: this.users.find(u => u.id == user?.id),
        idAssegnatoA: user.id,
        AssegnatoA: this.users.find(u => u.id == user?.id),
        Settore: null,
      }
      ; // Null indicates creating a new lead
      DialogManagerService.openDialog('lead', this.currentLead, []);
    },
  },
};
</script>

<style scoped>
:deep(a), :deep(a:visited) {
  color: rgb(0,0,238); /* Or your desired color */
}
:deep(a:hover) {
  background-color: #f5f5fe;
  color: rgb(0,0,0); /* Or your desired color */
}
</style>