<template>
  <div class="mb-5">
    <collapsible-component @add="aggiungiTrattativa"
      :dropdown-list="{ opportunita: 'Opportunità', offerta: 'Offerta' }">
      <template v-slot:title>Trattative</template>
      <div v-if="trattative.items.length === 0" class="text-center">
        <p class="mb-3 italico">Nessuna trattativa</p>
      </div>
      <div v-else>
        <v-card v-for="trattativa in trattative.items" @click="editTrattativa(trattativa)" :key="trattativa.id"
          variant="outlined" class="mb-3 custom-border" @mouseenter="trattativa.showNav = true"
          @mouseleave="trattativa.showNav = false">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <div class="contenuto">
                  <div class="mb-1 d-flex justify-space-between">
                    <v-chip size="small" :color="getTypeColor(trattativa.type)">
                      {{ getStatoLabel(trattativa.type) }}
                    </v-chip>
                    <span :style="{ color: getStatoColor(trattativa.stato) }">{{ trattativa.stato }}</span>
                  </div>
                  <div class="mb-2 mt-2 d-flex justify-space-between card-title">
                    <span>
                      {{ trattativa.oggetto }}
                    </span>
                    <span class="float-right">{{ trattativa.numero }}</span>
                  </div>
                  <div v-if="(trattativa.type === 'opportunita' && trattativa.stato !== 'qualificata') || (trattativa.type === 'offerta' && trattativa.stato !== 'accettata')">
                    <div class="d-flex justify-space-between mb-1">
                      <span>Scadenza:</span>
                      <span :class="coloreScadenza(trattativa.dataScadenza)">{{ trattativa.dataScadenza }}</span>
                    </div>
                  </div>
                  <div v-if="trattativa.type === 'contratto' || trattativa.type === 'offerta'">
                    <div class="d-flex justify-space-between mb-1">
                      <span>Inizio:</span>
                      <span>{{ trattativa.dataInizio }}</span>
                    </div>
                    <div class="d-flex justify-space-between mb-1">
                      <span>Termine:</span>
                      <span>{{ trattativa.dataTermine }}</span>
                    </div>
                  </div>
                  <div v-else class="d-flex justify-space-between mb-1">
                    <span>Prob.:</span>
                    <span>{{ trattativa.probabilita }}</span>
                  </div>
                  <div class="d-flex justify-space-between mb-1">
                    <span>Valore:</span>
                    <span>{{ formatNumber(trattativa.valore) }}</span>
                  </div>
                  <div class="text-medium-emphasis mt-2" v-if="trattativa.Contatto">
                    <v-icon>mdi-account-circle-outline</v-icon>
                    {{ trattativa.Contatto?.nome }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
          <actions-drawer :item="trattativa" @actionClick="chiama" actions="nota,appuntamento"
            :showActions="trattativa?.showNav"></actions-drawer>
        </v-card>
      </div>
    </collapsible-component>
  </div>
</template>
<script>
import CollapsibleComponent from '@/components/controls/CollapsibleComponent.vue';
import TrattativeService from '@/services/TrattativeService';
import { mapState } from 'pinia';
import useLeadsStore from '@/stores/leads';
import useListsStore from '@/stores/lists';
import useUsersStore from '@/stores/users';
import ActionsDrawer from '@/components/controls/ActionsDrawer.vue';
import DialogManagerService from '@/services/DialogManagerService';
import AuthService from '@/services/AuthService';
import AttivitaService from '@/services/AttivitaService';

export default {
  name: 'EntitiesTrattative',
  components: {
    CollapsibleComponent, ActionsDrawer
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'loadMoreLog']),
    ...mapState(useListsStore, ['lists']),
    ...mapState(useUsersStore, ['users']),
  },
  data() {
    return {
      trattative: {
        items: [],
        totalCount: 0
      },
    };
  },
  async mounted() {
    console.log('EntitiesTrattative mounted');
    await this.reload();
  },
  methods: {
    getTrattativaIcon(tipo) {
      return AttivitaService.getAttivitaIcon(tipo);
    },
    chiama(itemData) {
      const { tipo, item } = itemData;
      const user = AuthService.getUserDetails();
      const attivita = {
        tipo,
        idLead: this.currentLead.id,
        Lead: this.currentLead,
        idAssegnatoA: user?.id,
        AssegnatoA: this.users.find(u => u.id == user?.id),
        idOpportunita: item.type === 'opportunita' ? item.id : null,
        idOfferta: item.type === 'offerta' ? item.id : null,
        idContratto: item.type === 'contratto' ? item.id : null,
        dataEsecuzione: (new Date()).toISOString().substring(0, 16),
        modalita: 'outbound',
        esito: tipo === 'appuntamento' ? 'da-fare' : 'completata',
        descrizione: ''
      };
      DialogManagerService.openDialog('attivita', attivita, [this.loadMoreLog, this.reload]);
      console.log('chiamai', tipo, item);
    },
    getStatoLabel(type) {
      if (!type) return '';
      switch (type) {
        case 'contratto':
          return 'Contratto';
        case 'opportunita':
          return 'Opportunità';
        case 'offerta':
          return 'Offerta';
      }
    },
    getTypeColor(type) {
      if (!type) return 'black';
      if (type == 'contratto')
        return 'green';
      if (type == 'opportunita')
        return 'gray';
      if (type == 'offerta')
        return 'blue';
      return 'black';
    },
    getStatoColor(stato) {
      if (!stato) return 'black';
      if (stato.substr(0, 3) == 'non' || stato == 'rifiutata' || stato == 'scaduto')
        return 'red';
      if (stato == 'qualificata' || stato == 'accettata' || stato == 'attivo')
        return 'green';
      if (stato == 'sospeso')
        return 'orange';
      return 'black';
    },
    // format currency number
    formatNumber(value) {
      return new Intl.NumberFormat('it-IT', { style: 'currency', currency: 'EUR' }).format(value);
    },
    coloreScadenza(data) {
      return this.scadenzaFutura(data) ? 'futura' : 'scaduta';
    },
    scadenzaFutura(data) {
      return new Date(data) >= new Date();
    },
    trattativaScaduta(data) {
      return new Date(data) < new Date();
    },
    async reload() {
      console.log('fetching offerta')
      this.trattative = await TrattativeService.fetchTrattativeLead(
        this.$route.params.id,
      );
      console.log('trattative', this.trattative);
    },
    aggiungiTrattativa(quale) {
      console.log('aggiungiTrattativa', quale);
      switch (quale) {
        // case 'contratto':
        //   this.aggiungiContratto();
        //   break;
        case 'opportunita':
          this.aggiungiOpportunita();
          break;
        case 'offerta':
          this.aggiungiOfferta();
          break;
      }
    },
    aggiungiOfferta() {
      let date = new Date();
      const todaysDate = date.toISOString().substring(0, 10);
      date.setMonth(date.getMonth() + 1);
      const futureDate = date.toISOString().substring(0, 10);
      const item = {
        idLead: this.currentLead?.id,
        Lead: this.currentLead,
        dataEmissione: todaysDate,
        dataScadenza: futureDate,
        dataInizio: null,
        dataTermine: null,
        valore: '1000',
        probabilita: 'bassa',
        origine: 'spontaneo',
        Contatto: null,
        stato: 'in preparazione',
        oggetto: '',
        type: 'offerta',
      }
      DialogManagerService.openDialog(item.type, item, [this.loadMoreLog, this.reload]);
    },
    aggiungiOpportunita() {
      let date = new Date();
      const todaysDate = date.toISOString().substring(0, 10);
      date.setMonth(date.getMonth() + 1);
      const futureDate = date.toISOString().substring(0, 10);
      const item = {
        idLead: this.currentLead?.id,
        Lead: this.currentLead,
        dataEmissione: todaysDate,
        dataScadenza: futureDate,
        valore: '1000',
        probabilita: 'bassa',
        origine: 'spontaneo',
        Contatto: null,
        stato: 'in valutazione',
        oggetto: '',
        type: 'opportunita',
      };
      DialogManagerService.openDialog(item.type, item, [this.loadMoreLog, this.reload]);
    },
    /*    aggiungiContratto() {
          let date = new Date();
          const todaysDate = date.toISOString().substring(0, 10);
          date.setFullYear(date.getFullYear() + 1);
          const futureDate = date.toISOString().substring(0, 10);
          const item = {
            idLead: this.currentLead?.id,
            Lead: this.currentLead,
            dataInizio: todaysDate,
            dataScadenza: futureDate,
            name: '',
            surname: '',
            email: '',
            phone: '',
            linkedin: '',
            ruolo: 'commerciale',
            titolo: '',
            note: '',
            type: 'contratto',
          };
          DialogManagerService.openDialog(item.type, item, [this.loadMoreLog, this.reload]);
        },*/
    async upgradeTrattativa(trattativa) {
      if (this.opportunitaDialog || this.offertaDialog) {
        this.opportunitaDialog = false; this.offertaDialog = false;
        console.log("*****************************upgradeTrattativa", trattativa);
        TrattativeService.upgradeTrattativa(trattativa, {
          currentLead: this.currentLead,
          postSaveFunctions: [
            this.reload,
            this.loadMoreLog,
            (leadId, doReload, newItem) => {
              console.log('openDialog', trattativa.type, newItem);
              DialogManagerService.openDialog(trattativa.type == 'opportunita' ? 'offerta' : 'contratto', newItem, [
                this.reload,
                this.loadMoreLog,
              ]);
            }
          ],
        });
      }
    },
    deleteTrattativa(trattativa) {
      console.log('deleteTrattativa', trattativa);
      TrattativeService.deleteTrattativa(trattativa, {
        currentLead: this.currentLead,
        postSaveFunctions: [this.reload, this.loadMoreLog],
      });
      this.contrattoDialog = false; this.opportunitaDialog = false; this.offertaDialog = false;
    },
    editTrattativa(trattativa) {
      console.log('editTrattativa', trattativa);
      DialogManagerService.openDialog(
        trattativa.type,
        trattativa,
        [this.reload, this.loadMoreLog],
        trattativa.type === 'contratto' ?
          {
            'delete': async () => { await this.reload(); await this.loadMoreLog(this.currentLead.id, true); },
          } :
          {
            'upgradeTrattativa': async () => { await this.reload(); await this.loadMoreLog(this.currentLead.id, true); },
            'delete': async () => { await this.reload(); await this.loadMoreLog(this.currentLead.id, true); },
          },
      );
    },
    async saveTrattativa(trattativa) {
      console.log('saveTrattativa', trattativa);
      TrattativeService.saveTrattativa(trattativa, {
        currentLead: this.currentLead,
        postSaveFunctions: [this.reload, this.loadMoreLog],
      });
      this.contrattoDialog = this.opportunitaDialog = this.offertaDialog = false;
    },
  },
};
</script>

<style scoped>
.card-title {
  font-weight: 500;
  font-size: 1.15em;
}
.futura {
  color: green;
}

.scaduta {
  color: red;
}

.custom-border {
  border-color: lightgray !important;
}

.custom-border:hover {
  border-color: black !important;
}

.custom-border:hover>.v-card__overlay {
  opacity: 0 !important;
}

.clickable {
  cursor: pointer;
  color: black;
}
</style>
