import axios from 'axios';
import AuthService from '@/services/AuthService';

class UsersService {
  async get(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/users/' + id);
      console.log("user:", id, response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch user:', error);
      return null;
    }
  }
  async update(user) {
    try {
      AuthService.refreshToken();
      const response = await axios.put(process.env.VUE_APP_API_URL + '/api/users/'+user.id, user);
      console.log("saveUser", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to save user:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async delete(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.delete(process.env.VUE_APP_API_URL + '/api/users/'+id);
      console.log("deleteUser", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to delete user:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async create(user) {
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/users', user);
      console.log("newUser", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to create new user:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async saveUser(user) {
    console.log('saveUser');
    if (user.id) {
      console.log('update', user);
      await this.update(user);
    } else {
      console.log('create', user);
      await this.create(user);
    }
  }
}

export default new UsersService();