<template>
  <v-container class="pt-0">
    <v-tabs v-model="tab" align-tabs="center" density="compact">
      <v-tab class="small-tab" value="all">Tutte</v-tab>
      <v-tab class="small-tab" value="scadute">Scadute</v-tab>
      <v-tab class="small-tab" value="oggi">Oggi</v-tab>
      <v-tab class="small-tab" value="settimana">Questa Settimana</v-tab>
      <v-tab class="small-tab" value="mese">Questo Mese</v-tab>
    </v-tabs>
    <v-divider></v-divider>
    <div v-for="item in filteredItems" :key="item.id">
      <attivita-item v-if="item.itemType === 'attivita'" :attivita="item" @click="editAttivita(item)"></attivita-item>
      <trattativa-item v-else :trattativa="item" @click="editTrattativa(item)"></trattativa-item>
    </div>
  </v-container>
</template>

<script>
import LeadsService from '@/services/LeadsService';
import AttivitaItem from './AttivitaItem.vue';
import TrattativaItem from './TrattativaItem.vue';
import DialogManagerService from '@/services/DialogManagerService';
import { mapState } from 'pinia';
import useLeadsStore from '@/stores/leads';

export default {
  name: 'TrattativePreview',
  components: {
    AttivitaItem, TrattativaItem
  },
  data() {
    return {
      panoramica: {
        items: [],
        totalCount: 0
      },
      valid: true,
      tab: 'all',
    };
  },
  mounted() {
    console.log('TrattativePreview mounted');
    this.reload();
  },
  watch: {
    attivitaChanged() {
      console.log('attivitaChanged');
      this.reload();
    },
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'attivitaChanged', 'loadMoreLog']),
    filteredItems() {
      return this.panoramica.items.filter(item => {
        let dataEsecuzione;
        if (item.itemType === 'attivita')
          dataEsecuzione = item.dataEsecuzione;
        else
          dataEsecuzione = item.dataScadenza;
        if (this.tab === 'all') return true;
        if (this.tab === 'scadute') {
          return this.getDateString(new Date(dataEsecuzione)) < this.getDateString(new Date());
        }
        if (this.tab === 'oggi') {
          return this.getDateString(new Date(dataEsecuzione)) === this.getDateString(new Date());
        }
        if (this.tab === 'settimana') {
          const today = new Date();
          // Fix: Clone the today date to avoid modifying it
          const startOfWeekDate = new Date(today);
          const dayOfWeek = (today.getDay() + 6) % 7; // Adjust for Monday as the start of the week
          // Calculate the start of the week, then clone it to calculate the end of the week
          const startOfWeek = this.getDateString(new Date(startOfWeekDate.setDate(startOfWeekDate.getDate() - dayOfWeek)));
          // Clone the startOfWeekDate again to ensure it's not affected by the previous modification
          const endOfWeekDate = new Date(startOfWeekDate);
          const endOfWeek = this.getDateString(new Date(endOfWeekDate.setDate(endOfWeekDate.getDate() + 6)));
          const dateString = this.getDateString(new Date(dataEsecuzione));
          return dateString >= startOfWeek && dateString <= endOfWeek;
        }
        if (this.tab === 'mese') {
          const today = new Date();
          const startOfMonth = this.getDateString(new Date(today.getFullYear(), today.getMonth(), 1));
          const endOfMonth = this.getDateString(new Date(today.getFullYear(), today.getMonth() + 1, 0));
          const dateString = this.getDateString(new Date(dataEsecuzione));
          return dateString >= startOfMonth && dateString <= endOfMonth;
        }
      });
    },

  },
  methods: {
    getDateString(today) {
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      return yyyy + '-' + mm + '-' + dd;
    },
    async reload() {
      console.log('fetching trattative')
      this.panoramica = await LeadsService.fetchPanoramicaLead(
        this.$route.params.id
      );
      console.log('panoramica', this.panoramica);
    },
    editAttivita(attivita) {
      DialogManagerService.openDialog('attivita', attivita, [this.reload, this.loadMoreLog]);
      console.log('editAttivita', attivita);
    },
    editTrattativa(trattativa) {
      console.log('editTrattativa', trattativa);
      DialogManagerService.openDialog(
        trattativa.type,
        trattativa,
        [this.reload, this.loadMoreLog],
        trattativa.type === 'contratto' ?
          {
            'delete': async () => { await this.reload(); await this.loadMoreLog(this.currentLead.id, true); },
          } :
          {
            'upgradeTrattativa': async () => { await this.reload(); await this.loadMoreLog(this.currentLead.id, true); },
            'delete': async () => { await this.reload(); await this.loadMoreLog(this.currentLead.id, true); },
          },
      );
    },

  },
};
</script>

<style scoped>
.small-tab {
  text-transform: none !important;
}
</style>