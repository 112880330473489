<template>
  <div class="bg-light-gray fill-height">
    <v-row class="pt-5 pl-5 pr-5">
      <v-col cols="12">
            <v-row  >
              <v-col cols="12" md="3" lg="2">
                <v-select v-model="periodo" :items="['Oggi', 'Questa settimana', 'Questo mese', 'Quest\'anno',]"
                  label="Seleziona periodo" density="compact" hide-details variant="underlined"
                  @update:model-value="filtra"></v-select>
              </v-col>
              <v-col cols="12" md="3" lg="2">
                <v-select v-model="utenzaApp" :items="utenti" item-title="nome" item-value="id" label="Seleziona Utente"
                  density="compact" hide-details variant="underlined" @update:model-value="filtra"></v-select>
              </v-col>
            </v-row>
      </v-col>
    </v-row>
    <v-row class="pt-5 pl-5 pr-5">
      <v-col :cols="zoomedTrattative ? 12 : 6" v-if="isTrattativeVisible">
        <v-card height="400px">
          <v-card-title>Statistiche Contatti e Trattative</v-card-title>
          <v-card-subtitle>Nuovi {{ descrizionePeriodo.toLowerCase() }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="4" offset="2">
                <stats-well icon="mdi-office-building-outline" title="Lead" :link="'/app/leads?utenza=' + utenzaApp"
                  :value="statsTrattative.leadCreati"></stats-well>
              </v-col>
              <v-col cols="4">
                <stats-well icon="mdi-account-tie" title="Contatti"
                  :value="statsTrattative.contattiCreati"></stats-well>
              </v-col>
              <v-col cols="4">
                <stats-well icon="mdi-help-box-outline" title="Opportunità" :link="'/app/trattative?modello=Opportunità&utenza=' + utenzaApp"
                  :value="statsTrattative.opportunitaCreate"></stats-well>
              </v-col>
              <v-col cols="4">
                <stats-well icon="mdi-text-box-outline" title="Offerte" :link="'/app/trattative?modello=Offerte&utenza=' + utenzaApp"
                  :value="statsTrattative.offerteCreate"></stats-well>
              </v-col>
              <v-col cols="4">
                <stats-well icon="mdi-text-box-check-outline" title="Contratti" :link="'/app/trattative?modello=Contratti&utenza=' + utenzaApp"
                  :value="statsTrattative.contrattiCreati"></stats-well>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-progress-linear v-if="loadingTrattative" color="teal" indeterminate></v-progress-linear>
      </v-col>
      <v-col :cols="zoomedAttivita ? 12 : 6" v-if="isAttivitaVisible">
        <v-card height="400px">
          <v-card-title>Statistiche Attività</v-card-title>
          <v-card-subtitle>Nuove {{ descrizionePeriodo.toLowerCase() }}</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="4" offset="2">
                <stats-well icon="mdi-phone" title="Telefonate" :value="statsAttivita.telefono"></stats-well>
              </v-col>
              <v-col cols="4">
                <stats-well icon="mdi-note-outline" title="Note" :value="statsAttivita.nota"></stats-well>
              </v-col>
              <v-col cols="4" offset="2">
                <stats-well icon="mdi-email-outline" title="Email" :value="statsAttivita.email"></stats-well>
              </v-col>
              <v-col cols="4">
                <stats-well icon="mdi-calendar-clock" title="Eventi" :value="statsAttivita.appuntamento"></stats-well>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-progress-linear v-if="loadingAttivita" color="teal" indeterminate></v-progress-linear>
      </v-col>
      <v-col :cols="zoomedTrattativeInScadenza ? 12 : 6" v-if="isTrattativeInScadenzaVisible">
        <v-card min-height="400px">
          <v-card-title>
            <v-btn :icon="zoomedTrattativeInScadenza? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'" variant="text" @click="zoomedTrattativeInScadenza = ! zoomedTrattativeInScadenza" class="float-right"></v-btn>
            Elenco Trattative</v-card-title>
          <v-card-subtitle>In scadenza e scadute (offerte in negoziazione o presentate, opportunità contattate)</v-card-subtitle>
          <v-card-text>
            <v-data-table-server :headers="headersTrattativeInScadenza" :items="trattativeInScadenza"
              :items-length="totalTrattativeInScadenza" :loading="loadingTrattativeInScadenza" :search="searchTrattative"
              items-per-page-text="Trattative per pagina"
              loading-text="Caricamento in corso..."
              no-data-text="Nessuna trattativa trovata"
              page-text="{0}-{1} di {2}"
              v-model:items-per-page.sync="itemsPerPage" @update:options="fetchTrattativeInScadenza">
              <template v-slot:[`item.numero`]="{ item }">
                <a href="#" @click.prevent="openEditTrattativaDialog(item)">
                  {{ item.numero ? item.numero : '---' }}
                </a>
              </template>
              <template v-slot:[`item.Lead.ragioneSociale`]="{ item }">
                <router-link :to="`/app/leads/${item.idLead}`">
                  {{ item.Lead.ragioneSociale }}
                </router-link>
              </template>
              <template v-slot:[`item.dataScadenza`]="{ item }">
                {{ formatDate(item.dataScadenza) }}
              </template>
            </v-data-table-server>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col :cols="zoomedAttivitaAperte ? 12 : 6" v-if="isAttivitaAperteVisible">
        <v-card min-height="400px">
          <v-card-title>
            <v-btn :icon="zoomedAttivitaAperte? 'mdi-arrow-collapse-all' : 'mdi-arrow-expand-all'" variant="text" @click="zoomedAttivitaAperte = ! zoomedAttivitaAperte" class="float-right"></v-btn>
            <v-select v-model="statoAttivita" :items="['Tutte', 'In corso', 'Completate']" label="Stato attività" density="compact" hide-details
              variant="underlined" class="float-right mr-5 v-col-3"></v-select>
            Elenco Attività
          </v-card-title>
          <v-card-text>
            <v-data-table-server :headers="headersAttivitaAperte" :items="attivitaAperte"
              :items-length="totalAttivitaAperte" :loading="loadingAttivitaAperte" :search="searchAttivita" v-model:items-per-page.sync="itemsPerPage"
              items-per-page-text="Attività per pagina"
              loading-text="Caricamento in corso..."
              no-data-text="Nessuna attività trovata"
              page-text="{0}-{1} di {2}"
              @update:options="fetchAttivitaAperte">
              <template v-slot:[`item.Lead.ragioneSociale`]="{ item }">
                <router-link :to="`/app/leads/${item.idLead}`">
                  {{ item.Lead.ragioneSociale }}
                </router-link>
              </template>
              <template v-slot:[`item.dataEsecuzione`]="{ item }">
                {{ formatDate(item.dataEsecuzione) }}
              </template>
              <template v-slot:[`item.tipo`]="{ item }">
                <a href="#" @click.prevent="openEditAttivitaDialog(item)">
                  {{ item.tipo }}
                </a>
              </template>
            </v-data-table-server>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import StatsWell from '@/components/controls/StatsWell.vue';
import axios from 'axios';
import { mapState, mapWritableState } from 'pinia';
import useUsersStore from '@/stores/users';
import AttivitaService from '@/services/AttivitaService';
import TrattativeService from '@/services/TrattativeService';
import DialogManagerService from '@/services/DialogManagerService';
import AuthService from '@/services/AuthService';
//import { it } from 'vuetify/lib/locale/index.mjs';

export default {
  name: 'DashboardView',
  components: {
    StatsWell,
  },
  computed: {
    ...mapState(useUsersStore, ['users', 'fetchUsers', 'fixUtenze']),
    ...mapWritableState(useUsersStore, ['utenzaApp', 'itemsPerPage']),

    isAttivitaAperteVisible() {
      return !this.zoomedAttivita && !this.zoomedTrattative && !this.zoomedTrattativeInScadenza;
    },
    isAttivitaVisible() {
      return !this.zoomedAttivitaAperte && !this.zoomedTrattative && !this.zoomedTrattativeInScadenza;
    },
    isTrattativeVisible() {
      return !this.zoomedAttivitaAperte && !this.zoomedAttivita && !this.zoomedTrattativeInScadenza;
    },
    isTrattativeInScadenzaVisible() {
      return !this.zoomedAttivitaAperte && !this.zoomedAttivita && !this.zoomedTrattative;
    },
    descrizionePeriodo() {
      switch (this.periodo) {
        case 'Oggi':
          return 'Nella giornata di Oggi';
        case 'Questa settimana':
          return 'In questa settimana';
        case 'Questo mese':
          return 'Da inizio mese';
        case 'Quest\'anno':
          return 'Da inizio anno';
      }
      return ""
    },
  },
  watch: {
    itemsPerPage(oldValue, newValue) {
      console.log('itemsPerPage', oldValue, newValue);
    },
    utenzaApp() {
      console.log('utenzaApp changed', this.utenzaApp);
      this.searchTrattative = String(Date.now());
      this.searchAttivita = String(Date.now());
    },
    statoAttivita() {
      console.log('statoAttivita changed', this.statoAttivita);
      this.searchAttivita = String(Date.now());
    },
  },
  data: () => ({
    periodo: 'Questo mese',
    utenti: [],
    zoomedAttivita: false,
    zoomedTrattative: false,
    zoomedAttivitaAperte: false,
    zoomedTrattativeInScadenza: false,
    loadingTrattative: false,
    loadingAttivita: false,
    loadingAttivitaAperte: false,
    loadingTrattativeInScadenza: false,
    statsTrattative: {
      leadCreati: 0,
      contattiCreati: 0,
      opportunitaCreate: 0,
      offerteCreate: 0,
      contrattiCreati: 0,
    },
    statsAttivita: {
      nota: 0,
      telefono: 0,
      email: 0,
      appuntamento: 0,
    },
    attivitaAperte: [],
    headersAttivitaAperte: [
      { title: 'Tipo', value: 'tipo', sortable: true },
      { title: 'Lead', value: 'Lead.ragioneSociale', sortable: false },
      { title: 'Descrizione', value: 'descrizione', sortable: true },
      { title: 'Assegnata a', value: 'AssegnatoA.nome', sortable: false },
      { title: 'Data', value: 'dataEsecuzione', sortable: true },
    ],
    totalAttivitaAperte: 0,
    trattativeInScadenza: [],
    headersTrattativeInScadenza: [
      { title: 'Numero', value: 'numero', sortable: true },
      { title: 'Lead', value: 'Lead.ragioneSociale', sortable: false },
      { title: 'Oggetto', value: 'oggetto', sortable: true },
      { title: 'Assegnata a', value: 'Lead.AssegnatoA.nome', sortable: false },
      { title: 'Scadenza', value: 'dataScadenza', sortable: true },
      { title: 'Stato', value: 'stato', sortable: true },
    ],
    totalTrattativeInScadenza: 0,
    searchAttivita: '',
    searchTrattative: '',
    statoAttivita: 'In corso',
  }),
  async mounted() {
    //console.log(AuthService.getUserDetails());
    console.log('DashboardView mounted');
    this.utenti = [{ id: 0, nome: 'Tutti tranne Utente di Sistema' }].concat(await this.fetchUsers());
    this.fixUtenze();
    this.loadingTrattativeInScadenza = false;
    this.loadingAttivitaAperte = false;
    this.filtra();
    // this.loadingTrattative = false;
    // this.loadingAttivita = false;
  },
  methods: {
    openEditAttivitaDialog(item) {
      console.log('openEditAttivitaDialog', item);
      item.dataEsecuzione = item.dataEsecuzione.substring(0, 16);
      DialogManagerService.openDialog('attivita', item, [], {
        'delete': this.reloadAttivitaAperte,
      });
    },
    openEditTrattativaDialog(item) {
      console.log('openEditTrattativaDialog', item);
      DialogManagerService.openDialog(item.type, item, [], {
        'upgradeTrattativa': (item) => { this.$router.push(`/app/leads/${item.idLead}`); },
        'delete': this.reloadTrattativeInScadenza,
      });
    },
    reloadTrattativeInScadenza() {
      console.log('reloadTrattativeInScadenza');
      this.searchTrattative = String(Date.now());
    },
    reloadAttivitaAperte() {
      console.log('reloadAttivitaAperte');
      this.searchAttivita = String(Date.now());
    },
    formatDate(dateString) {
      if (!dateString) return '';
      let date = new Date(dateString);
      if (date.toString() === 'Invalid Date') {
        date = new Date(dateString.replace(' ', 'T'));
      }
      return new Date(date).toLocaleDateString('it-IT', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      });
    },
    async filtra() {
      console.log('Filtro cambiato', this.periodo, this.utenzaApp);
      await this.fetchStatsTrattative(this.periodo, this.utenzaApp);
      await this.fetchStatsAttivita(this.periodo, this.utenzaApp);
    },
    async fetchStatsTrattative(periodo = 'Questo mese', utenza = null) {
      console.log('loadingStatsTrattative', this.loadingTrattative);
      if (this.loadingTrattative) {
        return; // Prevent fetching if already loading
      }
      this.loadingTrattative = true;
      try {
      AuthService.refreshToken();
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/stats/trattative?periodo=' + periodo + '&utenza=' + (utenza > 0 ? utenza : ''));
        this.statsTrattative = response.data;
      } catch (error) {
        console.error('Error fetching stats trattative:', error);
      }
      this.loadingTrattative = false;
    },
    async fetchStatsAttivita(periodo = 'Questo mese', utenza = null) {
      console.log('loadingStatsAttivita', this.loadingAttivita);
      if (this.loadingAttivita) {
        return; // Prevent fetching if already loading
      }
      this.loadingAttivita = true;
      try {
        this.statsAttivita.nota = 0;
        this.statsAttivita.telefono = 0;
        this.statsAttivita.email = 0;
        this.statsAttivita.appuntamento = 0;
        AuthService.refreshToken();
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/stats/attivita?periodo=' + periodo + '&utenza=' + (utenza > 0 ? utenza : ''));
        console.log('response.data', response.data);
        for (let i = 0; i < response.data.length; i++) {
          if (response.data[i].tipo === 'nota') {
            this.statsAttivita.nota = response.data[i].count;
          } else if (response.data[i].tipo === 'telefono') {
            this.statsAttivita.telefono = response.data[i].count;
          } else if (response.data[i].tipo === 'email') {
            this.statsAttivita.email = response.data[i].count;
          } else if (response.data[i].tipo === 'appuntamento') {
            this.statsAttivita.appuntamento = response.data[i].count;
          }
        }
        console.log('statsAttivita', this.statsAttivita);
      } catch (error) {
        console.error('Error fetching stats attivita:', error);
      }
      this.loadingAttivita = false;
    },
    async fetchAttivitaAperte({ page, itemsPerPage, sortBy }) {
      console.log('loadingAttivitaAperte', this.loadingAttivitaAperte);
      if (this.loadingAttivitaAperte) {
        return; // Prevent fetching if already loading
      }
      if (itemsPerPage !== this.itemsPerPage) {
        this.itemsPerPage = itemsPerPage;
        this.searchTrattative = String(Date.now());
        //this.searchAttivita = String(Date.now());
      }
      this.loadingAttivitaAperte = true;
      AttivitaService.fetchAttivitaAperte(page, itemsPerPage, sortBy, this.utenzaApp, this.statoAttivita).then((data) => {
        console.log("Data", data)
        this.attivitaAperte = data.items;
        this.totalAttivitaAperte = data.totalCount;
        this.loadingAttivitaAperte = false;
      }).catch((error) => {
        console.error('Error fetching attivita aperte:', error);
        this.loadingAttivitaAperte = false;
      });
      //this.leads = await this.leadsStore.fetchLeads();
    },
    async fetchTrattativeInScadenza({ page, itemsPerPage, sortBy }) {
      console.log('loadingTrattativeInScadenza', this.loadingTrattativeInScadenza);
      if (this.loadingTrattativeInScadenza) {
        return; // Prevent fetching if already loading
      }
      if (itemsPerPage !== this.itemsPerPage) {
        this.itemsPerPage = itemsPerPage;
        //this.searchTrattative = String(Date.now());
        this.searchAttivita = String(Date.now());
      }

      this.loadingTrattativeInScadenza = true;
      TrattativeService.fetchTrattativeInScadenza(page, itemsPerPage, sortBy, this.utenzaApp).then((data) => {
        console.log("Data", data)
        this.trattativeInScadenza = data.items;
        this.totalTrattativeInScadenza = data.totalCount;
        this.loadingTrattativeInScadenza = false;
      }).catch((error) => {
        console.error('Error fetching trattative in scadenza:', error);
        this.loadingTrattativeInScadenza = false;
      })
      //this.leads = await this.leadsStore.fetchLeads();
    },
  },
};
</script>

<style scoped>
.bg-light-gray {
  background-color: #fafaf6;
}
</style>