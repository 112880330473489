<template>
  <v-container>
    <v-timeline direction="vertical" side="end" :truncate-line="logs?.length < totalLogCount ? 'start' : 'both'" class="mb-5">
      <v-timeline-item v-for="lattivita in logs" :key="lattivita.id" :dot-color="dotColor(lattivita)" size="small" small left>
        <template v-slot:opposite>
          {{ formatDate(lattivita.createdAt) }}
        </template>
        <template v-slot:icon>
          <v-btn variant="plain" @click="openRelatedItem(lattivita)"><v-icon size="small">{{ getAttivitaIcon(getTarget(lattivita)) }}</v-icon></v-btn>
        </template>
        <div v-if="lattivita.tipo === 'inserimento'">
          {{ lattivita.descrizione }} da <span class=" italico">{{ lattivita.CreatoDa.nome }}</span>
          <div v-if="lattivita.Contatto">{{ lattivita.Contatto.surname + ' ' + lattivita.Contatto.name }}</div>
        </div>
        <div v-else-if="lattivita.tipo === 'modifica'">
          {{ lattivita.descrizione }} da <span class="italico">{{ lattivita.CreatoDa.nome }}</span>
          <div v-if="lattivita.Contatto">{{ lattivita.Contatto.surname + ' ' + lattivita.Contatto.name }}</div>
          <ul v-if="Array.isArray(lattivita.additionalData)">
            <div class="mt-1" v-for="(modifica, index) in filterFields(lattivita.additionalData)" :key="index">
              <v-chip size="small" class="grassetto mr-3">{{modifica.field}}</v-chip>
              <span class="piupiccolo">
                {{ limitOutput(modifica.from)}}
              <v-icon>mdi-arrow-right-thick</v-icon>
                {{ limitOutput(modifica.to) }}
              </span>
            </div>
          </ul>
        </div>
        <div v-else>
          <div>
          {{ getSubject(lattivita) }}
          <v-btn :icon="getAttivitaIcon(lattivita.tipo)" variant="text" density="comfortable" @click="editAttivita(lattivita)"></v-btn>
          {{  getObject(lattivita) }}
          </div>
          <span v-if="lattivita.tipo=='appuntamento'" class="mr-2">
            {{ formatDate(lattivita.dataEsecuzione) }}
          </span>
          <span v-if="lattivita.descrizione && lattivita.descrizione.length > 0">
            <span class="italico">{{ lattivita.descrizione.substring(0, 50) }}</span><span v-if="lattivita.descrizione.length > 50">...</span>
          </span>
        </div>
      </v-timeline-item>
      <div v-if="logs?.length < totalLogCount">
        <v-btn @click="loadMoreLog(null)" prepend-icon="mdi-plus" color="blue" density="compact" variant="text">Carica altri</v-btn>
      </div>
    </v-timeline>
    <attivita-edit-dialog v-model="attivitaDialog" :attivita="currentAttivita" @close="closeAttivitaDialog" @save="saveAttivita"></attivita-edit-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'pinia';
import useLeadsStore from '@/stores/leads';
import AttivitaService from '@/services/AttivitaService';
import AttivitaEditDialog from '@/components/dialogs/AttivitaEditDialog.vue';
import DialogManagerService from '@/services/DialogManagerService';
import ContactsService from '@/services/ContactsService';
import TrattativeService from '@/services/TrattativeService';

export default {
  name: 'LogPreview',
  data() {
    return {
      leadId: this.$route.params.id,
      attivitaDialog: false,
      currentAttivita: null,
    };
  },
  async mounted() {
    console.log('LogPreview mounted');
    if (this.logs?.length === 0) {
      console.log("Carico logs")
      await this.loadMoreLog(this.$route.params.id);
    }
  },
  components: {
    AttivitaEditDialog
  },
  computed: {
    ...mapState(useLeadsStore, ['currentLead', 'updateLog', 'logs', 'loadMoreLog', 'totalLogCount', 'fetchLead']),
  },
  methods: {
    limitOutput(obj, limit = 240) {
      if (!obj) return 'nullo';
      const item = JSON.stringify(obj);
      if (item.length > limit) {
        return item.substring(0, limit) + '...';
      }
      return item;
    },
    getSubject(attivita) {
      if (attivita.modalita === 'inbound') {
        return attivita.Contatto.nome ?? attivita.Lead.ragioneSociale;
      } else {
        return attivita.CreatoDa?.nome;
      }
    },
    getObject(attivita) {
      if (attivita.modalita === 'inbound') {
        return attivita.CreatoDa?.nome;
      } else {
        return attivita.Contatto?.nome ?? attivita.Lead.ragioneSociale;
      }
    },

    getTarget(attivita) {
      return attivita.descrizione.split(' ')[0].toLowerCase().replace('à', 'a');
    },
    async openRelatedItem(item) {
      console.log('openRelatedItem', item);
      // get first word of descrizione in lower case
      let objectName = this.getTarget(item);
      let itemData = null;
      switch (objectName) {
        case 'attivita':
          itemData = await AttivitaService.get(item.idAttivitaCollegata);
          break;
        case 'contatto':
          itemData = await ContactsService.get(item.idContatto);
          break;
        case 'lead':
          itemData = await this.fetchLead(item.idLead);
          break;
        case 'contratto':
          itemData = await TrattativeService.get(item.idContrattoCollegato, objectName);
          break;
        case 'offerta':
          itemData = await TrattativeService.get(item.idOffertaCollegata, objectName);
          break;
        case 'opportunita':
          itemData = await TrattativeService.get(item.idOpportunitaCollegata, objectName);
          break;
        default:
          console.log('openRelatedItem', objectName);
      }
      console.log('openRelatedItem', itemData);
      if (itemData)
        DialogManagerService.openDialog(objectName, itemData);
    },
    getAttivitaIcon(tipo) {
      return AttivitaService.getAttivitaIcon(tipo);
    },
    editAttivita(attivita) {
      this.currentAttivita = attivita;
      this.attivitaDialog = true;
    },
    closeAttivitaDialog() {
      this.currentAttivita = null;
      this.attivitaDialog = false;
    },
    async saveAttivita(attivita) {
      console.log('saveAttivita', attivita);
      await AttivitaService.update(attivita);
      this.attivitaDialog = false;
      this.currentAttivita = null;
    },
    dotColor(attivita) {
      if (attivita.modalita == 'inbound') return 'green';
      if (attivita.modalita == 'outbound') return 'blue';
      return 'grey';
    },
    filterFields(modifiche) {
      let fields = ['id', 'createdAt', 'updatedAt']
      return modifiche.filter(modifica => !fields.includes(modifica.field));
    },
    formatDate(dateString) {
      if (!dateString) return '';
      let date = new Date(dateString);
      if (date.toString() === 'Invalid Date') {
        date = new Date(dateString.replace(' ', 'T'));
      }
      return new Date(date).toLocaleDateString('it-IT', {
          year: '2-digit',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,

        });
    },
  },
};
</script>

<style scoped>
.grassetto {
  font-weight: 500;
}
.italico {
  font-style: italic;
}
.piupiccolo {
  font-size: 0.8em;
}

</style>