import axios from 'axios';
import AuthService from '@/services/AuthService';

class AttivitaService {
  async get(id) {
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/attivita/' + id);
      console.log("attivita:", id, response.data);
      response.data.dataEsecuzione = response.data.dataEsecuzione.substring(0, 16);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch attivita:', error);
      return null;
    }
  }
  async update(attivita) {
    try {
      AuthService.refreshToken();
      const response = await axios.put(process.env.VUE_APP_API_URL + '/api/attivita/'+attivita.id, attivita);
      console.log("saveAttivita", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to save attivita:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async create(attivita) {
    try {
      AuthService.refreshToken();
      const response = await axios.post(process.env.VUE_APP_API_URL + '/api/attivita', attivita);
      console.log("newAttivita", response.data);
      return response.data;
    }
    catch (error) {
      console.error('Failed to create new attivita:', error);
      throw error; // Handle error appropriately in your application
    }
  }
  async saveAttivita(attivita, { currentLead, postSaveFunctions = [] }) {
    if (attivita.id) {
      console.log('update', attivita);
      await this.update(attivita);
    } else {
      if (!attivita.idLead && currentLead && currentLead.id) {
        attivita.idLead = currentLead.id;
      }
      console.log('create', attivita);
      await this.create(attivita);
    }
    console.log('saveAttivita', attivita);

    // Execute each post-save function in the array
    for (const func of postSaveFunctions) {
      if (typeof func === 'function') {
        await func(currentLead.id, true);
      }
    }
  }
  async fetchAttivitaAperte(page = 0, itemsPerPage = 10, sortBy = '', user = null, stato = null) {
    let sort = '';
    if (sortBy.length) {
      sort = `&sortBy=${sortBy[0].key}:${sortBy[0].order}`;
    }
    console.log("sort", sort)
    let utenza = '';
    if (user) {
      utenza = `&utenza=${user}`;
    }
    let statoAttivita = '';
    if (stato && stato !== 'Tutte') {
      switch (stato) {
        case 'In corso':
          statoAttivita = "&stato=da-fare";
          break;
        case 'Completate':
          statoAttivita = "&stato=completata";
          break;
      }
    }
    console.log("utenza", utenza)
    console.log("statoAttivita", statoAttivita)
    try {
      AuthService.refreshToken();
      const response = await axios.get(process.env.VUE_APP_API_URL + '/api/attivita-aperte'+'?page=' + page + '&pageSize=' + itemsPerPage + sort + utenza + statoAttivita);
      console.log("attivita-aperte:", response.data);
      return response.data;
    } catch (error) {
      console.error('Failed to fetch trattative:', error);
      return null;
    }
  }

  getAttivitaIcon(tipo) {
    switch (tipo) {
      case 'telefono':
        return 'mdi-phone';
      case 'email':
        return 'mdi-email-outline';
      case 'nota':
        return 'mdi-note-outline';
      case 'appuntamento':
        return 'mdi-calendar-clock';
      case 'contatto':
        return 'mdi-account-outline';
      case 'task':
        return 'mdi-calendar-check';
      case 'opportunita':
        return 'mdi-help-box-outline';
      case 'offerta':
        return 'mdi-text-box-outline';
      case 'contratto':
        return 'mdi-text-box-check-outline';
      case 'inserimento':
        return 'mdi-plus';
      case 'modifica':
        return 'mdi-pencil';
      default:
        return 'mdi-archive-outline';
    }
  }
}

export default new AttivitaService();