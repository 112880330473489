<template>
  <div id="app">
    <router-view />
    <component
      v-for="(dialog, index) in dialogs"
      :key="index"
      :is="dialogComponents[dialog.dialogType]"
      :item="dialog.props.item"
      :modelValue="true"
      @update:modelValue="value => handleUpdateModelValue(index, value)"
      v-on="getListeners(dialog.dialogType, index, dialog.extraListeners)"
    ></component>
  </div>
</template>

<script>
import DialogManagerService from '@/services/DialogManagerService';
import OffertaEditDialog from '@/components/dialogs/OffertaEditDialog.vue';
import LeadEditDialog from '@/components/dialogs/LeadEditDialog.vue';
import AttivitaEditDialog from '@/components/dialogs/AttivitaEditDialog.vue';
import ContactEditDialog from '@/components/dialogs/ContactEditDialog.vue';
import ContrattoEditDialog from './components/dialogs/ContrattoEditDialog.vue';
import OffertaDialog from '@/components/dialogs/OffertaDialog.vue';
import OpportunitaEditDialog from './components/dialogs/OpportunitaEditDialog.vue';
import UserDialog from './components/dialogs/UserDialog.vue';

export default {
  name: 'App',
  data() {
    return {
      dialogs: DialogManagerService.getDialogs(),
    };
  },
  computed: {
    dialogComponents() {
      return {
        lead: LeadEditDialog,
        attivita: AttivitaEditDialog,
        contatto: ContactEditDialog,
        contratto: ContrattoEditDialog,
        offerta: OffertaEditDialog,
        generaOfferta: OffertaDialog,
        opportunita: OpportunitaEditDialog,
        user: UserDialog,
      };
    },
  },
  methods: {
    closeDialog(index) {
      DialogManagerService.closeDialog(index);
    },
    saveDialog(index, item) {
      DialogManagerService.saveDialog(index, item);
    },
    handleUpdateModelValue(index, value) {
      if (!value) {
        DialogManagerService.closeDialog(index);
      }
      // Optionally handle other logic when the modelValue changes
    },
    getListeners(dialogType, index, extraListeners) {
      let listeners = {
        close: () => this.closeDialog(index),
        save: (item) => this.saveDialog(index, item),
      };
      if (extraListeners) {
        listeners = { ...listeners, ...extraListeners };
      }
      return listeners;
    },
  },
};
</script>

<style>
body {
  background-color: #f5f5f5;
}
html, body, #app {
  height: 100%;
}
.italico {
  font-style: italic;
}
.grassetto {
  font-weight: 500;
}
.piupiccolo {
  font-size: 0.8em;
}
.piugrande {
  font-size: 1.1em;
}
</style>
