<template>
    <v-app>
      <v-app-bar :color="tenantsStore.tenants[tenantsStore.currentTenant] && tenantsStore.tenants[tenantsStore.currentTenant].color || '#fbb03b'" prominent>
        <v-app-bar-nav-icon variant="text" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

        <v-toolbar-title>CRM Luna Labs
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <!--<v-btn variant="text" icon="mdi-magnify"></v-btn>

        <v-btn variant="text" icon="mdi-filter"></v-btn>

        <v-btn variant="text" icon="mdi-dots-vertical"></v-btn>-->
        <div v-if="user.tenants?.length > 1">
        <v-menu open-on-hover>
          <template v-slot:activator="{ props }">
            <v-btn v-bind="props">
              {{ tenantsStore.tenants[tenantsStore.currentTenant].name }}
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="(tenant, index) in tenantsStore.tenants" :key="tenant.id" @click="selectTenant(index)">
              <v-list-item-title>{{ tenant.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        </div>
        <div v-else>hello</div>
        <v-btn variant="text" @click="logout">
          Logout
        </v-btn>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" location="left" temporary>
        <v-list-item v-for="item in items" :key="item.value" :prepend-icon="item.icon" @click="navigateTo(item.value)">
          {{ item.title }}
        </v-list-item>
        <v-divider></v-divider>
        <v-btn rounded style="display: block; margin-left: auto;
        margin-right: auto; margin-top: 15px;
        width: 85%; font-size: 1em;text-transform: unset !important;" dark elevation=0 color="#424242" @click="logout">
          Logout
        </v-btn>
      </v-navigation-drawer>

      <v-main>
        <router-view></router-view>

      </v-main>
    </v-app>
</template>

<script>
import AuthService from '../services/AuthService';
import { mapStores } from 'pinia';
import useTenantsStore from '@/stores/tenants';
import useLeadsStore from '@/stores/leads';

export default {
  name: 'AuthView',
  computed: {
    ...mapStores(useTenantsStore, ['tenants', 'currentTenant', 'setTenants', 'changeTenant']),
    ...mapStores(useLeadsStore, ['leads']),
  },
  data: () => ({
    user: { tenants: [] },
    drawer: false,
    group: null,
    items: [
      {
        title: 'Utenti',
        value: 'utenti',
        icon: 'mdi-account',
      },
      {
        title: 'Ruoli',
        value: 'ruoli',
        icon: 'mdi-account-key',
      },
      /*{
        title: 'Dipendenti',
        value: 'dipendenti',
        icon: 'mdi-account-multiple',
      },*/
      {
        title: 'Leads',
        value: 'leads',
        icon: 'mdi-office-building-outline',
      },
      {
        title: 'Offerte',
        value: 'offerte',
        icon: 'mdi-file-document',
      },
    ],
  }),

  watch: {
    group() {
      this.drawer = false
    },
  },
  methods: {
    async selectTenant(index) {
      console.log("selectTenant", index);
      await this.tenantsStore.changeTenant(index);
      //this.$router.go()
      this.$nextTick(() => {
        this.$router.push({ path: `/app/leads` });
      });
    },
    logout() {
      this.$router.push({ path: `/logout` });
    },
    navigateTo(route) {
      this.$router.push({ path: `/app/${route}` });
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    }
  },
  mounted() {
    console.log("AuthView mounted")
    console.log(window.location.search);
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    //const token = this.getCookie('jwt');
    if (token) {
      AuthService.saveToken(token);
      const user = AuthService.getUserDetails();
      localStorage.setItem('user', JSON.stringify(user));
      this.tenantsStore.setTenants();
      console.log("Saved user", user);
      this.user = user;
      this.$router.push('/app/dashboard');
      return;
    }
    this.$router.push('/logout');
  }
}
</script>