import { defineStore } from 'pinia'
import axios from 'axios'
import AuthService from '@/services/AuthService';

const useUsersStore = defineStore('users', {
  state: () => ({
    users: [],
    valid: false,
    utenzaApp: null,
    itemsPerPage: 10,
    pageLead: 1,
  }),
  actions: {
    fixUtenze() {
      if (this.utenzaApp !== null) return; // Already fixed
      const user = AuthService.getUserDetails();
      this.utenzaApp = parseInt(user.id);
    },
    async fetchUsers() {
      if (this.valid && this.users.length > 0) return this.users; // Already fetched
      AuthService.refreshToken();
      try {
        const response = await axios.get(process.env.VUE_APP_API_URL + '/api/users?page=0&pageSize=1000');
        console.log("users", response.data);
        this.valid = true;
        return this.users = response.data.items;
      } catch (error) {
        console.error('Failed to fetch users:', error);
        throw error; // Handle error appropriately in your application
      }
    },
    async fetchUser(id) {
      const users = await this.fetchUsers();
      try {
        //find user in local store
        const user = users.find(user => user.id === id);
        if (user) return user;
      } catch (error) {
        console.error('Failed to fetch user:', error);
        throw error; // Handle error appropriately in your application
      }
      return null;
    },
    invalidate() {
      this.valid = false;
    }
  },
})

export default useUsersStore;